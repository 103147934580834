import { Component, OnInit, ViewChild } from '@angular/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { CityService } from 'src/app/@core/services/city.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';
import { MessageService } from 'src/app/message.global';
import * as moment from 'moment';
import { FormBuilder,Validators} from '@angular/forms';
import { OvertimeService } from 'src/app/@core/services/overtime.service';
import { RegularizationRequestService } from 'src/app/@core/services/regularization-request.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { OnDutyService } from 'src/app/@core/services/on-duty.service';
import { WorkfromhomeService } from 'src/app/@core/services/workfromhome.service';
import { CompensatoryOffService } from 'src/app/@core/services/compensatory-off.service';
import { AssetRequestService } from 'src/app/@core/services/asset-request.service';
import { ExpenseReportRequestService } from 'src/app/@core/services/expense-report-request.service';
import { ExpenseAdvnaceProfileService } from 'src/app/@core/services/expense-advance-profile.service';

import { Subject } from 'rxjs';
import { delay,switchMap  } from 'rxjs/operators';
import { RequestService } from 'src/app/@core/services/request.service';
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})

export class RequestComponent implements OnInit {

  filterpanel = false;
  holidayRequestFlag              = false;
  ondutysidepanel                 = false;
  viewstatusPanel                 = false;
  showSidePanel                   = false;
  showSidePanel1                  = false;
  active                          = 1;
  investmentCnt:any               = [];
  tax_regima:any                  = [];
  alertToggle                     = false;
  alertToggle1                    = false;
  cond                            = true;
  investalertMsg:any              = '';
  invalidReqAlertMsg:any          = '';
  proposed_cnt:any                = [];
  confirmed_cnt:any               = [];
  loader                          = false;
  nodata                          = false;
  requests:any[]                  = [];
  searchlistdata:any              = [];
  reqTypeSearchlistdata:any       = [];
  recentUsedReq:any               = [];
  investmentSingleData:any        = [];
  hra80ggSingleData:any           = [];
  cahpt80CSingleData:any          = [];
  otherSourceSingleData:any       = [];
  diductionSingleData:any         = [];
  incomeLossSingleData:any        = [];
  hra80ggFlag:any                 = false;
  requestsearch:string            = this.messageService.searchdisplay('requests');
  requestTypeSearch:string        = this.messageService.searchdisplay('novalue');
  viewHeader                      = '';
  cityName:any                    = '';
  modalToggle                     = false;
  currentDocName                  = "";
  currentExtension                = "";
  allowedExtension                = "";
  loader1                         = false;
  fileName:any;
  isEmployee                      = true;
  investmentDeclerationView       = false;
  from                            = 'request';
  fromViewStatus                  = '';
  requestId:any
  requestName:any
  requestitem                     = '';
  declareStatus:any;
  searchKeyword                   = "";
  investDetailsData:any           = [];
  loader2                         = false;
  loader3                         = false;
  currency:any;
  reqIds:any;
  viewPanel                       = false;
  reqName:any;
  viewData:any                    = [];
  searchKeywordRequestType        = "";
  requestSearchData:any           = '';
  // For tab section loader
  tabLoader                       = false;

  searchData : Subject<void> = new Subject();

  /********VIEW STATUS VARIABLES  ****/
  viewStatusData:any              = [];
  approvalRequired                = false;
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;

  /********HOLIDAY REQUEST VARIABLES  ****/
  holidayFloatData:any               = [];
  holidatSearchData:any              = [];
  holiday_nodata                     = false;
  holiday_loader                     = false;
  holidayRequestView                 = false;
  holidayRequestSingleData:any       = [];
  saveClicked                        = false;

   /********LEAVE REQUEST VARIABLES  ****/
   leaveRequestView                  = false;
   leaveRequestData:any              = [];
   compOffData:any                   = [];

  /********EMPLOYEE PROFILE REQUEST VARIABLES  ****/
  empRequestView                     = false;
  empRequestData:any                 = [];
  currentStaus:any

  /********FBP DECLARATION REQUEST VARIABLES  ****/
  fbdeclarationFlag                  = false;
  fbpRequestView                     = false;
  fbpReqViewData:any                 = [];
  fbpStatus:any                      = '';

  /********WFH REQUEST VARIABLES  ****/
  workFromHome                       = false;
  workFromHomedata:any               = [];
  workFromHomecancel                 = false;

  /********IT DECLARATION REQUEST VARIABLES  ****/
  taxData:any                        = [];
  exeption_50:any                    = [];
  hra80gg:any;

  /********COMPENSATORY OFF REQUEST VARIABLES  ****/
  compOffRequestFlag                 = false;
  compOffListData:any                = [];
  currentYear:any                    = new Date().getFullYear();

  /********INFINITY SCROLL VARIABLES  ****/
   offset                           = 0;
   listlength                       = 0;
   direction                        = '';
   defaultlimit:any                 = 20;
   requestDetailsList:any           = [];
   infinityloader                   = false;
   activeToggle:any                 = '';
   @ViewChild(SearchbarComponent) child:any;

  /***************REIMBUREMENT REQUEST VARIABLES*********** */
   empReimburseView                 = false;
   reimbursementType:any;
   reimbursementData:any;

  /********* Attendance Regularization View ****************/
  regularizationEligibleData: any    = [];
  loader_regularization: boolean     = false;
  regularizationData: any;
  regualarizationID: any;
  emp_Id: any;
  regularizeCancel = false

  /********* On Duty View ****************/
  loader_OnDuty: boolean            = false;
  onDutyData: any;
  onDutyID: any;
  eligibileData: any;
  onDutyCancel = false

  /********* Short Leave View ****************/
  shortLeaveView : boolean = false
  shortLeaveData : any     = []

  /********* Short Leave View ****************/
  loanView : boolean = false
  loanData : any     = []
  loanId   : any

  //  requestId:any;
  // Filter variables
  selected: any;
  ranges: any = {
                    'Today'       : [moment(), moment()],
                    'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                    'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
  isInvalidDate = (m: moment.Moment) =>  {
    }

  filterForm                = this.formBuilder.group({
      category_list          : null,
      req_date               : ''
    }
  );

  disabled            = false;
  category_list:any   = [];
  datafilter :any     = [];
  submitted           = false;

  constructor(
    private investmentService:InvestmentDeclerationService,
    public router: Router,
    private cityService: CityService,
    public appservice:AppService,
    public messageService : MessageService,
    private datePipe:DatePipe,
    private formBuilder: FormBuilder,
    private overtimeService: OvertimeService,
    private regularizationServ : RegularizationRequestService,
    private authServ   : AuthService,
    private onDutyServ : OnDutyService,
    private compensatoryOffService: CompensatoryOffService,
  	private WorkfromhomeService:WorkfromhomeService,
    private assetRequestService: AssetRequestService,
    private expenseReportRequestService:ExpenseReportRequestService,
    public ExpenseAdvnaceProfileService: ExpenseAdvnaceProfileService,
    private requestService:RequestService
    ) {
      this.investmentService.request_item.subscribe(result =>{


        let itDeclarationChange = ((result.event['request_type_name'] == 'Confirmed Investment Declaration' || result.event['request_type_name'] == 'Proposed Investment Declaration') && result.event['status'] == 'Accepted') ? true : false;


        for(let i=0; i<this.requests.length; i++){
          if(this.requests[i]['id'] == result.event['id']){
            this.requests[i]['accepted_by']          = result.event['accepted_by'];
            this.requests[i]['maxLevel']             = result.event['maxLevel'];
            this.requests[i]['modified_at']          = result.event['modified_at'];
            this.requests[i]['current_level']        = result.event['current_level'];
            this.requests[i]['status']               = result.event['status'];
            this.requests[i]['declaration_status']   = result.event['declaration_status'];
          }else{
            if(((this.requests[i]['request_type_name'] == 'Confirmed Investment Declaration' || this.requests[i]['request_type_name'] == 'Proposed Investment Declaration') && this.requests[i]['status'] == 'Accepted') && itDeclarationChange){
              this.requests[i]['declaration_status']   = 'Inactive';
            }
          }
        }

        // View status live updation
        if(this.viewStatId == result.event['id']){
          this.requestViewStatus(this.viewStatId,this.viewStatApprovalRequired,'');
        }

        // this.requestDetails();

      })
     }

  ngOnInit(): void {
    this.loader                = true;
    this.tabLoader             = true;
    this.requestDetails();
    this.investmentService.requestSocket();
    this.emp_Id = this.authServ.isEmployeeId
    this.regualarizationEligibility()
    this.OnDutyEligibility();

    this.searchData.pipe(delay(1000),
        switchMap(() => this.activeToggle == '' ?  this.investmentService.requestAllList(this.defaultlimit,this.offset,this.searchKeyword,this.datafilter) : this.investmentService.requestList(this.activeToggle,this.defaultlimit,this.offset,this.datafilter,this.searchKeyword))).subscribe((res:any)=>{
          if(this.offset ==0){
            this.requests = [];
  }
          this.detailsList(res.results);
        });
  }

  requestDetails(){
    this.investmentService.requestDetails().subscribe((res:any)=>{
      if(res.is_employee){
        this.investDetailsData = res;
        this.loader = false;
         this.isEmployee       = true;
        if(res.count_info.hasOwnProperty('max_count_limit')){
          this.investmentCnt   = res.count_info.max_count_limit;
        }if(res.count_info.hasOwnProperty('assigned_salary_data')){
          if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){
            this.tax_regima    = res.count_info.assigned_salary_data.tax_regime;
          }else{
            this.tax_regima    = res.count_info.assigned_salary_data.AssignSalaryStructure;
          }
        }
        if(res.count_info.hasOwnProperty('request_count_info')){
          if(res.count_info.request_count_info.hasOwnProperty('Proposed_Investment_Declaration')){
            this.proposed_cnt   = res.count_info.request_count_info.Proposed_Investment_Declaration;
          }if(res.count_info.request_count_info.hasOwnProperty('Confirmed_Investment_Declaration')){
            this.confirmed_cnt  = res.count_info.request_count_info.Confirmed_Investment_Declaration;
          }
        }
        this.offset = 0;
        this.showDataItems();
        // this.showReqDataItems();
        // this.recentRequestType();
        this.requestFilterData();
      }else{
        this.isUser();
      }
    });
  }

  resetfilter(){
    this.filterForm.get('category_list')?.reset('');
    this.filterForm.get('req_date')?.reset('');
    // this.datafilter = 'search=&requested_on=';
    this.applyfilter();
  }

  applyfilter(){
    this.submitted      = true;
    // this.child.clearBar();
    // this.offset = 0;
    let selectedDate:any;
    this.filterpanel=false;
    if (this.filterForm.invalid) {
      return;
    }
    let category_list     = this.filterForm.controls.category_list.value;
    let req_date      = this.filterForm.controls.req_date.value;
    if(req_date.startDate!=null && req_date.startDate!=''){

      let start         = moment(req_date.startDate._d).format('YYYY-MM-DD');
      let end           = moment(req_date.endDate._d).format('YYYY-MM-DD');
      selectedDate      = start + ',' + end;
    }
    this.datafilter = 'request_type__in='+(category_list!=null && category_list!="" ?category_list:'')+'&requested_on__range='+(selectedDate!=null && selectedDate!="" ?selectedDate:'');
    this.child.clearBar();
    this.offset = 0;
    this.showDataItems();
  }

  closeInfo(bool:any){
    this.alertToggle = false;
  }

  /******** USER FUNCTION  ****/
  isUser(){

    this.loader     = false;
    this.tabLoader  = false;
    this.isEmployee = false;
    this.nodata     = true;
  }

  /******** RECENT REQUEST FUNCTION  ****/
  recentRequestType(){
    this.investmentService.recentUsedRequest().subscribe((res: any) => {
      this.recentUsedReq  = res;
      // this.loader         = false;
      this.loader3 = false;
      this.loader2 = false;
    });
  }

  showDataItems(){
    // this.loader = true;
    this.tabLoader = true
    if(this.activeToggle == ''){
      this.investmentService.requestAllList(this.defaultlimit,this.offset,this.searchKeyword,this.datafilter).subscribe((res:any)=>{
        if(this.offset ==0){
          this.requests = [];
        }
        this.detailsList(res.results);
      });

      // Request landing page search API (All)
      this.investmentService.requestAllSearch(this.datafilter).subscribe((res: any) => {
        this.searchRes(res);
      });

    }else{
      this.investmentService.requestList(this.activeToggle,this.defaultlimit,this.offset,this.datafilter,this.searchKeyword).subscribe((res:any)=>{
        if(this.offset ==0){
          this.requests = [];
        }
        this.detailsList( res.results);
      });

      // Request landing page search API (Except All)
      this.investmentService.requestSearch(this.activeToggle,this.datafilter).subscribe((res: any) => {
        this.searchRes(res);
      });

    }

  }

  dropdownData : any = []
  showReqDataItems(){
    this.loader2 = true;
    this.investmentService.requestTypeList(this.searchKeywordRequestType).subscribe((res: any) => {
      this.reqTypeSearchlistdata = res;
      this.dropdownData = res;
    });
    // this.investmentService.requestTypeListfilter().subscribe((res: any) => {
    //   this.category_list = res;
    //   this.selectAllForDropdownItems(this.category_list);
    // });
  }

  requestFilterData(){
    this.investmentService.requestTypeListfilter().subscribe((res: any) => {
      this.category_list = res;
      this.selectAllForDropdownItems(this.category_list);
    });
  }

  detailsList(results:any){
    this.listlength = results.length;
    if (results.length != 0) {
      this.requestDetailsList =  results;
    }
    this.infinityloader = false;
    this.addItems(this.listlength);
    this.nodata = this.requests.length==0?true:false;
    // this.loader = false;
    this.tabLoader = false;
  }

  searchRes(searchres:any){
    for(let i=0; i< searchres.length; i++){
      searchres[i]['name'] = searchres[i]['request_type__name']
    }
    this.searchlistdata = searchres;
    // this.loader = false;
    this.tabLoader = false;
  }

 /********* INIFNITY SCROLL FUNCTION *******************/
 onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.showDataItems();
      // this.loader       = false;
      this.tabLoader    = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }

  /********* INIFNITY SCROLL DATA CREATION ****************/
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.requests.push(this.requestDetailsList[i]);
    }
  }

  /********* INVESTMENT RESTRICTIONS ****************/
  getRequest(investType:any){

    this.requestName = investType;

    let currentYear:any     = new Date().getFullYear();
    let currentDate:any     = new Date().getDate();
    let currentMonth:any    = new Date().toLocaleString('default', { month: 'long' });
    let currentMonthNumber  = new Date(Date.parse(currentMonth +" 1, "+currentYear+"")).getMonth()+1;
    let setYear:any;

    if(investType == 'Confirmed Investment Declaration' || investType == 'Proposed Investment Declaration'){
      // this.showSidePanel  = false;
      this.alertToggle    = true;
      this.cond           = true;
      // if(this.investmentCnt == 'Not tax settings configured' && (this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned')){
      //   this.investalertMsg = 'Tax setting and Salary structure are not configured';
      // }
      if(this.investDetailsData.count_info?.statutory_tax_settings == 'Not tax settings configured'){
        if(investType == 'Confirmed Investment Declaration')
            this.investalertMsg = 'Confirmed investment declaration cannot be raised as tax settings has not been configured yet.'
        if(investType == 'Proposed Investment Declaration')
            this.investalertMsg = 'Proposed investment declaration request cannot be raised as tax settings has not been configured yet.'
      }
      else if(!this.investDetailsData.count_info.is_payroll_configed){
        // this.investalertMsg = 'Payroll configuration is not configured.';
        if(investType == 'Confirmed Investment Declaration')
          this.isConfigured('payrollConfig','Confirmed investment declaration');
        if(investType == 'Proposed Investment Declaration')
          this.isConfigured('payrollConfig','Proposed investment declaration');
      }
      else if(this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned'){
        if(investType == 'Confirmed Investment Declaration')
          this.isConfigured('salaryStructure','Confirmed investment declaration');
        if(investType == 'Proposed Investment Declaration')
          this.isConfigured('salaryStructure','Proposed investment declaration');
      }
      else if(this.investDetailsData.count_info?.statutory_tax_settings?.is_employee_under_flat_rate){
        if(investType == 'Confirmed Investment Declaration')
          this.isConfigured('flattax','Confirmed investment declaration');
        else if(investType == 'Proposed Investment Declaration')
          this.isConfigured('flattax','Proposed investment declaration');
      }
      else{
        this.cond         = false;
        this.alertToggle  = false;
        if(investType == 'Confirmed Investment Declaration'){

          // Payroll configuration for confirmed start
          if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){
            let fyMonthNumber =  new Date(Date.parse(this.investDetailsData.count_info.payroll_config_data.confirmed_declaration_fy_month +" 1, "+currentYear+"")).getMonth()+1;

            let todate = new Date();

             var ci = [1, 2, 3].indexOf(currentMonthNumber);
             var fi = [1, 2, 3].indexOf(fyMonthNumber);

             if(ci != -1 && fi != -1){
               setYear = currentYear;
               // CD => 9-1-2023 FD => 12-2-2023
              }
              else if(ci == -1 && fi == -1){
              // CD => 9-12-2023 FD => 12-12-2023
               setYear = currentYear;
              }
              else if(ci == -1 && fi != -1){
              // CD => 9-12-2023 FD => 12-2-2024
                setYear = Number(currentYear) + 1;
              }
              else if(ci != -1 && fi == -1){
              // CD => 9-1-2023 FD => 12-12-2022
              setYear = this.appservice.getFinancialYear();
              }
              let fromdate          = (new Date(setYear+"-"+fyMonthNumber+"-"+this.investDetailsData.count_info.payroll_config_data.confirmed_declaration_fy_date));

              if(moment(todate).format('YYYY-MM-DD') > moment(fromdate).format('YYYY-MM-DD')){
                this.alertToggle    =  true;

                this.investalertMsg = 'The last date for submitting confirmed investment declaration for the current financial year has lapsed, please contact your HR.'
              }
              else if(this.investDetailsData.count_info.payroll_config_data?.is_today_in_proposed_cuttoff_range && !(this.investDetailsData.count_info.is_payslip_released) ){

                this.isConfigured('cutOffData','confirmed investment declaration');
              }
              else if(this.investDetailsData.count_info?.is_confirmed_it_declaration_pending){
                this.isConfigured('limitExceed','confirmed investment declaration');
              }

              else{
                if(this.investDetailsData?.count_info?.statutory_tax_settings?.max_con_investment_declare_request >= 0 && (this.investDetailsData?.count_info?.statutory_tax_settings?.max_con_investment_declare_request <= this.confirmed_cnt)){
                  this.cond           = true;
                  this.alertToggle    = true;
                  this.investalertMsg = 'You cannot raise confirmed investment declaration request as you have exceeded the request limit for this month.';
                }else{
                  this.router.navigate(['/confirmed-investment-decleration']);
                }
              }

            }
        }
          // Payroll configuration for confirmed end

        else if(investType == 'Proposed Investment Declaration'){

          // Payroll configuration for proposed start

          if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){
            let fyMonthNumber =  new Date(Date.parse(this.investDetailsData.count_info.payroll_config_data.proposed_declaration_fy_month +" 1, "+currentYear+"")).getMonth()+1;

            let todate = new Date();

            var ci = [1, 2, 3].indexOf(currentMonthNumber);
            var fi = [1, 2, 3].indexOf(fyMonthNumber);

            if(ci != -1 && fi != -1){
              setYear = currentYear;
              // CD => 9-1-2023 FD => 12-2-2023
            }
            else if(ci == -1 && fi == -1){
              // CD => 9-12-2023 FD => 12-12-2023
              setYear = currentYear;
            }
            else if(ci == -1 && fi != -1){
              // CD => 9-12-2023 FD => 12-2-2024
              setYear = Number(currentYear) + 1;
            }
            else if(ci != -1 && fi == -1){
              // CD => 9-1-2023 FD => 12-12-2022
              setYear = this.appservice.getFinancialYear();
            }
            let fromdate  = (new Date(setYear+"-"+fyMonthNumber+"-"+this.investDetailsData.count_info.payroll_config_data.proposed_declaration_fy_date));


            if((moment(todate).format('YYYY-MM-DD') > moment(fromdate).format('YYYY-MM-DD')) && (!this.investDetailsData.count_info.payroll_config_data.proposed_allow_submit_aftercuttoff)){

              this.alertToggle    =  true;

              this.investalertMsg = 'The last date for submitting proposed investment declaration for the current financial year has lapsed, please contact your HR.';

            }
              else if(this.investDetailsData.count_info.payroll_config_data?.is_today_in_proposed_cuttoff_range && !(this.investDetailsData.count_info.is_payslip_released) ){

                this.isConfigured('cutOffData','proposed investment declaration');
              }
              else if(this.investDetailsData.count_info?.is_proposed_it_declaration_pending){
                this.isConfigured('limitExceed','proposed investment declaration');
              }
            // Payroll configuration for proposed end
            else{

                if(this.investDetailsData?.count_info?.statutory_tax_settings?.max_pro_investment_declare_request >= 0 && (this.investDetailsData?.count_info?.statutory_tax_settings?.max_pro_investment_declare_request <= this.proposed_cnt)){
                this.cond           = true;
                this.alertToggle    = true;
                this.investalertMsg = 'You cannot raise proposed investment declaration request as you have exceeded the request limit for this month.';
                }else{
                this.router.navigate(['/proposed-investment-decleration']);}
            }

          }
        }
      }

    }
    else if(investType == 'On Duty'){

      if(!this.eligibileData.on_duty){
        this.alertToggle = true
        this.investalertMsg = 'As per policy, You are not eligible for On-duty request';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'On-duty request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.ondutyRequest();
      }
    }
    else if(investType == 'Leave'){

      if(!this.investDetailsData.count_info?.leave_configured){
        this.alertToggle = true
        this.investalertMsg = 'Leave request cannot be raised as Leave policy is not configured yet.';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Leave request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.leaveRequest();
      }

    }
    else if(investType == 'Compensatory Off'){

      if(!this.investDetailsData.count_info?.compoff_data?.enable_compoff){
        this.alertToggle = true
        this.investalertMsg = 'Compensatory off request cannot be raised as it has not been configured yet.';
      }else if(!this.investDetailsData.count_info?.leave_configured){
        this.alertToggle = true
        this.investalertMsg = 'Comp-off cannot be availed as the leave policy is not configured yet.';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Compensatory off request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.compOffRequest(this.currentYear);
      }
    }
    else if(investType == 'Holiday'){
      if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Holiday request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.holidayRequest();
      }
    }
    else if(investType == 'Work From Home'){

        if(!this.investDetailsData.count_info?.is_wfh_allowed){
          this.alertToggle = true;
          this.investalertMsg = 'As per policy, You are not eligible for WFH request.';
        }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
          this.alertToggle = true;
          this.investalertMsg = 'WFH request cannot be raised as attendance rule has not been assigned yet.';
        }else{
          this.WFHRequest();
        }
    }
    else if(investType == 'Attendance Regularization'){
      if(!this.regularizationEligibleData.is_attend_regular_allowed){
        this.alertToggle = true
        this.investalertMsg = 'As per policy, You are not eligible for Attendance Regularization request.';
      }else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Attendance Regularization request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.regularizationRequest();
      }

    }
    else if(investType == 'Short Leave'){
      if(!this.investDetailsData?.count_info.short_leave_configured){
        this.alertToggle = true
        this.investalertMsg = 'Short leave request cannot be raised as short leave policy has not been configured yet.'
      }
      else if(!this.investDetailsData.count_info?.attend_rule_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Short leave request cannot be raised as attendance rule has not been configured yet.';
      }
      else{
        this.shortLeaveRequest()
      }
    }
    else if(investType == 'Reimbursement'){
      if(this.investDetailsData.count_info?.statutory_tax_settings == 'Not tax settings configured'){
        this.isConfigured('taxSettings','Reimbursement');
      }
      else if(!this.investDetailsData.count_info?.is_payroll_configed){

        this.isConfigured('payrollConfig','Reimbursement');
      }
      else if(this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned'){
        this.isConfigured('salaryStructure','Reimbursement');
      }
      else if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){

        if(this.investDetailsData.count_info?.payroll_config_data?.is_today_in_reimbursemen_cuttoff_range
          && !(this.investDetailsData.count_info.is_payslip_released)){

          this.isConfigured('cutOffData','reimbursement');
        }
        else
        this.reimbursementRequest();
      }
    }
    else if(investType == 'FBP Declaration'){

      if(this.investDetailsData.count_info?.statutory_tax_settings == 'Not tax settings configured'){

        this.isConfigured('taxSettings','FBP');
      }

      else if(!this.investDetailsData.count_info.is_payroll_configed){
        this.isConfigured('payrollConfig','FBP');

      } else if(this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned'){
        this.isConfigured('salaryStructure','FBP');
      }
      else if(this.investDetailsData.count_info.hasOwnProperty('payroll_config_data')){

        if(this.investDetailsData.count_info?.is_FBP_restricted){
          this.isConfigured('cutOffData','fbp');
        }
        else
        this.FBPDeclaration();
      }
    }else if(investType == 'Asset'){
      this.assetRequest();
    }else if(investType == 'Expense Report'){

      if(!this.investDetailsData?.count_info?.expense_policy_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Expense report request cannot be raised as expense policy is not configured yet.';

      }else if(!this.investDetailsData?.count_info?.advance_policy_assigned){
        this.alertToggle = true;
        this.investalertMsg = 'Expense report request cannot be raised as advance policy is not configured yet.';
      }else if(this.investDetailsData.count_info?.expense_settings == null){
        this.isConfigured('expenseConfig','Expense report');
      }else if(!this.investDetailsData.count_info?.expense_settings?.submission_window){
          this.isConfigured('cutOffData1','expense report');
      }
      else{
        this.expenseReport();
      }
  }

    else if(investType == 'Expense Advance'){
      this.expenseAdvanceRequest();
    }
    else if(investType == 'Loan'){
      if(!this.investDetailsData?.is_loan_available){
        this.alertToggle = true
        this.investalertMsg = "As no loan component is mapped to your paygroup, you can't raise a loan request"
      }else{
        this.loanRequestSetup()
      }
    }

  }

  isConfigured(type:any,reqType:any){
    // this.showSidePanel  = false;
    this.alertToggle    = true;
    if(type ==  'salaryStructure')
      this.investalertMsg = reqType+' request cannot be raised as salary structure has not been assigned yet.';
    else if(type == 'payrollConfig')
      this.investalertMsg = reqType+' request cannot be raised as payroll settings has not been configured yet.';
    else if(type == 'cutOffData')
      this.investalertMsg = 'The '+reqType+' request window has closed and will reopen on the beginning of next pay cycle.';
    else if(type == 'limitExceed')
      this.investalertMsg = this.messageService.requestRestriction('Employee');
    else if(type == 'taxSettings')
      this.investalertMsg = reqType+' request cannot be raised as tax settings has not been configured yet.';
    else if(type == 'notConfigured')
      this.investalertMsg = 'You cannot raise a '+reqType+' request, as it is not configured';
    else if(type == 'cutOffData1')
      this.investalertMsg = 'The '+reqType+' submission window has closed and will reopen at the beginning of the next pay cycle.';
    else if(type == 'expenseConfig')
      this.investalertMsg = reqType+' request cannot be raised as expense settings has not been configured yet.';
    else if(type == 'flattax')
      this.investalertMsg = 'The '+reqType+' is restricted for your employment type, please contact HR for more details.';
  }

  newRequestSidePanelFunction(){
    this.showSidePanel            = true;
    this.loader3 = true;
    this.showReqDataItems();
    this.recentRequestType();
    this.requestDetails();
  }

  /******** SEARCH FUNCTION  ****/
  searchresults(data:any){
    // this.loader = true;
    this.tabLoader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset = 0;
      this.requests = [];
      this.requestDetailsList = [];
      // this.showDataItems();
      this.searchData.next();
    }
    else{
      this.investmentService.singleRequest(data).subscribe((res: any) => {
        this.requests = res;
        // this.loader = false;
        this.tabLoader = false;
      });
    }
  }

  searchtyperesults(data:any){
    this.requestSearchData = data;
    if(data == ""){
      this.searchKeywordRequestType = "";
      this.reqTypeSearchlistdata = [];
       this.showReqDataItems();
       this.recentRequestType();
    }
    else{
      this.investmentService.getSingleReqType(data).subscribe((res: any) => {
        this.reqTypeSearchlistdata = res;
      });
    }
  }

  searchrequestType(searchKeyword:any){

    this.reqTypeSearchlistdata= [];
    this.searchKeywordRequestType = searchKeyword;
    if(searchKeyword != ""){
      this.showReqDataItems();
      this.recentRequestType();
    }
  }

  newRequestPanelClose(){
    this.showSidePanel            = false;
    this.searchKeywordRequestType = "";
    this.requestSearchData        = "";
  }

  selectItemList(value:any){
    if(this.isEmployee){
      // this.loader             = true;
      this.tabLoader          = true;
      this.activeToggle       = value;
      this.offset             = 0;
      this.requests           = [];
      this.requestDetailsList = [];
      // this.showDataItems();
    }else{
      this.isUser();
    }
    this.child.clearBar();
  }

  /********* VIEW REQUESTS ****************/
  viewRequest(req:any){
    var req_obj = req.request_object
    var req_type_name = req.request_type_name
    var req_id = req.id
    var declare_status = req.declaration_status
    var status = req.status

    this.requestId    = req_id;
    this.requestName  = req_type_name;

    if((status == 'Invalid' && (req_type_name == 'Leave' || req_type_name == 'Attendance Regularization' || req_type_name == 'On Duty' || req_type_name == 'Work From Home' || req_type_name == 'Overtime' || req_type_name == 'Compensatory Off' || req_type_name == 'Holiday' || req_type_name == 'Short Leave' || req_type_name == 'Asset' || req_type_name == 'Employee Profile')) || (status != 'Invalid')){

      if(req_type_name == 'Confirmed Investment Declaration' || req_type_name == 'Proposed Investment Declaration' ){
        this.viewInvestment(req_obj,req_type_name,declare_status);
      }else if(req_type_name == 'Holiday' || req_type_name == 'Holiday Cancellation'){
        if(status == 'Invalid'){
          this.alertToggle1       = true;
          this.invalidReqAlertMsg = 'Due to update in holiday configuration, the inbox request you are trying to access is no longer available'
        }else{
          this.viewHolidayRequest(req_id,req_type_name)
          this.requestId   = req_id;
          this.requestName = req_type_name;
        }

      }else if(req_type_name == 'Leave' || req_type_name == 'Leave Cancellation'){
        this.viewLeaveRequest(req_id,req_type_name)
      }
      else if(req_type_name == 'Employee Profile'){
        if(req.request_subcategory == "document" && status=='Invalid'){
          this.alertToggle1 = true;
          this.invalidReqAlertMsg = 'Due to the deletion of the related document from the master data, the request you are trying to access is no longer available.'
        }else{
          this.viewEmployeeProfile(req_type_name,req_id)
          this.currentStaus = status
        }
      }
      else if(req_type_name == 'FBP Declaration'){
        this.viewFbpDeclaration(req_type_name,req_id)
      }
      else if(req_type_name == 'Reimbursement' || req_type_name == 'Reimbursement Cancellation')
        this.viewrReimbursement(req_type_name,req_id)

      else if(req_type_name == 'Attendance Regularization' || req_type_name == 'Attendance Regularization Cancellation'){
        if(req_type_name == 'Attendance Regularization Cancellation'){
          this.regularizeCancel = true
          req_type_name = 'Attendance Regularization'
        }else{
          this.regularizeCancel = false
        }
         this.viewAttendanceRegularization(req_type_name,req_id)
      }else if(req_type_name == 'On Duty' || req_type_name == 'On Duty Cancellation'){
          if(req_type_name == 'On Duty Cancellation'){
            this.onDutyCancel = true
            req_type_name = 'On Duty'
          }else{
            this.onDutyCancel = false
          }
        this.viewOnDuty(req_type_name,req_id)
      }else if(req_type_name == 'Work From Home' || req_type_name == 'Work From Home Cancellation'){
        if(req_type_name == 'Work From Home Cancellation'){
            this.workFromHomecancel =true;
            req_type_name = 'Work From Home'
          }else{
            this.workFromHomecancel =false;
          }
        this.viewWhf(req_type_name,req_id)
      }else if(req_type_name == 'Overtime')
        this.viewOvertime(req_type_name,req_obj)
      else if(req_type_name == 'Compensatory Off')
        this.viewCompOff(req_type_name,req_id)
      else if(req_type_name == 'Check-in Request'){
        this.viewCheckInRequest(req_type_name,req_id)
      }else if(req_type_name == 'Asset'){
        this.viewAsset(req_type_name,req_id)
      }else if(req_type_name == 'Expense Report'){
        this.viewExpenseReport(req_type_name,req_id)
      }else if(req_type_name == 'Expense Advance'){
        this.expenseAdvanceView(req_type_name,req_id);
      }
      else if(req_type_name == 'Short Leave' || req_type_name == 'Short Leave Cancellation'){
        if(req_type_name == 'Short Leave Cancellation'){
          req_type_name = 'Short Leave'
        }
        this.viewShortLeaveRequest(req_type_name,req_id)
      }else if(req_type_name == 'Loan'){
        this.viewLoanRequest(req_type_name,req_id);
      }
    }else{
      this.alertToggle1 = true;
      this.invalidReqAlertMsg = 'Due to update in salary structure, the inbox request you are trying to access is no longer available'

    }

  }

   /******** REQUEST VIEW STATUS  ****/
   viewStatId:any = '';
   viewStatApprovalRequired:any;
   viewStatusLoader = false;
   requestViewStatus(reqId: any, approvalRequired:any,event:any){
     if(event != null && event != '' && event != undefined)
        event.stopPropagation();
     this.viewStatId  = reqId;
     this.viewStatApprovalRequired = approvalRequired;
     this.viewstatusPanel     = true;
     this.viewStatusLoader    = true;
     if(approvalRequired){
      this.approvalRequired = true;
      if(typeof reqId === 'number'){
        this.investmentService.requestViewStatus(reqId).subscribe((res: any = []) =>{
          this.viewStatusData     = res;
          this.fromViewStatus     = res?.request_type == 'Reimbursement' ? 'reimbursement-request' :( res?.request_type == 'Employee Profile' && res?.category == 'document') ? 'employee-request' : res?.request_type == 'Attendance Regularization' ? 'regularization-request' : res?.request_type == 'On Duty' ? 'onduty-request' : res?.request_type == 'Asset' ? 'asset-request' : '';
          if(!res?.apporval_matrix_loading)
            this.viewStatusLoader   = false;
          if(res.status == 1){
            this.viewStatusLevelCnt = res?.activity?.level_count;
            this.viewStatusmaxLevel = res?.activity?.maxlevel;
          }
        });
      }
     }
     else{
      this.approvalRequired = false;
      this.viewStatusLoader = false;
     }

   }

   onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  /********* INVESTMENT DECLERATIONS VIEW ****************/
  viewInvestment(req_obj: any,req_type_name: any,declare_status: any){
      var viewId    = req_obj;
      this.loader1  = true;
      this.declareStatus  = declare_status;
      if(req_type_name == 'Confirmed Investment Declaration'){
        this.viewHeader = "Confirmed Investment Declaration";
      }else{
        this.viewHeader = "Proposed Investment Declaration";
      }
      this.investmentService.investmentSingleData(viewId).subscribe((res: any = []) =>{
        this.investmentSingleData   = res;
        this.hra80ggSingleData      = res?.hra80gg;
        this.cahpt80CSingleData     = res?.chapter4a80c;
        this.diductionSingleData    = res?.chapter4adeduction;
        this.otherSourceSingleData  = res?.income_from_othersource;
        this.incomeLossSingleData   = res?.incomeloss_property;
        this.taxData                = JSON.parse(res?.declared_datail);
        this.currency               = res?.currency;

        if(this.taxData?.excemption_declaration?.hasOwnProperty('hra'))
        this.hra80gg='hra';
      if(this.taxData?.excemption_declaration?.hasOwnProperty('80gg'))
        this.hra80gg='80gg';
        if(res.hra80gg?.length > 0){
            if(res.hra80gg[0]['select_one'] == true)
              this.hra80ggFlag = true;
            else
              this.hra80ggFlag = false;
        }

        if(this.diductionSingleData?.donate_80g?.length > 0){
          for(let i=0; i< this.diductionSingleData?.donate_80g?.length; i++){
            if(!this.diductionSingleData?.donate_80g[i]?.exemption){
              this.exeption_50.push(this.diductionSingleData?.donate_80g[i])
            }
          }
        }
        this.loader1           = false;
        // if(this.hra80ggSingleData.city != null && this.hra80ggSingleData.city != 'null')
        // this.getCity(this.hra80ggSingleData.city);
      });
      this.investmentDeclerationView = true;
      this.requestId    = req_obj;
      this.requestName  = req_type_name;
  }

  /********* HOLIDAY REQUEST VIEW ****************/
  viewHolidayRequest(req_obj: any,req_type_name:any){
    this.requestName  = req_type_name;
    this.holidayRequestView = true;
    this.loader1 = true;
    this.requestService.viewRequest('Holiday',req_obj).subscribe((res: any = []) =>{
      this.holidayRequestSingleData = res?.data;
      this.loader1                  = false;
    });
  }

  holidayViewClose(data:any){
    this.holidayRequestView = data;
  }

  /********* LEAVE REQUEST VIEW ****************/
  viewLeaveRequest(req_id: any,req_type_name:any){
    this.loader1          = true;
    this.compOffData      = [];
    this.leaveRequestView = true;
    this.requestService.viewRequest('Leave',req_id).subscribe((res: any = []) =>{
      this.leaveRequestData = res?.data;

      // Comp off data construction
      if(this.leaveRequestData?.related_compoff_data?.length > 0){
        for(let i=0;i<this.leaveRequestData?.related_compoff_data?.length;i++){

          let attendance_date = this.appservice.dateFormatDisplay(this.leaveRequestData?.related_compoff_data?.[i]?.attendance_date);
          let eligible_compoff = this.leaveRequestData?.related_compoff_data?.[i]?.eligible_compoff == 'Full Day' ? '1 day' : '0.5 day';

          this.compOffData?.push(attendance_date+' - '+eligible_compoff);

        }
      }
      //End

      this.loader1 = false;
    });
  }

  leaveViewClose(data:any){
    this.leaveRequestView = data;
  }

  /********* EMPLOYEE PROFILE VIEW ****************/
  viewEmployeeProfile(req_type_name:any,req_id:any){
    this.reqIds = req_id;
    this.empRequestData = [];
    this.empRequestView = true;
    this.loader1 = true;
    this.requestService.viewRequest(req_type_name,req_id).subscribe((res: any = []) =>{
      if(res.status == 1){
      this.empRequestData = res.data;
      this.loader1 = false;}
    });
  }

  employeeProfileViewClose(){
    this.empRequestView = false;
  }

  /********* GET CITY FUNCTION ****************/
  getCity(cityid:any){
    if(cityid != null){
      this.cityService.getCity(cityid).subscribe((res: any) => {
      this.cityName = res.name;
      });
    }
  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  // viewDocument(filename:any){
  //   this.modalToggle      = true;
  //   this.fileName         = filename;
  //   this. currentDocName   = this.fileName.split('_').pop();
  //   this.currentExtension = this.fileName.split('.').pop();
  //   if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx'){
  //     this.modalToggle      = false;
  //     this.alertToggle = true;
  //     this.investalertMsg = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
  //   }
  // }

  /********* GET DOCUMENT NAME ****************/
  // getDocName(name:string){
  //   var docname : any  = name.split('_').pop();
  //   docname = docname.replace(/[&\/\\#,+()$~%'":*?<>{}0-9]/g, '', "");
  //   return docname;
  // };
  /***********REIMBURSEMENT VIEW**************** */
  viewrReimbursement(req_type_name: any,req_obj:any){
    this.loader1          = true;
    this.empReimburseView = true;
    this.reimbursementData= [];
    this.reimbursementType = req_type_name;
    this.requestService.viewRequest('Reimbursement',req_obj).subscribe((res: any = []) =>{
      this.reimbursementData = res.data;
      this.requestId         = req_obj;
      this.loader1           = false;
    });
  }

  dateFormatDisplay(date:any){
    return this.datePipe.transform(date, this.appservice.getdateformat())?.toString();
  }

  closeSidePanel(data:any){
    this.investmentDeclerationView = data;
  }

  /********* ON DUTY START ****************/
  ondutyRequest(){
    this.ondutysidepanel=true;
    this.router.navigate(['/on-duty-setup']);
  }
  /********* ON DUTY END ****************/

  /********* LEAVE REQUEST START ****************/
  leaveRequest(){
    this.router.navigate(['/leave-request-setup']);
  }
  /********* LEAVE REQUEST END ****************/

  /********* REGULARIZATION REQUEST START ****************/
  regularizationRequest(){
    this.router.navigate(['/regularization-setup']);
  }
  /********* REGULARIZATION REQUEST END ****************/

  /********************Reimbursement************************ */
  reimbursementRequest(){
    this.router.navigate(['/reimbursement-setup']);
  }
  /********************Reimbursement************************ */

  WFHRequest(){
    this.router.navigate(['/work-from-home-setup']);

  }
  /********* FLOATING HOLIDAY REQUEST START ****************/
  holidayRequest(){
    this.holidayRequestFlag = true;
    // this.showSidePanel      = false;
    this.holidayLists();
    this.searchHoliday();
  }

  // test start
  expenseReport(){
    this.router.navigate(['/request/expense-reports-setup']);
  }
  // test end

  /********* FLOATING HOLIDAY LIST ****************/
  holidayLists(){
    this.saveClicked    = false;
    this.holiday_loader = true;
    this.investmentService.holidayFloatList('holiday_date','').subscribe((res: any) => {
      this.holiday_loader   = false;
      this.holidayFloatData = res;

      if(this.holidayFloatData?.length == 0){
        this.holiday_nodata = true;
      }
      else{
        this.holiday_nodata = false;
      }
    });
  }

  /********* SINGLE FLOATING HOLIDAY ****************/
  singleHoliday(id: number){
    this.holiday_loader = true;
    this.investmentService.holidayFloatGet(id).subscribe((res: any) => {
      this.holidayFloatData = res;
      this.holiday_loader = false;
    });
  }

  /********* SEARCH HOLIDAY DROP DOWN FUNCTION ****************/
  searchHoliday(){
    this.holiday_loader = true;
    this.investmentService.holidayFloatDropDown().subscribe((res: any) => {
      for(let i=0; i< res.length; i++){
        if(res[i]['day_name'] != null && res[i]['day_name'] != '')
          res[i]['name'] = res[i]['holiday_name'] +' '+ 'day ' +res[i]['day_name'];
        else
          res[i]['name'] = res[i]['holiday_name'];
      }
      this.holiday_loader     = false;
      this.holidatSearchData  = res;
    });
  }

  /********* HOLIDAY SIDEBAR CLOSE FUNCTION ****************/
  holidayReqCloseFunction(data: any){

    if(data?.action == 'close')
      this.holidayRequestFlag = data?.show;

    if(data?.action == 'save'){
      this.holidayLists();
      this.offset             = 0;
      this.requests           = [];
      this.requestDetailsList = [];
      this.showDataItems();
    }
  }

  /********* HOLIDAY SIDEBAR CLOSE FUNCTION ****************/
  holidayReqSaveFunction(data: any){
     this.investmentService.holidayFloatCreate(data.requestData).subscribe((res: any) => {
      this.saveClicked        = data.requestData;
      this.holidayRequestFlag = false;
      this.showSidePanel      = false;
      this.offset             = 0;
      this.requests           = [];
      this.requestDetailsList = [];
      this.showDataItems();
    });
  }

  /********* SEARCH HOLIDAY FUNCTION ****************/
  holidaySearchFunction(data: any){
    if(data.search == false){
      this.holidayLists();
    }else{
      this.singleHoliday(data.searchid);
    }
  }

  /********* COMPENSATORY OFF REQUEST SETUP FUNCTION ****************/
  compOffRequest(year:any){
    this.compOffRequestFlag = true;
    // this.showSidePanel      = false;
    this.compOffListData    = [];
    this.holiday_loader     = true;
    this.compensatoryOffService.getCompOffRequestList(this.emp_Id,Number(year)).subscribe((res: any) => {
      this.holiday_loader  = false;
      // let all_expired      = res?.every((key:any) => key?.is_expired === true);
      this.compOffListData = res;
      if(this.compOffListData?.length == 0){
        this.holiday_nodata = true;
      }
      // else if(all_expired){
      //   this.holiday_nodata = true;
      // }
      else{
        this.holiday_nodata = false;
      }
    });
  }

  compOffReqCloseFunction(){
    this.compOffRequestFlag = false;
  }

  /********** SHORT LEAVE *******/
  shortLeaveRequest(){
    this.router.navigate(['/short-leave-setup']);
  }
  /********** SHORT LEAVE ENDS *******/

  /********* FLOATING HOLIDAY REQUEST END ****************/
  searchrequest(searchKeyword:any){
    // this.loader = true;
    this.tabLoader = true;
    this.offset           = 0;
    this.requests= [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      this.searchData.next()
      // this.showDataItems();
    }
  }

   /********* FBP DECLARATION START ****************/
   FBPDeclaration(){
    this.fbdeclarationFlag = true;
   }

  fbpReqClose(data:any){
    this.fbdeclarationFlag = data;
  }

  fbpReqSaveFunction(data: any){
    this.investmentService.fbpRequestCreate({'fbp_request_category': data.fbp_request_category}).subscribe((res: any) => {
      this.fbdeclarationFlag = false;
      this.showSidePanel      = false;
      this.offset             = 0;
      this.requests           = [];
      this.requestDetailsList = [];
      this.showDataItems();
    });

  }

  viewFbpDeclaration(req_type_name:any,req_id:any){
    this.fbpRequestView = true;
    this.loader1        = true;
    this.requestService.viewRequest(req_type_name,req_id).subscribe((res: any) => {
     this.loader1        = false;
     this.fbpStatus      = res?.data?.status;
     this.fbpReqViewData = res?.data?.fbp_request_category;
     this.currency       = res?.data?.currency;
    });
   }

  fbbDeclarationViewClose(){
    this.fbpRequestView = false;
  }

  /********* FBP DECLARATION START ****************/
  clearcommonForm(item:any,index:number){
    if(item == "category_list"){
       let daata = this.filterForm.value.category_list;
       daata.splice(index, 1);
       this.filterForm.patchValue({
        category_list: daata
       });
     }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  closeViewPage(data:any){
    this.empReimburseView   = data;
    this.holidayRequestView = data;
    this.leaveRequestView   = data;
    this.shortLeaveView     = data
    this.loanView           = data
    this.showDataItems();
  }

  // Loan Request
  loanRequestSetup(){
    this.router.navigate(['/loan-setup']);
  }

  viewLoanRequest(req_type_name:any, req_id:any){
    this.loanData = []
    this.loader1 = true;
    this.loanView = true
    this.reqName = req_type_name
    this.loanId = req_id
    this.requestService.viewRequest(req_type_name,req_id).subscribe((res:any)=>{
      this.loanData = res?.data
      this.loader1 = false
    })
  }

  closeLoanPanel(data:any){
    this.loanView = data
  }
/************************************* TIME & ATTENDANCE VIEW SECTIONS START *******************/

  viewAttendanceRegularization(req_type_name:any,req_id:any){
    this.viewPanel = true;
    this.reqName = req_type_name
    this.loader_regularization = true
    this.loader1 = true
    this.requestService.viewRequest('Attendance Regularization',req_id).subscribe((res:any)=>{
      this.regularizationData = res?.data?.requests
      this.regualarizationID  = req_id
      this.loader_regularization = false
      this.loader1 = false
    })
  }

  regualarizationEligibility(){
    this.regularizationServ.getCheckEligibility(this.emp_Id).subscribe((res:any)=>{
      this.regularizationEligibleData = res?.data
    })
  }

  viewOnDuty(req_type_name:any,req_id:any){
    this.viewPanel = true;
    this.loader1 = true
    this.reqName = req_type_name
    this.loader_OnDuty = true
    this.requestService.viewRequest(req_type_name,req_id).subscribe((res:any)=>{
      this.onDutyData = res?.data?.requests
      this.onDutyID = req_id
      this.loader_OnDuty = false
      this.loader1 = false
    })
  }

  OnDutyEligibility(){
    this.onDutyServ.checkEligibility(this.emp_Id).subscribe((res:any)=>{
      this.eligibileData = res?.body?.data
    })
  }

  /********* Work from Home View ****************/
  viewWhf(req_type_name:any,req_id:any){
    this.viewPanel = true;
    this.reqName = req_type_name;
    this.loader1 = true;
    this.requestService.viewRequest('Work From Home',req_id).subscribe((res: any = []) =>{
      this.workFromHomedata = res.data;
     this.workFromHomedata['fulldayarr']=[];
     this.workFromHomedata['halfdayarr']=[];
     this.workFromHomedata['totday']= 0;
     this.workFromHomedata['wfhid']=req_id;
     for(let i=0;i<this.workFromHomedata.requests.length;i++){
      if(this.workFromHomedata.requests[i].day_portion=='Full Day'){
        this.workFromHomedata.fulldayarr.push(this.appservice.dateFormatDisplay(this.workFromHomedata?.requests[i]?.wfh_date));
        this.workFromHomedata.totday = this.workFromHomedata.totday+1;
      }else{
        this.workFromHomedata.halfdayarr.push((this.appservice.dateFormatDisplay(this.workFromHomedata?.requests[i]?.wfh_date))+' ('+this.workFromHomedata.requests[i].day_portion+')');
        this.workFromHomedata.totday = this.workFromHomedata.totday+0.5;
      }
    }
        this.loader1 = false;
    });
  }

  viewOvertime(req_type_name:any,req_id:any){
    this.loader1        = true;
    this.viewPanel      = true;
    this.reqName        = req_type_name;
    this.overtimeService.getOvertimeDetails(req_id).subscribe((res: any) => {
      this.loader1      = false;
      this.viewData     = res;
     });
  }

  viewCompOff(req_type_name:any,req_id:any){
    this.viewData   = [];
    this.loader1    = true;
    this.viewPanel  = true;
    this.reqName    = req_type_name;
    this.requestService.viewRequest('Compensatory Off',req_id).subscribe((res: any) => {
      this.loader1  = false;
      this.viewData = res?.data;
     });
  }

  compOffSuccess(data:any){
    this.offset             = 0;
    this.requests           = [];
    this.requestDetailsList = [];
    this.showDataItems();
    this.compOffRequest(data?.year)
  }

  checkInId : any
  viewCheckInRequest(req_type_name:any,req_id:any){
    this.viewData = []
    this.loader1 = true;
    this.viewPanel = true
    this.reqName = req_type_name
    this.checkInId = req_id
    this.requestService.viewRequest(req_type_name,req_id).subscribe((res:any)=>{
      this.viewData = res?.data
      this.loader1 = false
    })
  }

  closePanel(val:any){
    this.viewPanel = val
    // this.loader    = true;
    this.tabLoader = true;
    this.requestDetails();
  }

  // Short Leave
  viewShortLeaveRequest(req_type_name:any,req_id:any){
    this.shortLeaveData = []
    this.loader1 = true;
    this.shortLeaveView = true
    this.reqName = req_type_name
    this.requestService.viewRequest(req_type_name,req_id).subscribe((res:any)=>{
      this.shortLeaveData = res?.data
      this.loader1 = false
    })
  }

  shortLeaveClose(data:any){
    this.shortLeaveView = data
  }
/************************************* TIME & ATTENDANCE VIEW SECTIONS END *******************/


// test start
requestFlag = false;
assetRequest(){
  this.requestFlag = true;
}
/************************************* EXPENSE MODULE START *******************/
// test start

viewExpenseReport(req_type_name:any,req_id:any){
  this.viewPanel = true;
  this.reqName = req_type_name;
  this.loader1 = true;
  this.requestService.viewRequest(req_type_name,req_id).subscribe((res:any)=>{
    this.viewData           = res?.data?.expense_report_data;
    this.viewData['status'] = res?.data?.status;
    this.currency           = res?.data?.currency;
    this.loader1            = false;
  })
}

requestSave(data:any){
  this.requestFlag        = false;
  this.showSidePanel      = false;
  this.offset             = 0;
  this.requests           = [];
  this.requestDetailsList = [];
  this.showDataItems();
}

viewAsset(req_type_name:any,req_id:any){
  this.viewData   = [];
  this.reqIds     = req_id;
  this.loader1    = true;
  this.viewPanel  = true;
  this.reqName    = req_type_name;
  this.requestService.viewRequest('Asset',req_id).subscribe((res: any) => {
    this.loader1  = false;
    this.viewData = res?.data;
   });
}
// test end



  addadvance = false;
  validationdata:any=[];
  advancedatalist: any = [];
  requestViewData:any = [];

viewAdvance = false;


expenseAdvanceRequest(){
  this.ExpenseAdvnaceProfileService.getvaliddata(this.emp_Id).subscribe((res: any) => {
    this.validationdata = res;
    if(this.validationdata.data?.advance_policy==false) {
      this.alertToggle = true;
      this.investalertMsg = 'Advance request cannot be raised as advance policy is not configured yet.';
    }else if(this.validationdata.data?.pending_advance_requests==true) {
      this.alertToggle = true;
      this.investalertMsg = 'This request cannot be raised, as there is a pending advance request.';
    }else if(this.validationdata.data?.pending_dispense_requests==true){
      this.alertToggle =true;
      this.investalertMsg = 'This request cannot be raised, as there is an pending advance dispense.';
    }else{
    this.addadvance = true;
    }
 });

}

closefn(daata:any){
  this.addadvance = daata;
}

detailout(event:any){
  if(event == true){
    this.addadvance         = false;
    this.showSidePanel      = false;
    this.offset             = 0;
    this.requests           = [];
    this.requestDetailsList = [];
    this.showDataItems();
  }
}

expenseAdvanceView(req_type_name:any,req_id:any){
  this.viewAdvance = true;
  this.loader1 = true;
  this.requestService.viewRequest(req_type_name,req_id).subscribe((res:any)=>{
    this.requestViewData = res?.data;
    this.loader1 = false;
  })
}

closeViewPanel(data:any){
  this.viewAdvance = data;

}

// test end

/************************************* END *******************/

}

