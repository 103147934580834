import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { OnDutyService } from 'src/app/@core/services/on-duty.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-on-duty-approval',
  templateUrl: './on-duty-approval.component.html',
  styleUrls: ['./on-duty-approval.component.scss']
})
export class OnDutyApprovalComponent implements OnInit {
  showTrend = false;
  viewDetails = false;
  viewData:any = [];
  pageSize = 20;
  page = 1
  @Input() requestitem: any;
  @Output() successEmitter: EventEmitter<boolean> =  new EventEmitter();
  constructor(public messageService : MessageService,public appService: AppService ,private investmentService:InvestmentDeclerationService,private inboxservice: InboxService,private fb: FormBuilder,private onDutyServ : OnDutyService,private datePipe:DatePipe) { }


  xAxisLabel:any = [];
  yAxisLabel: string = 'Days';
  graphLoader             = false;
  year:any=false;
  month:any=false;
  alertShow = 'show';
  today =  this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString()


  onSelect(event:any) {

  }
  ngOnChanges(): void{
    this.page=1
    if(this.requestitem?.request_data?.is_cancel)
      this.viewOnDuty('On Duty',this.requestitem?.request_data?.id)
    this.yearListCreate();
  }
  ngOnInit(): void {

  }
  billLoader                      = false;
  viewstatusPanel                 = false;
  viewStatusData:any              = [];
  viewStatusLevelCnt:any;
  viewStatusmaxLevel:any;

  // For year dropdown in on duty request trend
  currentYear =  new Date().getFullYear();
  selectedYearDisp = String(this.currentYear)
  selectedYear = this.currentYear
  yearList: any = [];
  multi: any = [];
  empId : any;

  /********* On Duty View ****************/
  onDutyData    : any
  loader_OnDuty : boolean = false
  onDutyID      : any

  viewOnDuty(req_type_name:any,req_id:any){

    // API
    this.loader_OnDuty = true
    this.inboxservice.getRequestDetails(req_type_name,req_id).subscribe((res:any)=>{
      this.onDutyData = res.data.requests
      this.onDutyID = req_id
      this.loader_OnDuty = false
    })
  }

  requestViewStatus(){
    this.viewstatusPanel  = true
    this.billLoader       = true;

     this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) =>{
      this.viewStatusData     = res;
      this.billLoader         = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
     });
  }
  checkall(event:any){
    var newList = this.requestitem?.requests
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    subArray.forEach((item:any, key:any) => {
      if(item.status != 'Cancelled' &&  item.status != 'Invalid')
      item.checked = event.target.checked
    })
  }

  checkAllSelected(){
    var newList = this.requestitem?.requests
    var begin = (this.page-1) * this.pageSize ;
    var end = this.page* this.pageSize;
    var subArray = newList?.slice(begin,end);
    if(subArray?.length){
      return subArray.every((item: { checked: any,status:any }) => {
        if(item.status != 'Cancelled' && item.status != 'Invalid')
          return item.checked;
        else
          return false
      });
    } else {
      return false;
    }
  }

  refreshInbox(event: boolean){
    if(event){
      this.successEmitter.emit(event);
    }
  }
  form = this.fb.group({
    read_status: [true,Validators.required],
    approved_status: [false,Validators.required],
    comment: ['', Validators.maxLength(500)],
  })

  get f() {return  this.form.controls; }

  yearListCreate(){
    var i:number;
    for(i = this.currentYear; i >= 2020 ; i--){
      this.yearList.push({k:String(i)})
    }
  }
  getOndutyTrendDetails(event:any){
    this.graphLoader = true
    this.selectedYearDisp = String(event)
    this.selectedYear = event
    this.empId = this.requestitem.from_person.id
    if(this.empId != null && this.empId != '' && this.empId != undefined){
    this.inboxservice.getOndutyTrend(event,this.empId).subscribe((res:any)=>{
        if(res){
          this.prepareTrendData(res.data)
        }
      })
    }
  }

  prepareTrendData(data:any){

    let approved:any         = [];
    let pending:any          = [];
    let rejected:any         = [];
    let cancelled:any         = [];
    this.xAxisLabel = [];

    this.multi           = [];
    data.forEach((item:any) => {
      this.xAxisLabel?.push(item?.name)
      item?.series.forEach((item1:any) => {
          if(item1?.name == "Approved"){
            approved.push(item1?.value)
          }else if(item1?.name == "Pending"){
            pending.push(item1?.value)
          }else if(item1?.name == "Cancelled"){
            cancelled.push(item1?.value)
          }else{
            rejected.push(item1?.value)
          }
      });
    });

    this.multi.push({label: "Accepted", data: approved, backgroundColor: '#009722', barThickness: 10 },{label: "Pending", data: pending, backgroundColor: '#FFC107', barThickness: 10 },{label: "Rejected", data: rejected, backgroundColor: '#DC3545', barThickness: 10 },{label: "Cancelled", data: cancelled, backgroundColor: '#7d92a1', barThickness: 10 } )

    this.graphLoader = false;

  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,
        // "read_status": read_status,
        // "star": star,
      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  confirmCancel(status:any){
    var status: any;

    if(status=='Approved'){
      this.form.controls['approved_status'].setValue('Approved');
    }else{
      this.form.controls['approved_status'].setValue('Rejected');
    }

    this.form.controls['approved_status'].setValue(status);
    this.inboxservice.updateInboxItem(this.requestitem?.id,this.form.value).subscribe((res)=>{
      if(res){
        this.successEmitter.emit(true);
      }
    })



  }

}
