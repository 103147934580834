import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SnapshotService } from 'src/app/@core/services/snapshot.service';
import { WebsocketService } from 'src/app/@core/services/websocket.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { environment } from 'src/environments/environment';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showSidePanel = false;
  showProfileCard = true;
  showWhatsNewPanel=false;
  theme1: any;
  theme: any;
  notifyList: any = [];
  dateAgo: any;
  isactiveData: any;
  empdetail: any = [];
  nodata = false;

  @ViewChild('popOver') public popover: NgbPopover | undefined;
  @Input() changeThemesidePanel: any;
  @Output() changeThemesidePanel1: any = new EventEmitter();
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.showSidePanel = false;
    this.showProfileCard = false;
    this.changeThemesidePanel = false;
    this.changeThemesidePanel1.emit(this.changeThemesidePanel);
    if (this.popover?.isOpen()) this.popover.close();
  }
  popupClass = "popup-primary"
  showProgress = false;
  errorLog = false;
  exportData: any = [];
  processText = ''
  completeText = ''
  btnClass = ""
  saveData: any = []
  timeLeft: any = "";
  progPercentage: any = 0
  kbUrl = ""
  wnUrl = ""
  doLogout() {
    localStorage.removeItem('last_visited_url' + this.authService.isUserId);
    this.changeThemesidePanel1.emit(false);
    this.authService.doLogout();
    this.webSocketService.closeSocket();
  }

  hideCard(event: any, id: number) {
    if (event.target.parentNode.parentNode.parentElement.classList.contains('show')) {
      event.target.parentNode.parentNode.parentNode.classList.remove('show');
      this.isactiveData = { 'is_active': false };
      this.notificationService.UpdateNotification(id, this.isactiveData).subscribe((res: any) => {
      });
    }
  }
  constructor(
    public notificationService: NotificationService,
    public webSocketService: WebsocketService,
    private authService: AuthService,
    public SnapshotService: SnapshotService,
    public appService: AppService
  ) {
    this.webSocketService.notify.subscribe(result => {
      this.listNotifications();
      this.checkNotificationCount();
    });

  }
  limit = 10
  offset = 0
  notificationList: any = []
  listlength = 0
  bluetick = 0
  loader = true
  readIds: any = [];
  employeeId: any;
  ngOnInit(): void {
    this.employeeId = this.authService.isEmployeeId;
    this.listNotifications();
    this.checkNotificationCount();
    this.listWhatsNew()
    var dts = ""
    if (!window.location.origin.includes("localhost"))
      dts = window.location.origin
    else
      dts = "remya.dev-skizzlehr.in"
    this.kbUrl = "https://knowledgebase.skizzlehr.tech/?tk=" + this.authService.getToken() + "&&emp=" + this.employeeId + "&&dts=" + dts + "&&api=" + environment.apiPath
    dts = dts.replace(/^https?:\/\//, '')
    this.wnUrl = "https://announcements.skizzlehr.tech/?tk=" + this.authService.getToken() + "&&emp=" + this.employeeId + "&&dts=" + dts + "&&api=" + environment.apiPath
  }
  ngOnDestroy() {
    this.webSocketService.closeSocket();
  }
  showtheme() {
    this.changeThemesidePanel = true;
    this.changeThemesidePanel1.emit(this.changeThemesidePanel);
  }
  addItems(itemlist: any) {
    this.loader = false
    for (let i = 0; i < itemlist; ++i) {
      let message = this.notificationList[i].message;
      // Code for date format
      message = message.replace(/\d{2}([-])\d{2}\1\d{4}/g, (dateString:any) => {
          let ndateString = dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");
          return this.appService.dateFormatDisplay(ndateString);
      });
      // code for time format
      message = message.replace(/\b\d{2}:\d{2}:\d{2}\b/g, (timeString:any) => {
          return this.appService.timeFormatDisplay(timeString);
      });
      // For loan Currency
      message = message.replace(/(loan (request|process).*?)(\s*for\s*)(\d+(\.\d{1,2})?)/gi, (match: string, prefix: string, action: string, forText: string, amountString: string) => {
        const amount = parseFloat(amountString);
        let empData = this.appService.getEmpData();
        const formattedAmount = this.appService.formatCurrency(empData?.currency, amount);
        return `${prefix}${forText}${formattedAmount}`;
      });
      this.notificationList[i].message = message;
      this.notifyList.push(this.notificationList[i]);
      this.readIds.push(this.notificationList[i].id);
    }
    if (this.notifyList.length == 0)
      this.nodata = true
    else
      this.nodata = false
  }
  readDisplayed() {
    this.notificationService.markReadNotification({ 'notif_ids': this.readIds }).subscribe((res: any) => {
      this.checkNotificationCount();
      this.offset = 0;
      this.notifyList = [];
      this.listNotifications();
    });

  }
  checkNotificationCount() {
    this.notificationService.getNotificationCount().subscribe((res: any) => {
      this.bluetick = res.unread_count;
    });

  }

  listNotifications() {
    this.notificationService.GetNotificationList(this.limit, this.offset).subscribe((res: any) => {
      this.notificationList = [];
      if (this.offset == 0) {
        this.notifyList = [];
      }
      for (let key of res.results) {
        this.notificationList.push(key);
      }

      this.listlength = res.results.length;
      this.addItems(this.listlength);
    });

  }
  /********* INIFNITY SCROLL FUNCTION *******************/
  onScrollDownNot() {
    this.loader = true
    this.offset += this.limit;
    if (this.listlength) {
      this.listNotifications();
    }
    this.loader = false
  }
  /********* INIFNITY SCROLL DATA CREATION ****************/

  markreadall() {
    this.notificationService.MarkReadAllNotificationList().subscribe((res: any) => {
      this.offset = 0;
      this.bluetick = 0;
      if (res.status == 1) {
        this.notificationService.GetNotificationList(this.limit, this.offset).subscribe((res: any) => {
          this.notifyList = res.results;
        });
      }
    })
  }
  clearall() {
    this.notificationService.clearAllNotification().subscribe((res: any) => {
      this.notifyList = res.results;
      this.nodata = true;
    });

  }
  openWhatsNew(){
    this.showWhatsNewPanel = true
  }
  whatsnew:any = []
  listWhatsNew(){
    if(this.SnapshotService.EMPCODE == undefined){
      setTimeout(() => {
        this.listWhatsNew();
      }, 1000);
    } else {
      var dts = ""
      if (!window.location.origin.includes("localhost")){
        dts = window.location.origin
        dts = dts.replace(/^https?:\/\//, '')
      } else
        dts = "remya.dev-skizzlehr.in"
    this.notificationService.getWhatsNew(this.SnapshotService.EMPCODE+dts).subscribe((res: any) => {
      this.whatsnew = res
    });
    }
  }
  cropText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
      return text;
    }

    let croppedText = text.substring(0, maxLength);
    const lastSpaceIndex = croppedText.lastIndexOf(' ');

    if (lastSpaceIndex > -1) {
      croppedText = croppedText.substring(0, lastSpaceIndex);
    }

    return `${croppedText}<span class="link-primary1">... Learn more</span>`;
  }

}
