<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='leavePolicy === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="closeFunction()"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewloader"></app-view-loader>
        <ng-container *ngIf="!viewloader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Leave POLICY </p>
            <a *ngIf="from == 'leaveRule' && editPermission && viewListData.is_active==true"
              (click)="editPolicy(viewListData.id)" class="link-primary fs-14 d-flex align-items-center ms-auto">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-16 mb-24">

            <!-- LATEST DESIGN -->
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Leave policy details</span></div>
                  <div class="col-6">
                    <div class="detail-title">Policy name</div>
                    <div class="detail-desc">{{viewListData.name}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Leave cycle</div>
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i
                        class="bi-record-circle-fill me-8 text-light-500"></i>{{viewListData.leave_cycle==false?'Calendar':'Financial'}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Description</div>
                    <div class="detail-desc">{{viewListData.description == null || viewListData.description == ''
                      ?'-':viewListData.description}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Policy valid from</div>
                    <div class="detail-desc">{{this.appService.dateFormatDisplay(viewListData.policy_valid_from)}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Map leave type</div>
                    <div class="align-items-center d-flex flex-wrap fs-14 gap-16 mt-8">
                      <span class="hstack text-break align-items-baseline" *ngFor="let map of leaveMapping">
                        <i class="icon-check-square-fill text-light-400 me-8"></i>{{map?.leave_type_name}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 ">
                <div class="card-body px-24 pb-18">
                  <span class="title">Configure leave type</span>
                </div>
                <div class="card-body px-0 py-0">
                  <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-custom shadow-0 p-0 ">
                    <ng-container *ngFor="let rule of leaveMapping; let i=index;">
                      <li [ngbNavItem]="i" class="success">
                        <a ngbNavLink class="nav-link">{{rule?.leave_type_name}} </a>
                        <ng-template ngbNavContent>
                          {{rule?.leave_type_name}}
                        </ng-template>
                      </li>
                    </ng-container>
                  </nav>
                </div>
                <ng-container *ngFor="let rule of leaveMapping; let i=index;">
                  <ng-container *ngIf="activeTab==i">
                    <div class="card-body px-24">
                      <div class="vstack gap-24">
                        <div class="card details-card shadow-0 p-24">
                          <div class="row row-24 ">
                            <div class="col-12 "><span class="title">LEAVE TYPE DETAILS</span></div>
                            <div class="col-4">
                              <div class="detail-title">Leave Type Code</div>
                              <div class="detail-desc">{{rule?.leave_type_code}}
                              </div>
                            </div>
                            <div class="col-8">
                              <div class="detail-title">Leave Type Name</div>
                              <div class="detail-desc">{{rule?.leave_type_name}}
                              </div>
                            </div>
                            <!-- <div class="col-4">
                              <div class="detail-title">This Leave is</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_unpaid_paid==false?'Unpaid Leave':'Paid Leave'}}
                              </div>
                            </div> -->
                            <div class="col-4">
                              <div class="detail-title">Is Negative balance is allowed?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.negative_balance_allowed==true?'Yes, -'+rule?.leavemapping_configuration[0]?.negative_balance_days:'No'}}
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="detail-title">Is Half day leave allowed?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.halfday_leave_allow==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="detail-title">Is Leave cancellation allowed?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_cancellation_allow==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="detail-title">Is this leave encashable?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_encashable==true?'Yes':'No'}}
                              </div>
                            </div>
                            <ng-container *ngIf="rule?.leavemapping_configuration[0]?.leave_encashable==true">

                              <div class="col-4">
                                <div class="detail-title">Leave encashment limit</div>
                                <div class="detail-desc">
                                  <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_encash_limit_status==true?'Set limit, -'+rule?.leavemapping_configuration[0]?.max_encash_limit:'All'}}
                                </div>
                              </div>

                              <div class="col-12">
                                <div class="detail-title">Is Leave encashment allowed during leave year end process?</div>
                                <div class="detail-desc">
                                  <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_encash_allow_leaveendyear==true?'Yes':'No'}}
                                </div>
                              </div>
                              <div class="col-12" *ngIf="rule?.leavemapping_configuration[0]?.leave_encash_allow_leaveendyear==true">
                                <div class="detail-title">How leave encashment works during the year end process?</div>
                                <div class="detail-desc">
                                  <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.how_encash_work_leaveendyear==true?'Carry Forward First and Encash Remaining':'Encash First and Carry Forward Remaining'}}
                                </div>
                              </div>
                            </ng-container>

                            <div class="col-12">
                              <div class="detail-title">Do you want to consider the week off as leave if it is adjoining a leave?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.do_you_want_weekoffleave==true?'Yes, '+rule?.leavemapping_configuration[0]?.weekoffleave_choice:'No'}}
                              </div>
                            </div>
                            <div class="col-12 ms-16" *ngIf="rule?.leavemapping_configuration[0]?.do_you_want_weekoffleave==true">
                              <div class="detail-title">Is above setting applicable to week offs adjoining a half day leave?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.adjoin_halfday_weekoff==true?'Yes' : 'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Do you want to consider the holiday as leave if it is adjoining a leave?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.do_you_want_holidayleave==true?'Yes, '+rule?.leavemapping_configuration[0]?.holidayleave_choice:'No'}}
                              </div>
                            </div>
                            <div class="col-12 ms-16" *ngIf="rule?.leavemapping_configuration[0]?.do_you_want_holidayleave==true">
                              <div class="detail-title">Is above setting applicable if holiday is adjoining a half day leave?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.adjoin_halfday_leave==true?'Yes' : 'No'}}
                              </div>
                            </div>
                            <!-- <div class="col-12">
                              <div class="detail-title">Do you want to include weekend’s as leave if taken before the
                                weekend?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.sandwich_holiday==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Do you want to include holidays as leave if taken before the
                                holiday?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_before_weekend==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Do you want to include weekend’s as leave if taken after the
                                weekend?</div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_after_weekend==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Do you want to include holidays as leave if taken after the holiday?
                              </div>
                              <div class="detail-desc">
                                <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_after_holiday==true?'Yes':'No'}}
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="card details-card shadow-0 p-24">
                          <div class="row row-24 ">
                            <div class="col-12 "><span class="title">LEAVE CREDIT DETAILS</span></div>
                            <div class="col-12">
                              <div class="detail-title">How do you want to grant this leave?</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.grant_leave==true?'Manual':'Auto'}}
                              </div>
                            </div>
                            <ng-container *ngIf="rule?.leavemapping_configuration[0]?.grant_leave==false;else manualdata">
                              <ng-container *ngFor="let credit of rule?.leavemapping_configuration[0]?.leavemapping_creditrule;let index=index">
                                <div class="col-12">
                                  <div class="card details-card shadow-0 p-24">
                                    <div class="row row-24 ">
                                      <div class="col-12 "><span class="title">CREDIT RULE {{index+1}}</span></div>
                                      <div class="col-4">
                                        <div class="detail-title">Effective from</div>
                                        <div class="detail-desc">{{credit?.effective_from}} {{credit?.effective_type}}
                                        </div>
                                      </div>
                                      <div class="col-8">
                                        <div class="detail-title">From DOJ/DOC</div>
                                        <div class="detail-desc">
                                          {{credit?.from_doj_doc==true?'DOC':'DOJ'}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">When do you want this leave to be credited?</div>
                                        <div class="detail-desc">{{credit?.do_want_to_credit_leave}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">Date of accrual</div>
                                        <div class="detail-desc">{{this.appService.ordinal_suffix_of(credit?.which_dayof_month)}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">Leave credit count</div>
                                        <div class="detail-desc">{{credit?.no_of_leaves_credited}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">How do you want to credit leave if employee joins middle of
                                          the accrual cycle?</div>
                                        <div class="detail-desc">{{credit?.leave_credit_timeofjoining}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">Do you want to apply First Month Rule?</div>
                                        <div class="detail-desc"><i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit.first_month_rule==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <ng-container *ngIf="credit?.first_month_rule==true">
                                        <div class="col-12">
                                          <div class="fs-12 fw-600 text-uppercase">FIRST MONTH RULE</div>
                                        </div>
                                        <ng-container *ngFor="let first of credit?.leavecreditrule_firstmonth">
                                          <div class="col-4">
                                            <div class="detail-title">From date</div>
                                            <div class="detail-desc"> {{this.appService.ordinal_suffix_of(first?.from_date)}}
                                            </div>
                                          </div>
                                          <div class="col-4">
                                            <div class="detail-title">To date</div>
                                            <div class="detail-desc"> {{this.appService.ordinal_suffix_of(first?.to_date)}}
                                            </div>
                                          </div>
                                          <div class="col-4">
                                            <div class="detail-title">Count </div>
                                            <div class="detail-desc"> {{first?.count_number}}
                                            </div>
                                          </div>
                                        </ng-container>


                                      </ng-container>


                                      <div class="col-12">
                                        <div class="detail-title"> How do you want to credit leave if employee exits middle of
                                          the accrual cycle?</div>
                                        <div class="detail-desc"> {{credit?.leave_credit_timeofexiting}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">Do you want to apply Last Month Rule? </div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit.last_month_rule==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <ng-container *ngIf="credit.last_month_rule==true">
                                        <div class="col-12">
                                          <div class="fs-12 fw-600 text-uppercase">LAST MONTH RULE</div>
                                        </div>
                                        <ng-container *ngFor="let last of credit?.leavecreditrule_lastmonth">
                                          <div class="col-4">
                                            <div class="detail-title">From date</div>
                                            <div class="detail-desc"> {{this.appService.ordinal_suffix_of(last?.from_date)}}
                                            </div>
                                          </div>
                                          <div class="col-4">
                                            <div class="detail-title">To date</div>
                                            <div class="detail-desc"> {{this.appService.ordinal_suffix_of(last?.to_date)}}
                                            </div>
                                          </div>
                                          <div class="col-4">
                                            <div class="detail-title">Count </div>
                                            <div class="detail-desc"> {{last?.count_number}}
                                            </div>
                                          </div>
                                        </ng-container>
                                      </ng-container>


                                      <div class="col-12">
                                        <div class="detail-title">Do you want to grant leaves based on previous month
                                          attendance?</div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.grant_leave_prev_month==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <ng-container *ngIf="credit?.grant_leave_prev_month==true">
                                        <div class="col-12">
                                          <div class="detail-title">Required attendance is calculated from</div>
                                          <div class="detail-desc"> <i
                                              class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.req_attend_calculate==true?'Paid days':'Present days'}}
                                          </div>
                                        </div>
                                        <div class="col-12">
                                          <div class="detail-title">Credit leave if previous month's attendance is or above</div>
                                          <div class="detail-desc">{{credit?.prev_month_greater_than}}
                                          </div>
                                        </div>
                                        <div class="col-12">
                                          <div class="detail-title">If the above criteria not met, leaves will</div>
                                          <div class="detail-desc"> <i
                                              class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.criteria_not_met==true?'Prorate':'Lapse'}}
                                          </div>
                                        </div>
                                      </ng-container>
                                      <div class="col-12">
                                        <div class="detail-title">Do you want to Roundoff leaves while granting?</div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.roundoff_leave_granting==true?'Yes, '+credit?.roundoff_option:'No'}}
                                        </div>
                                      </div>

                                      <!-- New Field -->
                                      <div class="col-12">
                                        <div class="detail-title">Does this credited leave get lapsed after a specific period?</div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.credit_lapse_enable==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <ng-container *ngIf="credit?.credit_lapse_enable==true">
                                        <div class="col-12">
                                          <div class="detail-title">After what period of time, this leave will get lapsed?</div>
                                          <div class="detail-desc"> <i
                                              class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.credit_lapse_cond==false?'Month':'Days'}}, {{credit?.credit_lapse}}
                                          </div>
                                        </div>

                                      </ng-container>
                                      <!-- End -->

                                      <div class="col-12">
                                        <div class="detail-title">Do you want to provide leave opening balance?</div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.leave_opening_balance_enable==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <div class="col-12" *ngIf="credit?.leave_opening_balance_enable==true">
                                        <div class="detail-title">Opening balance count</div>
                                        <div class="detail-desc">{{credit?.leave_opening_balance}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">Do you want to carry over previous policy balance when credit
                                          rule change?</div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.carry_over_prev_creditrule==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <div class="col-12" *ngIf="credit?.carry_over_prev_creditrule==true">
                                        <div class="detail-title">Maximum carry over limit</div>
                                        <div class="detail-desc">
                                          <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.max_carry_over_status==true?'Set limit, -'+credit?.carry_over_limit:'All'}}
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div class="detail-title">Do you want to carry forward the leave balance to next year?
                                        </div>
                                        <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.carry_forward_next_year==true?'Yes':'No'}}
                                        </div>
                                      </div>
                                      <!-- <div class="col-12" *ngIf="credit?.carry_forward_next_year==true">
                                        <div class="detail-title">Carry forward limit</div>
                                        <div class="detail-desc"> {{credit?.carry_forward_limit}}
                                        </div>


                                      </div> -->


                                      <div class="col-12" *ngIf="credit?.carry_forward_next_year==true">
                                        <div class="detail-title">Maximum carry forward limit</div>

                                        <div class="details-desc">
                                        <i class="bi-record-circle-fill me-8 text-light-500"></i>{{credit?.max_carry_forward_status==true?'Set limit, -'+credit?.carry_forward_limit:'All'}}
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-template #manualdata>
                              <div class="col-12">
                                <div class="detail-title">Do you want to carry forward the leave balance to next year?
                                </div>
                                <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.carry_forward_feature_year==true?'Yes':'No'}}
                                </div>
                              </div>
                              <div class="col-12" *ngIf="rule?.leavemapping_configuration[0]?.carry_forward_feature_year==true">
                                <div class="detail-title">Maximum carry forward limit</div>
                                <div class="detail-desc">
                                  <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_carry_forward_count_status==true?'Set limit, -'+rule?.leavemapping_configuration[0]?.carry_forward_count:'All'}}

                                </div>


                              </div>
                            </ng-template>

                          </div>
                        </div>
                        <div class="card details-card shadow-0 p-24">
                          <div class="row row-24 ">
                            <div class="col-12 "><span class="title">ELIGIBILITY</span></div>
                            <div class="col-12">
                              <div class="detail-title">Is this leave applicable to only specific employment type?</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_applicable_emp_type==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12" *ngIf="rule?.leavemapping_configuration[0]?.leave_applicable_emp_type==true">
                              <div class="detail-title">Employement Type</div>
                              <div class="detail-desc">{{rule?.leavemapping_configuration[0]?.employment_type}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Is this leave applicable to only specific gender?</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_applicable_gender==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12" *ngIf="rule?.leavemapping_configuration[0]?.leave_applicable_gender==true">
                              <div class="detail-title">Gender</div>
                              <div class="detail-desc">{{rule?.leavemapping_configuration[0]?.gender}}
                              </div>
                            </div>
                            <div class="col-12" *ngIf="rule.leave_type_name != 'Compensatory off'">
                              <div class="detail-title">Is there any waiting period for the employee to be eligible for this leave type?</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.leave_waiting_status==true?'Yes':'No'}}
                              </div>
                            </div>
                           <ng-container  *ngIf="rule?.leavemapping_configuration[0]?.leave_waiting_status==true && rule?.leave_type_name != 'Compensatory off'">
                            <div class="col-12">
                              <div class="detail-title">Waiting Period starts from</div>
                              <div class="detail-desc">{{rule?.leavemapping_configuration[0]?.waiting_period_start?'Date of  Confirmation':'Date of  Joining'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Waiting Period effective from</div>
                              <div class="detail-desc">{{rule?.leavemapping_configuration[0]?.waiting_period_effective?rule?.leavemapping_configuration[0]?.waiting_period_effective:'--'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">No. of days</div>
                              <div class="detail-desc">{{rule?.leavemapping_configuration[0]?.waiting_period_days?rule?.leavemapping_configuration[0]?.waiting_period_days:'0'}}
                              </div>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                        <div class="card details-card shadow-0 p-24">
                          <div class="row row-24 ">
                            <div class="col-12 "><span class="title"> LEAVE REQUEST CRITERIA</span></div>
                            <div class="col-12">
                              <div class="detail-title">Prior days required to request this leave? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.prior_days_required_req_leave==true?'Yes,-'+rule?.leavemapping_configuration[0]?.prior_days:'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Is Back dated leave request allowed? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.backdated_leave_allow==true?'Yes,-'+rule?.leavemapping_configuration[0]?.backdated_days:'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Can employee raise leave request during probation? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.leave_during_probation==true?'Yes':'No'}}
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="detail-title">Can employee raise leave request during notice period? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.leave_during_noticeperiod==true?'Yes':'No'}}
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="detail-title">Is document required for this leave? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.doc_required_leave==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12" *ngIf="rule?.leavemapping_configuration[0]?.doc_required_leave==true">
                              <div class="detail-title"> Document required if leave exceeds</div>
                              <div class="detail-desc"> {{rule?.leavemapping_configuration[0]?.leave_exceed}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Is leave clubbing restricted with other leave types? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.leave_clubbing_not_allowed_status==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12" *ngIf="rule?.leavemapping_configuration[0]?.leave_clubbing_not_allowed_status==true">
                              <div class="detail-title">Leave types </div>
                              <div class="detail-desc"> {{leaveClubbing(rule?.leavemapping_configuration[0]?.leave_clubbing_leave_type )}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Can this leave be availed only on birthday? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.leave_taken_birthday==true?'Yes':'No'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Can this leave be availed only on wedding anniversary? </div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i> {{rule?.leavemapping_configuration[0]?.leave_taken_wedding_anniversary==true?'Yes':'No'}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card details-card shadow-0 p-24">
                          <div class="row row-24 ">
                            <div class="col-12 "><span class="title">LEAVE REQUEST LIMITS</span></div>
                            <div class="col-12">
                              <div class="detail-title">Maximum no. of requests employee can apply in a tenure</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_req_apply_tenure_status==true?'Set Limit, '+rule?.leavemapping_configuration[0]?.max_req_apply_tenure:'No Limit'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Maximum no. of requests employee can apply in a year</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_req_apply_year_status==true?'Set Limit, '+rule?.leavemapping_configuration[0]?.max_req_apply_year:'No Limit'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Maximum no. of requests employee can apply in a month</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_req_apply_month_status==true?'Set Limit, '+rule?.leavemapping_configuration[0]?.max_req_apply_month:'No Limit'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Maximum no. of leaves an employee can apply in a month</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_leave_apply_month_status==true?'Set Limit, '+rule?.leavemapping_configuration[0]?.max_leave_apply_month:'No Limit'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Minimum no. of leaves required to request</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.min_leave_per_req_status==true?'Set Limit, '+rule?.leavemapping_configuration[0]?.min_leave_per_req:'No Limit'}}
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="detail-title">Maximum no. of leaves required to request</div>
                              <div class="detail-desc"> <i class="bi-record-circle-fill me-8 text-light-500"></i>{{rule?.leavemapping_configuration[0]?.max_leave_per_req_status==true?'Set Limit, '+rule?.leavemapping_configuration[0]?.max_leave_per_req:'No Limit'}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </ng-container>
                </ng-container>

              </div>
            </div>
            <div class="col-12" *ngIf="viewListData?.leave_policy_leaveendprocess?.length>0">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">LEAVE YEAR END SETTINGS</span></div>
                  <div class="col-12">
                    <div class="detail-title">Leave year end process should be?</div>
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i
                        class="bi-record-circle-fill me-8 text-light-500"></i>{{viewListData.leave_policy_leaveendprocess[0]?.manual_auto==true?'Auto':'Manual'}}
                    </div>
                  </div>
                  <ng-container *ngIf="viewListData.leave_policy_leaveendprocess[0]?.manual_auto==true">
                  <div class="col-12">
                    <div class="detail-title">Leave year end process to be carried out on</div>
                  </div>
                  <div class="col-6 mt-8">
                    <div class="detail-title">Month</div>
                    <div class="detail-desc">
                      {{viewListData.leave_policy_leaveendprocess[0]?.process_to_carriedon_month}}</div>
                  </div>
                  <div class="col-6 mt-8">
                    <div class="detail-title">Day</div>
                    <div class="detail-desc">{{this.appService.ordinal_suffix_of(viewListData.leave_policy_leaveendprocess[0]?.process_to_carron_date)}}
                    </div>
                  </div>
                </ng-container>
                  <div class="col-12">
                    <div class="detail-title">What happens to negative leave balances during the year end?</div>
                    <div class="detail-desc">
                      {{viewListData.leave_policy_leaveendprocess[0]?.negative_balance_leaveyearend}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">APPLICABILITY</span></div>
                  <div class="col-12">
                    <div class="detail-title">Applicable To</div>
                    <div class="detail-desc">{{viewApplicable}}</div>
                  </div>
                  <div class="col-12 ">
                    <div class="detail-title">{{viewApplicable}}(s) </div>
                    <!-- <div class="detail-desc">
                                    <div class="d-flex gap-8 flex-wrap mt-8 ">
                                        <ng-container *ngFor="let item of viewRightsArray">
                                            <span class="badge3">{{item}}</span>
                                        </ng-container>
                                    </div>
                                </div> -->
                    <div class="detail-desc">
                      <div class="selected-values mt-1">
                        <ng-container *ngIf="viewApplicable == 'Employee'">
                          <li class="ng-value" *ngFor="let rights of viewRightsArray, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights?.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span>{{ (rights.name.length>25)? (rights.name+" ("+rights.empcode+")" |
                                  slice:0:25)+'..':(rights.name+" ("+rights.empcode+")") }}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="viewApplicable == 'Company'">
                          <li class="ng-value" *ngFor="let rights of viewRightsArray, let i=index;">
                            <div class="ng-value-label" title="{{rights.name}}">
                              <span class="d-inline-flex align-items-center">
                                <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                  *ngIf="rights.img !=  '' && rights.img !=  null">
                                <span class="avatar sq-24 avatar-circle {{rights?.color_code}}"
                                  *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                                <span> {{rights.name}}</span>
                              </span>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="(viewApplicable != 'Employee') && (viewApplicable != 'Company')">
                          <div class="d-flex flex-wrap mt-8 gap-8">
                            <ng-container *ngFor="let rights of viewRightsArray, let i=index;">
                              <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                                slice:0:25)+'..':(rights) }}
                              </span>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
