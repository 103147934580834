<div class="container-fluid p-24 " *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Assign Geo Locations</h3>
      <p class="text-helper mb-md-0">Assign locations for employees to mark attendance using their mobile app.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='locsearch'  [isLoading]="sLoading"
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchlocation($event)"></app-searchbar>
        </div>

        <div ngbDropdown class="d-inline-block">
          <button class=" btn-square btn btn-secondary" (click)="filterclick()" placement="bottom-left"
            id="dropdownBasic1" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <app-common-employee-filters *ngIf='filter === true' [filterForm]="filterForm" [from]="from" [filter]="filter"
            (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)"></app-common-employee-filters>
        </div>

        <button class="btn flex-shrink-0 btn-outline-primary btn-add" (click)="viewSidePanel = true;"
          *ngIf="permissions.a">Import </button>
        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add"
          routerLink="/assign-geo-location/assign-geo-location-setup" *ngIf="permissions.a"><i
            class="icon-plus"></i>ASSIGN GEO LOCATION</button>
      </div>
    </div>
  </div>
  <div class="row row-16rem" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card4'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && loader==false;else all_data" class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg11.png'" [noDataText]="'NO LOCATION ASSIGNED YET'"
      [hasPermission]="permissions?.a" (addData)="addnew($event)"
      routerLink="/assign-geo-location/assign-geo-location-setup"> </app-nodata-view>
  </div>

  <ng-template #all_data>
    <div class="row row-16rem " infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let locationdata of geolocationlist let i = index">
          <div class="card card-c2 card-hover px-16" (click)="viewemp(locationdata?.id);">
            <div class="card-body pb-8 px-0">
              <div class="d-flex gap-16">
                <img *ngIf="locationdata?.profile_image !=  '' && locationdata?.profile_image != null" alt=""
                  class="avatar-circle avatar-border sq-45" src="{{locationdata?.profile_image}}" />
                <div *ngIf="locationdata?.profile_image ==  ''|| locationdata?.profile_image == null"
                  class="avatar-circle avatar-border fs-14 fw-500 {{locationdata?.color_code}}  sq-45">
                  {{this.appService.getFirstChar(locationdata?.employee_name,2)}}</div>
                <div class="vstack text-trim">
                  <p class="mb-0 fs-12 fw-500 lh-base text-trim">{{locationdata?.employee_name}}</p>
                  <p class="mb-0 fs-10 fw-500 lh-base text-trim text-light-500 hstack gap-4">
                    <span>{{locationdata?.employee_code}}</span>|
                    <span class="text-trim">{{locationdata?.designation}}</span>
                  </p>

                </div>
              </div>
            </div>
            <div class="card-body py-12 px-0  d-flex fs-12 align-items-center">
              <div class="text-light-400 me-8 text-muted fs-12 text-nowrap">Locations mapped</div>
              <div class="text-muted fs-12 text-nowrap ms-auto fw-500">{{locationdata?.locations_count}}</div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let locationdata of geolocationlist let i = index">
          <div class="card card-c2 card-hover h-100 px-24 {{locationdata?.color_code}} user-card"
            (click)="viewemp(locationdata?.id);">
            <div class="card-body py-16 px-0 text-center">
              <img class="avatar-circle sq-57 mt-6 mx-auto" src="{{locationdata?.profile_image}}"
                *ngIf="locationdata?.profile_image !=  '' && locationdata?.profile_image != null" />
              <div class="avatar-circle sq-57 mt-6 mx-auto fw-500 bg-white avatar-border"
                *ngIf="locationdata?.profile_image ==  ''|| locationdata?.profile_image == null">
                {{this.appService.getFirstChar(locationdata?.employee_name,2)}}</div>
              <h6 class="fw-500 mt-8 tips text-trim fs-14">{{locationdata?.employee_name}}</h6>
              <span class="tips-helper">{{locationdata?.employee_name}}</span>
              <div class="mb-0 text-trim text-uppercase fw-500 fs-10 text-light-400">
                {{locationdata?.designation}}</div>
              <div class="d-flex fs-10 text-light-400 mt-1 gap-1 justify-content-center fw-500">
                <span class="text-uppercase">EMP CODE : </span><span> {{locationdata?.employee_code}}
                </span>
              </div>
            </div>
            <div class="card-body px-0 pt-8 pb-16 d-flex fs-12">
              <div class="me-8 text-light-400">
                Locations mapped
              </div>
              <div class="ms-auto  fw-500 text-end">
                {{locationdata?.locations_count}}
              </div>
            </div>
          </div>
        </div> -->
      </ng-container>
    </div>
  </ng-template>

  <div class="side-panel side-pane-active" style="--sidepanel-width:56.4375rem;"
    [class.side-pane-active]='assignGeoLoc === true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Details</h5>
        <a class="toggle-panel" (click)="assignGeoLoc = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions.v">
        <div class="container-fluid p-32">
          <app-c1-loader *ngIf="loader1"></app-c1-loader>
          <ng-container *ngIf="!loader1">
            <div class="d-flex mb-16">
              <p class="text-uppercase fw-500 mb-0">EMPLOYEE DETAILS</p>

            </div>
            <div class="row row-24 mb-24">
              <div class="col-12">
                <div class="card details-card shadow-0 px-24 py-16">
                  <div class="row row-24 ">
                    <div class="col-12 align-items-center d-flex"><span class="title">EMPLOYEE</span>
                      <a class="link-danger ms-auto " (click)="deletemodel=true;deleteid=empId;deletekey='employee';"><i
                          class="icon-trash me-8 "></i> </a>
                    </div>
                    <div class="col-sm-4 col-12">
                      <div class="detail-title">Employee Name</div>
                      <div class="detail-desc">{{locationViewdata?.employee_name}}</div>
                    </div>
                    <div class="col-sm-4 col-12">
                      <div class="detail-title">Employee Code</div>
                      <div class="detail-desc">{{locationViewdata?.employee_code}}</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row row-24 mb-24">
              <div class="col-12">
                <div class="card details-card shadow-0 radius-8 px-24 py-16">
                  <div class="row row-24 ">
                    <div class="col-12 d-flex">
                      <span class="title">LOCATIONS</span>
                    </div>

                    <div class="col-12" *ngFor="let locationViewdata of locationViewdata?.location_data let i = index">
                      <div class="card details-card shadow-0 px-24 py-16" *ngIf="locationViewdata?.is_active">
                        <div class="row row-24 ">
                          <div class="col-12 align-items-center d-flex">
                            <span
                              class="title">{{locationViewdata?.location_name}}</span>
                            <div class="d-flex gap-8 ms-auto align-items-center">
                              <span class="badge-success badge fs-14 py-1 px-16" *ngIf="locationViewdata?.expiry_status==false">Active</span>
                              <span class="badge-danger badge fs-14 py-1 px-16"  *ngIf="locationViewdata?.expiry_status">Expired</span>
                              <span class="text-light-400">|</span>
                             <a class="link-danger lh-1"
                              (click)="deletemodel=true;deleteid=locationViewdata?.assigned_id;deletekey='location';"><i
                                class="icon-trash "></i> </a>
                            </div>
                          </div>
                          <div class="col-sm-4 col-12">
                            <div class="detail-title">Coordinates</div>
                            <div class="detail-desc">{{locationViewdata?.latitude
                              }}, {{locationViewdata?.longitude
                              }}</div>
                          </div>
                          <div class="col-sm-4 col-12">
                            <div class="detail-title">Radius</div>
                            <div class="detail-desc">{{locationViewdata?.radius
                              }} m</div>
                          </div>
                          <div class="col-sm-4 col-12">
                            <div class="detail-title">Is this location for specific period?</div>
                            <div class="detail-desc" *ngIf="locationViewdata?.for_specific_days_only">Yes,
                              {{locationViewdata?.duration_of_days}}</div>
                            <div class="detail-desc" *ngIf="!locationViewdata?.for_specific_days_only">No</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <div class="modal  modal-alert {{deletemodel == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16rem">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Delete Mapped {{deletekey}}?</h4>

        </div>
        <div class="modal-body">

          <p class="mb-0">{{this.messageService.Deletemsgdisplay('mapped '+deletekey)}}</p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-sm text-uppercase"
            (click)="deletemapped(deleteid,deletekey);">Yes</button>
          <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
            (click)="deletemodel = false">No</button>
        </div>
      </div>
    </div>
  </div>


  <div class="side-panel side-pane-active" style="--sidepanel-width:50rem;"
    [class.side-pane-active]='viewSidePanel == true'>
    <div class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Import Geo Location</h5>
        <button class="btn-reset text-light-600" (click)="viewSidePanel = false">
          <i class="icon-close-lg fs-16"></i></button>
      </header>
      <div class="side-panel-body" style="overflow-y: auto;">
        <div class="container-fluid p-32 form-container">
          <div class="row row-24">
            <div class="col-12">
              <div class="card card-c2 p-24">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title mb-8">Import Employees</span>
                  </div>
                  <ng-container *ngIf="fileName">
                    <div class="col-md-6">
                      <div class="card card-c2 bg-secondary ">
                        <div class="d-flex p-24 ">
                          <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                          <span class="text-trim fw-500">{{fileName}}</span>
                          <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                          <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()"><i
                              class="bi bi-trash ms-8 "></i></span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                      class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                      <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                        (change)="onFileChange($event)" id="upload" hidden>
                      Select File to Upload
                    </label>
                    <span class="invalid-feedback"
                      *ngIf="invalidFormat">{{this.messageService.validateFileFormat('xlsx/csv')}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card card-c2 p-24">
                <div class="row row-16">
                  <div class="col-12">
                    <span class="title">Instructions</span>
                  </div>

                  <div class="col-12 ">
                    <ol class="fs-14 ps-16 mb-0 li-24">
                      <li>Download the <a class="link-primary" href="assets/docs/sample-assign-location-import.xlsx"> <i
                            class="icon-download me-1"></i> Sample Excel template</a></li>
                      <li>Enter or update the Employee details as per the format provided in the template.</li>
                      <li>Save the file.</li>
                      <li>Click on the "Select file to upload" button and select the file.</li>
                      <li>The file chosen should be in (.xls/.csv)format only.</li>
                      <li>Click on "upload".</li>

                    </ol>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <footer class="submit-footer justify-content-between  gap-16">
          <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
            (click)="uploadBalance()">Upload</button>

        </footer>
      </div>

    </div>
  </div>

  <!-- Delete popup -->
  <div class="modal  modal-alert {{deleteToggle2 == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header gap-16">
          <i class="bi bi-exclamation-triangle text-danger"></i>
          <h4 class="modal-title">Error Occured</h4>
        </div>
        <div class="modal-body">
          <p class="mb-0">{{deleteMsg}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle2 = false;">Close</button>
        </div>
      </div>
    </div>
  </div>
  <app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
    [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
  <app-error-download [fileName]="'Assign location Import Error'" [successDataCount]="successDataCount"
    [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Import Employee'"
    (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg">
  </app-error-download>