<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row row-16">
    <div class="col-md-4 col-lg-6">
      <h3>Leave Balance</h3>
      <p class="text-helper mb-md-0">View, track and adjust employees leave balances.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="form-icon" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchListData' [searchplaceholder]='searchHolder'  [isLoading]="sLoading"
            (searchEmitter)="searchResults($event)" (keywordsearchEmitter)="searchEmp($event)"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle
            (click)="filterpanel=true">
            <i class="icon-filter-left fs-13"></i>
          </button>
        </div>
        <button class="btn flex-shrink-0 radius-2 btn-outline-primary fs-10 fw-700 text-uppercase px-4"
          (click)="viewSidePanel = true" *ngIf="permissions?.a">Import</button>
        <button class="btn flex-shrink-0 radius-2 btn-primary fs-10 fw-700 text-uppercase px-4" *ngIf="permissions?.a"
          [disabled]="loader" (click)="exportDatas()">Export</button>
      </div>
    </div>
      <app-common-employee-filters class="mt-0" [filterForm]="filterForm" [from]="from" [filter]="filterpanel"
      (filterClose)="closefn($event)" (filterOutput)="resultfilterfn($event)" (dropOutput)="searchDrop($event)" [isMemorized]="isMemorized" (resetCalled)="resetCalled($event)"></app-common-employee-filters>
    <div class="col-12" *ngIf="selectedList?.length!=0 && !loader && alertDiv">
      <div role="alert" class="alert alert-warning show mb-0" id="mapShow">
        <p class="mb-0 fs-14"> The leave policy has not been mapped for {{selectedList?.length}} employees. <a
            class="link-primary1" (click)="showSidePanel=true">Click here</a> to view the details</p>
        <div class="btn-close pointer" (click)="alertDiv = false"></div>
      </div>
    </div>
    <div class="col-12">
      <app-table-loader *ngIf="loader"></app-table-loader>
      <div class="row " *ngIf="noData && !loader;else all_data" style="height: calc(100vh - 14rem);">
        <app-nodata-view class="vstack" [imgHeight]="'16rem'" [noDataImg]="'bg2.png'"
          [noDataText]="'Leave Management Made Easy'"
          [noDataPara]="'Quickly view and adjust employee leave balances. Use the \'Import\' feature to keep everything up-to-date effortlessly.'"
          [hasPermission]="false">
        </app-nodata-view>
      </div>
      <!-- <div class="card card-c2 overflow-hidden" *ngIf="noData && !loader;else all_data" >
                  <div class="table-responsive scrollbar-10 table-minheight"
                    style="max-height: calc(100vh - (3.625rem + 4rem + 2.5rem + 1.5rem + 3.125rem));">
                    <table
                      class="table sticky-header table-sm form-cell-sm td-0 table-bordered  sticky-first-col">
                      <thead>
                        <tr>
                          <th class="fw-600 text-center text-trim w-15ch" scope="col"
                            *ngFor="let col of displayedColumns" ngbPopover="{{col}}"
                            popoverClass="popover-input popover-info" container="body" triggers="hover">{{col}}
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div class="text-center card card-c2 flex-center p-16 bg-dark-500 m-12">No data found</div>
                  </div>
                </div> -->
      <ng-template #all_data>
        <div class="card card-c2">
          <div class="table-responsive scrollbar-10 table-minheight" *ngIf="!loader && landScreenData.length!=0"
            style="max-height: calc(100vh - {{(selectedList?.length!=0 && !loader && alertDiv) ? '17.9rem':'13.5rem'}})">
            <table class="table sticky-header table-sm form-cell-sm td-0 table-bordered  sticky-first-col">
              <thead>
                <tr>
                  <th class="fw-600 text-center" scope="col" rowspan="2" style="min-width: 7rem;">Employee </th>
                  <th class="fw-600 text-center text-trim w-15ch" scope="col"
                    *ngFor="let head of landScreenData[0].leavetypedata" ngbPopover="{{head?.leave_name}}"
                    popoverClass="popover-input popover-info" container="body" triggers="hover">{{head?.leave_name}}
                  </th>
                </tr>
                <tr>
                  <td class="text-center z-0" scope="col" *ngFor="let head of landScreenData[0].leavetypedata">
                    <div class="card card-lb py-16 px-8 horizontal-body card-c2 border-0 text-light-500">
                      <div class="card-body flex-center fs-12 p-0">Taken</div>
                      <div class="card-body flex-center fs-12 p-0">Balance</div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody class="">
                <tr *ngFor="let item of landScreenData;let i = index">
                  <td>
                    <div class=" p-12 d-flex align-items-center pointer"
                      routerLink="/leave-balance/leave-balance-view/{{item?.employee_id}}">
                      <img *ngIf="(item?.profile_image!=null && item?.profile_image!='')" class="avatar-circle sq-32"
                        src="{{item?.profile_image}}" alt="">
                      <div *ngIf="(item?.profile_image ==  null || item?.profile_image == '')"
                        class="avatar-circle fs-12 sq-32 {{item?.color_code}}">
                        {{this.appService.getFirstChar(item?.name,2)}}</div>
                      <div class="overflow-hidden ms-16 d-flex flex-column">
                        <p class="fs-14 fw-600 w-15ch text-trim mb-0" ngbPopover="{{item?.name}}"
                          popoverClass="popover-input popover-info" container="body" triggers="hover">{{item?.name}}</p>
                        <p class="fs-10 mb-0 text-trim lh-base">{{item?.employee_code}}</p>
                      </div>
                    </div>
                  </td>
                  <td *ngFor="let data of landScreenData[i].leavetypedata">
                    <div class="card card-lb py-16 px-8 horizontal-body card-c2 border-0" *ngIf="data?.taken != 'NA'">
                      <div class="card-body flex-center p-0">{{data?.taken}}</div>
                      <div class="card-body flex-center p-0">{{data?.balance}}</div>
                    </div>
                    <div class="card card-lb py-16 px-8 horizontal-body card-c2 border-0" *ngIf="data?.taken == 'NA'">
                      <div class="card-body flex-center p-0 text-danger">NA</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-12" *ngIf="!loader && !noData && lastPage > landScreenData.length&& lastPage > 20">
      <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [collectionSize]="lastPage" [maxSize]="3"
        [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="limit"
        (pageChange)="getPageFromService($event)"></ngb-pagination>
    </div>
  </div>
</div>

<ng-container *ngIf="showSidePanel">
  <app-non-mapped-empp [showSidePanel]="showSidePanel" [selectedList]="selectedList" [selectedName]="selectedName"
    (closePanel)="closePanel($event)" [searchNeeded]="'true'"></app-non-mapped-empp>
</ng-container>

<div class="side-panel side-pane-active" style="--sidepanel-width:50rem;"
  [class.side-pane-active]='viewSidePanel == true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Import Leave Balance</h5>
      <button class="btn-reset text-light-600" (click)="closeSidePanel()">
        <i class="icon-close-lg fs-16"></i></button>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24">
          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="row row-24">
                <div class="col-12">
                  <span class="title">Import Leave Balance</span><br>
                </div>
                <div class="row" *ngIf="fileName">
                  <div class="col-md-6">
                    <div class="card card-c2  bg-secondary">
                      <div class="p-12 bg-secondary d-flex gap-8 align-item-center">
                        <i class="icon-file-earmark-text-fill fs-40 text-accent2"></i>
                        <div class="d-flex flex-column justify-content-center gap-8 fs-12 fw-500 overflow-hidden lh-1">
                          <span class="tips text-uppercase  fw-700 text-trim">{{fileName}}</span>
                          <span class="tips-helper ">{{fileName}}</span>

                        </div>
                        <i class="icon-trash  ms-auto flex-center link-danger" (click)="clearData()"></i>
                      </div>
                    </div>
                    <!-- <div class="card card-c2 bg-secondary  mb-24">
                      <div class="d-flex p-24 ">
                        <i class="bi bi-file-earmark-text-fill text-accent2 me-8"></i>
                        <span class="text-trim fw-500">{{fileName}}</span>
                        <span class="ms-auto text-accent1 text-nowrap">{{fileSize}}</span>
                        <span class="link-danger fw-500 flex-shrink-0" (click)="clearData()"><i
                            class="bi bi-trash ms-8 "></i></span>
                      </div>
                    </div> -->
                  </div>
                </div>
                <label for="upload" [class.disabled]="fileName != ''" style="max-width: 18.375rem;"
                  class="btn btn-outline-primary radius-2 px-5 {{invalidFormat?'is-invalid':''}}">
                  <input [disabled]="fileName != ''" type="file" name="" onclick="this.value = null"
                    (change)="onFileChange($event)" id="upload" hidden>
                  Select File to Upload
                </label>
                <span class="invalid-feedback"
                  *ngIf="invalidFormat">{{this.messageServ.validateFileFormat('xlsx/csv')}}</span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card card-c2 p-24">
              <div class="row row-16">
                <div class="col-12">
                  <span class="title">Instructions</span>
                </div>

                <div class="col-12 ">
                  <ol class="fs-14 ps-16 mb-0 li-24">
                    <li>Download the <a class="link-primary" href="assets/docs/sample-leave-balance-import.xlsx"> <i
                          class="icon-download me-1"></i> Sample Excel template</a></li>
                    <li>Enter or update the balance details as per the format provided in the template.</li>
                    <li>Save the file.</li>
                    <li>Click on the "Select file to upload" button and select the file.</li>
                    <li>The file chosen should be in (.xls/.csv)format only.</li>
                    <li>Click on "upload".</li>

                  </ol>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="side-panel-footer">
      <button class="btn btn-primary btn-sm ms-auto text-uppercase" [disabled]="fileName == '' || iscompleted"
        (click)="uploadBalance()">Upload</button>
    </footer>
  </div>
</div>


<!-- Delete popup -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Error Occured</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{deleteMsg}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="deleteToggle = false;">Close</button>
      </div>
    </div>
  </div>
</div>
<app-upload-progress-bar *ngIf=" isDataUpload !=0" [progress]="percentage| number:'1.0-0'" [iscompleted]="iscompleted"
  [isProgressStart]="isProgressStart" [heading]="'Uploading'"></app-upload-progress-bar>
<app-error-download [fileName]="'Leave Balance Import Error'" [successDataCount]="successDataCount"
  [downloadMsg]="downloadMsg" [failedData]="exportData" [modalHeading]="'Import Leave Balance'"
  (closePanel)="downloadMsg = false;clearData()" [successMsg]="successMsg" [errorMsg]="errorMsg"> </app-error-download>
