<app-view-loader *ngIf="loader"></app-view-loader>
<div class="row row-16" *ngIf="!loader">
  <div class="col-12">
      <div class="mb-0 fw-500">ON DUTY REQUEST DETAILS</div>
  </div>
  <div class="col-12"  *ngFor="let item of onDutyData">
      <div class="card details-card shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16">
              <div class="col-12 d-flex align-items-start">
                  <span class="title">{{this.appServ.dateFormatDisplay(item?.onduty_date)}}</span>
                 <div class="d-flex align-items-center gap-8 ms-auto">
                    <span class="ribbon ribbon-right ribbon-secondary" *ngIf="item?.status == 'Cancelled'">Cancelled</span>
                    <span class="ribbon ribbon-right ribbon-danger" *ngIf="item?.status == 'Invalid'">Invalid</span>
                    <span class="link-danger fs-14 fw-500 text-nowrap" *ngIf="item?.status != 'Cancelled' && item?.status != 'Rejected' && item?.status != 'Invalid' && !onDutyCancel" (click)="cancelRequest(item?.id,item?.statu,item?.cancellation_raised_status,item?.overtime_processed_status,item?.compoff_raised_status,item?.arrear_valid)">Cancel Request</span>
                    <span  class="text-light-400" *ngIf="item?.status != 'Cancelled' && item?.status != 'Rejected' && !onDutyCancel && item?.status != 'Invalid'">|</span>
                    <span class=" fs-14 ms-auto px-2 badge3 {{item?.status=='Pending' ? 'badge-warning' : item?.status == 'Rejected' ? 'badge-danger' : 'badge-success'}}" *ngIf="item?.status != 'Cancelled' && item?.status != 'Invalid'">
                      {{item?.status}}
                    </span>
                    <span *ngIf="from!='inbox'" class="text-light-400">|</span>
                    <span *ngIf="from!='inbox'" class="fs-14 fw-500 text-nowrap link-primary1" (click)="statusData(item?.id)">View status</span>
                 </div>
              </div>
              <div class="col-12">
                  <div class="card card-c2 p-16">
                      <div class="row row-24">
                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Check-in date</div>
                            <div class="detail-desc">{{this.appServ.dateFormatDisplay(item?.checkin_date)}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Check-in time</div>
                              <div class="detail-desc">{{this.appServ.timeFormatDisplay(item?.checkin_time)}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Check-out date</div>
                            <div class="detail-desc">{{this.appServ.dateFormatDisplay(item?.checkout_date)}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Check-out time</div>
                              <div class="detail-desc">{{this.appServ.timeFormatDisplay(item?.checkout_time)}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Total hours</div>
                            <div class="detail-desc">{{this.appServ.hhmmTimeFormat(item?.hours)}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                            <div class="detail-title">Shift</div>
                            <div class="detail-desc">{{item?.shift_name == null ?'-' : item?.shift_name+' ('+item?.shift_code+')'}}</div>
                          </div>
                          <div class="col-sm-6 col-12">
                              <div class="detail-title">Reason</div>
                              <div class="detail-desc">{{item?.reason}}</div>
                          </div>
                          <div class="col-sm-6 col-12" *ngIf="onDutyCancel">
                            <div class="detail-title">Reason for cancellation</div>
                            <div class="detail-desc">{{item?.remark}}</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- view status panel -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Activities</h5>
            <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
        </header>

        <app-view-status [loader]="statusLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData" [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>

    </form>
  </div>
</ng-container>
<!-- end view status panel -->


<!-- Cancel Request -->
<app-cancel-request-modal [requestCancel]="cancelReq" [requestName]="'on duty'" [requestId]="cancelId" [requestStatus]="status" (modal)="close($event)"></app-cancel-request-modal>

