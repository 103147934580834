import { Component, OnInit,HostListener  } from '@angular/core';
import { AuthService } from './auth.service';
import { FormBuilder,Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import { WebsocketService } from '../services/websocket.service';
import { NotificationService } from '../services/notification.service';
import { userpasswordValidator } from 'src/app/@shared/validators/userpassword.validators';
import { MessageService } from 'src/app/message.global';
import { OrganizationService } from '../services/organization.service';
import { AppService } from 'src/app/app.global';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public username: string = '';
  public password: string = '';
  submitted     = false;
  loginForm     = this.formBuilder.group({
                    username :[null,[Validators.required,userpasswordValidator()]],
                    password :[null,[Validators.required]],
  })
  defaultcls    = false;
  show: boolean = false;
  viewicon      = "icon-eye-slash";
  invalidUser   = false;
  errorUser:any = "";
  errorPass:any = "";
  invalidPass   = false;
  inactiveUser  = false;
  inactiveMsg   = false;
  currentlogodata:any=[];
  loader=false;
  scrHeight:any;
  scrWidth:any;
    NEEDPROFILEPIC:any = false
  constructor(
    private authService: AuthService,
    private formBuilder:FormBuilder,
    private router:Router,
    private websocketService:WebsocketService,
    private notificationService:NotificationService,
    public messageService : MessageService,
    public OrganizationService: OrganizationService,
    public appservice:AppService,
    public homeService:HomeService
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['home'])
    }
    this.getorgdata();
    if(document.getElementsByTagName('body')[0]){
      document.getElementsByTagName('body')[0].classList.add('no-notifications')
    }
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if(window.innerWidth < 850)
      this.router.navigate(['mobile-view']);
  }
  get f(){
    return this.loginForm.controls;
  }
  doLogin() {
    this.submitted = true;
    if(this.loginForm.invalid)
    return
    this.authService.signIn(this.loginForm.value).subscribe((res:any)=>{
      this.inactiveUser  = false;
      this.invalidPass   = false;
      this.invalidUser   = false;
      if(res.error){

        if(!(res.hasOwnProperty('inactiveuser')))
        this.notificationService.handleErrorNotification(res.error,"Error");
        else{
          this.notificationService.handleErrorNotification(this.messageService.wrongLoginData('inactive'),"Error");
        }
      } else {
        this.authService.NEEDPROFILEPIC = res.data.is_profile_image_need_to_update
        localStorage.setItem('leaveYearEndNotification','true');
        localStorage.setItem('access_token', res.token);
        localStorage.setItem('refresh_token', res.refresh);
        localStorage.setItem('userData', JSON.stringify(res.data));
        this.homeService.NEEDANNIVERSARY = true
        this.homeService.NEEDBDAYWIDGET = true

        if(res.data.first_login==true){
          this.router.navigate(['one-time-password/']);
        }
        else{
          this.authService.menuList().subscribe((res: any) => {
            this.authService.MENULIST = res.page_access;
            this.authService.MENUCREATED = true
            this.appservice.setPermissions(res.page_access)
            this.websocketService.NotificationSocket();
            this.authService.gotoLastVisited();
          });

        }
      }

    },
    (error: any) => {
      this.inactiveUser = false;

      if(error.error.username_error == 0 && error.error.reset == 0 && error.error.error == "Password you have entered is incorrect."){
        this.loginForm.controls['password'].setErrors({'invalidPass': true});
        this.errorPass     = this.messageService.wrongLoginData('wrong_password');
        this.invalidPass   = true;
        this.invalidUser   = false;

      } else if(error.error.username_error == 0 && error.error.reset == 0 && error.error.error == "Password has been regenerated, please contact your admin."){
        this.loginForm.controls['password'].setErrors({'invalidPass': true});
        this.errorPass     = this.messageService.wrongLoginData('password_regenerated');
        this.invalidPass   = true;
        this.invalidUser   = false;

      }
      else if(error.error.reset == 0 && error.error.error == "Sorry you are disabled by HR."){
        this.notificationService.handleErrorNotification(this.messageService.wrongLoginData('disabled'),"Error");
      }
      else  if(error.error.reset == 0){
        this.errorUser    = this.messageService.wrongLoginData('wrong_code_email');
        this.loginForm.controls['username'].setErrors({'invalidUser': true});
        this.invalidUser  = true;
        this.invalidPass  = false;

      }
      if(error.error.username_error == 1){
        this.errorUser    = this.messageService.wrongLoginData('wrong_code_email');
        this.loginForm.controls['username'].setErrors({'invalidUser': true});
        this.invalidUser  = true;
        this.invalidPass  = false;

      }
    })

  }
  getpassword() {
    this.show = !this.show;
    this.viewicon =this.show==true?'icon-eye':'icon-eye-slash';
  }

  getorgdata(){
    this.loader= true;
    this.OrganizationService.getloginpagedata()
    .subscribe((res: any) => {
     this.currentlogodata = res.success;
     localStorage.setItem('org_logo', res.success.logo);
     if(this.currentlogodata.background_image == '/assets/images/bg/bg-2.svg'){
      this.defaultcls=true;
      }
   this.loader= false;
    },
      (error:any)=>{
        this.loader= false;

      }
      )
  }
  onKeydownUser(){
    if(this.loginForm.controls['username'].hasError('invalidUser') && !this.loginForm.controls['username'].hasError('required') &&!this.loginForm.controls['username'].hasError('pattern'))
    this.loginForm.controls['username'].setErrors(null);
  }
  onKeydownPass(){
    if(this.loginForm.controls['password'].hasError('invalidPass') && !this.loginForm.controls['password'].hasError('required') &&!this.loginForm.controls['password'].hasError('pattern'))
    this.loginForm.controls['password'].setErrors(null);
  }
}
