<div class="row row-16">
    <div class="col-12" *ngIf="iscancel == false">
        <div class="card details-card shadow-0 px-24 pt-16 pb-24">
            <div class="row row-24">
                <div class="col-12">
                    <div class="d-flex">
                        <span class="title">REIMBURSEMENT DETAILS</span>
                        <span (click)="showTrend = true" class="text-accent2 pointer ms-auto fs-12 fw-500"><i class="bi bi-graph-up me-1"></i>Reimbursement Request Trend</span>
                    </div>
                </div>
                <div class="col-12">
                    <app-table-loader *ngIf="loader"></app-table-loader>
                    <ng-container *ngIf="!loader">
                        <div class="card card-c2 p-0">
                            <div class="table-responsive radius-4">
                                 <!-- <pre>Form Values: {{reimbursement | json}}</pre> -->
                                <table class="table td-12 sticky-header td-white-space td-fs-14
                                    tr-fs-14  sticky-first-col sticky-last-col table-sm form-table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                <input class="form-check-input checkbox-16 m-0" type="checkbox" [(ngModel)]="isMasterSel" (change)="checkUncheckAll()">
                                            </th>
                                            <th class="fw-600" scope="col">CATEGORY</th>
                                            <th class="fw-600" scope="col">BILL DATE</th>
                                            <th class="fw-600" scope="col">AMOUNT</th>
                                            <th class="fw-600" scope="col">Status</th>
                                            <th class="fw-600" class="text-center" scope="col">View</th>
                                        </tr>
                                    </thead>
                                    <tbody class="cell-16 height-full">
                                        <ng-container *ngIf="reimbursement.length>0">
                                        <ng-container *ngFor="let item of $any(reimbursement | slice: (page-1) * pageSize : page * pageSize),let i = index">
                                            <tr>
                                                <td class="text-center">
                                                    <input [disabled]="item.status == 'Cancelled'" class="form-check-input mt-1 checkbox-16" type="checkbox" (change)="onCheckChange($event,item)" [(ngModel)]="item.isSelected">
                                                </td>
                                                <td>{{item.reimburse_bill.category }}</td>
                                                <td>{{this.appService.dateFormatDisplay(item.reimburse_bill.bill_date)}}</td>
                                                <td>{{item.reimburse_bill.bill_amount | currency : currency}}</td>
                                                <td >
                                                    <span *ngIf="item.status == 'Pending'" class="badge badge-warning fs-14 px-16 py-1">Pending</span>
                                                    <span *ngIf="item.status == 'Approved'" class="badge badge-success fs-14 px-16 py-1">Accepted</span>
                                                    <span *ngIf="item.status == 'Rejected'" class="badge badge-danger fs-14 px-16 py-1">Rejected</span>
                                                    <span *ngIf="item.status == 'Cancelled'" class="badge badge-secondary fs-14 px-16 py-1">Cancelled</span>
                                                </td>
                                                <td class="text-center">
                                                    <i (click)="viewBillDetails(item.id)"
                                                        class="bi bi-eye link-primary  fs-20"></i>
                                                </td>
                                            </tr>
                                        </ng-container></ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </ng-container>


                </div>
                <ngb-pagination *ngIf="reimbursement?.length>pageSize && !loader" class="d-flex justify-content-end" [collectionSize]="reimbursement?.length" [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [pageSize]="pageSize"></ngb-pagination>
            </div>
        </div>
    </div>
    <app-view-reimbursement-details  *ngIf="iscancel == true" [from]="from" [isCancel]="iscancel" [billDetails]="reimbursement" [currency]="currency" [empId]="empId"></app-view-reimbursement-details>
    <ng-container  [formGroup]="form"  *ngIf="!archieved_status">
        <!-- <div class="col-12">
            <div class="card card-c2">
                <div class="card-body indicater p-24  d-flex align-items-center gap-16">
                    <div class="me-16">Requests selected <span class="fs-20 text-accent2 ms-12">{{counter}}</span></div>
                    <button type="button"
                        class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400" [disabled]="btndisable" (click)="approveReject('Reject')">Reject</button>
                    <button class=" btn btn-primary text-uppercase btn-sm fw-400" [disabled]="btndisable" (click)="approveReject('Accept')">Accept</button>
                </div>
            </div>
        </div> -->
        <ng-container *ngIf="from=='inbox' || (from=='fnf' && reqStatus == 'Pending')">
        <div class="col-12">
            <textarea name="" id="" rows="3" placeholder="{{this.messageService.descriptiontext('comment')}}" formControlName="comment"
                [ngClass]="{ 'is-invalid': f.comment.errors}"
                class="form-control textarea-comment"></textarea>
            <div *ngIf="f.comment.errors?.maxlength" class="invalid-feedback">{{this.messageService.fieldlengthvalidation('word','500')}}</div>
        </div>
        <div *ngIf="iscancel == false" class="col-12 d-flex gap-16 sticky-footer py-16">
            <div   class="d-flex gap-16">
                <p class="mb-0 d-flex align-items-center gap-6">
                    <span class="fw-600">{{counter}}</span>
                    <span class="fs-14">Requests selected</span>
                </p>
                <button class="btn btn-outline-danger btn-icon fs-14 radius-2"  [disabled]="btndisable || counter==0" (click)="approveReject('Reject')"> <i class="icon-close-circle"></i>
                    Reject</button>
                <button class="btn btn-outline-success btn-icon fs-14 radius-2"  [disabled]="btndisable|| counter==0" (click)="approveReject('Accept')"> <i class="icon-check-circle"></i>
                    Approve</button>
            </div>
            <button  class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400" [disabled]="checkifallCompleted()"  (click)="confirm()">Submit</button>

        </div>
        <div class="col-12 d-flex gap-16"  *ngIf="iscancel == true">
            <button  class="ms-auto btn btn-outline-danger text-uppercase btn-sm fw-400" (click)="confirmCancel('Rejected')">Decline</button>
            <button  class=" btn btn-primary text-uppercase btn-sm fw-400" (click)="confirmCancel('Approved')">Confirm</button>
        </div>
      </ng-container>
        <!-- <div class="col-12 d-flex gap-16 sticky-footer ">
            <button class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400"  (click)="confirm()">Submit</button>
        </div> -->
    </ng-container>
</div>


<div class="side-panel" style="--sidepanel-width:40.625rem;" [class.side-pane-active]='viewDetails === true'>
    <div class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>View</h5>
            <a class="toggle-panel" (click)="viewDetails = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <app-view-reimbursement-details [billDetails]="billDetails"  [from]="from" [currency]="currency"></app-view-reimbursement-details>
        </div>
    </div>
</div>

<div *ngIf="showTrend" class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='showTrend === true'>
    <form class="side-panel-container" autocomplete="off">
        <header class="side-panel-head">
            <h5>Reimbursement Analysis</h5>
            <a class="toggle-panel" (click)="showTrend = false"><i class="icon-close-lg fs-16"></i></a>
        </header>
        <div class="side-panel-body" style="overflow-y: auto;">
            <app-reimbursement-analysis [empId]="empId" [currency]="currency"></app-reimbursement-analysis>
        </div>
        <!-- <footer class="side-panel-footer gap-32">
    <button type="button" class="ms-auto btn btn-outline-primary text-uppercase btn-sm">Decline</button>
    <button type="submit" class=" btn btn-primary text-uppercase btn-sm">Confirm</button>
  </footer> -->
    </form>
</div>

<div class="modal  modal-alert {{approveModal == true ? 'show': ''}}  {{rejectModal == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header  gap-16">
          <h4 class="modal-title">Add your comment</h4>
        </div>
        <div class="modal-body py-0">
          <div class="d-flex align-items-center gap-5 mb-2">
            <span class="fs-20 fw-600 text-accent2">{{counter}}</span>
            <span class="fs-18">Requests Selected</span>
          </div>
          <textarea class="form-control textarea-comment" ng-model="approveComment" name="" id="" rows="3">Add comments (Optional)... </textarea>
        </div>
        <div class="modal-footer">
          <button *ngIf="approveModal == true" type="button" class="btn text-uppercase btn-outline-secondary btn-sm" (click)="approveReject('Accept');approveModal=false">SKIP COMMENT</button>
          <button *ngIf="approveModal == true" type="button" class="btn text-uppercase btn-primary btn-sm" (click)="approveReject('Accept');approveModal=false">Approve</button>
          <button *ngIf="rejectModal == true" type="button" class="btn text-uppercase btn-outline-secondary btn-sm"  (click)="approveReject('Reject');rejectModal=false">SKIP COMMENT</button>
          <button *ngIf="rejectModal == true" type="button" class="btn text-uppercase btn-danger btn-sm" (click)="approveReject('Reject');rejectModal=false">Reject</button>
        </div>
      </div>
    </div>
  </div>

