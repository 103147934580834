import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import * as moment from 'moment';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-short-leave-request',
  templateUrl: './short-leave-request.component.html',
  styleUrls: ['./short-leave-request.component.scss']
})
export class ShortLeaveRequestComponent implements OnInit {

  // Var Initialization
  submitted       : boolean = false
  holidayArray    : any     = [];
  weekoffArray    : any     = [];
  dateTxt         : any     = [];
  toDateTxt       : any     = [];
  weekoff         : any     = [];
  holiday         : any     = [];
  employee        : number  = 0;
  document_required : boolean = false;
  errorPass                 = '';
  activeIndex     : any
  activeControl   : any
  checkTimeOnly   : boolean = false

  // Durations Vars
  totalDuration        : any  = 0
  // File
  fileType        : string  = "";
  docURL          : any     = "";
  docName         : any     = "";
  fileSize        : any     = "";
  docLoader       : boolean = false;
  loader          : boolean = false;
  fileError       : boolean = false;
  fileErrorMsg    : any     = "";
  dateAPIData     : any
  portionAPIData  : any

  // File View
  modalToggle               = false;
  allowedExtension          = "";
  alertMsg        : any     = '';
  alertToggle1              = false;
  fileName                  = "";
  currentDocName  : any;
  viewDocName     : any;
  currentExtension: any;

  fromDateFilter = (date: Date | null): boolean => { return true; }
  toDateFilter = (date: Date | null): boolean => { return true; }

  minDate = new Date(new Date().getFullYear() - 5, 11, 31);

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const d = moment(cellDate).date();
    if (view === 'month') {
      const weekoff = this.weekoffArray.find((weekoff:any) => weekoff.date == d)
      return weekoff
      ? weekoff.portion === 'Full Day'
        ? 'status-week-off'
        : weekoff.portion === 'First Half'
        ? 'status-first-week-off'
        : 'status-second-week-off'
      : this.holidayArray.indexOf(d) !== -1
      ? 'status-holiday'
      : '';
    }
    return '';
  };

  // Input Datas
  @Input() formGp : any
  @Input() dateMsg : any

  // Output Emitters
  @Output() submitMethod = new EventEmitter();
  @Output() validationError = new EventEmitter();
  @Output() validationResult = new EventEmitter();
  @Output() validationPortionResult = new EventEmitter();

  constructor(
    public msgServ : MessageService,
    public appServ : AppService,
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private renderer: Renderer2,
    private investmentService : InvestmentDeclerationService,
    private authService : AuthService,
    private fb : FormBuilder,
    private notifyServ : NotificationService
  ) { }

  ngOnInit(): void {
    // Date Format Setup
    this.getDateFormat();
    // Get Emp Id
    this.employee = this.authService.isEmployeeId;
    // Initially Calling for Weekoff & Holiday
    this.weekOffHolidayList(this.dateTxt);
    // Total Duration
    if(this.short_leave_portion().length > 0){
      this.calcTotalDuration();
    }
    // API Data setup
    this.dateAPIData = this.dateMsg != undefined ? this.dateMsg : {}
    // To check the document validator
    if(this.formGp.get('document')?.hasValidator(Validators.required))
     this.document_required = true;
    else
     this.document_required = false;
  }

  get f(){
    return this.formGp.controls;
  }

  short_leave_portion() : FormArray{
    return this.formGp.get('short_leave_portion') as FormArray
  }

  createLeaveFormGroup(): FormGroup {
    return this.fb.group({
      'from_time': new FormControl(null,[Validators.required]),
      'to_time'  : new FormControl(null,[Validators.required]),
      "duration" : new FormControl(0,[Validators.required])
    })
  }

  addLeaves(){
    this.short_leave_portion().push(this.createLeaveFormGroup())
  }

  checkForDuplicates() {
    const formArray = this.short_leave_portion()
    if (this.hasDuplicates(formArray.value)) {
      formArray.controls.forEach((control) => {
        control.get('from_time')?.setErrors({ 'duplicateEntry': true });
        control.get('to_time')?.setErrors({ 'duplicateEntry': true });
      });
    } else {
      formArray.controls.forEach((control) => {
        control.get('from_time')?.setErrors(null);
        control.get('to_time')?.setErrors(null);
      });
    }
    formArray.updateValueAndValidity();
  }

  hasDuplicates(formArrayValue:any): boolean {
    const seenTimes = new Set();
    for (const entry of formArrayValue) {
      const key = `${entry.from_time}-${entry.to_time}`
      if (seenTimes.has(key)) {
        return true;
      }
      seenTimes.add(key);
    }
    return false;
  }

  // Fn Call on date Change
  dateChangeFunction(ev:any){
    this.checkTimeOnly = false
    this.removeValidation();
    this.payloadAPI();
  }

  // Individual Duration
  setDuration(index:any,control:any){
    this.activeIndex = index
    this.activeControl = control
    this.checkTimeOnly = true
    this.checkForDuplicates();
    this.checkWholeValidation()
  }

  payloadAPI(){
    let dataArray = {
      "employee": this.employee,
      "date": moment(this.f.date.value).format('YYYY-MM-DD'),
      "portion": !this.checkIfFormArrayHasValues() ? [] : this.alterAPI()
    }

    this.investmentService.shlValidationAPI(dataArray).subscribe((res:any)=>{
      if(!this.checkTimeOnly){
        this.dateAPIData = res?.data
        this.portionAPIData = res?.data
        if(this.dateAPIData?.shift_details == null){
          this.alertToggle1 = true
          this.alertMsg = 'Short leave request cannot be raised as shift for the selected date as not been assigned.'
          this.validationError.emit({'error':true, 'errorMsg':this.alertMsg})
          this.calcTotalDuration()
        }
      }else{
        this.portionAPIData = res?.data
      }
      this.formArrayCreation()
    },
    (err:any)=>{
      this.alertMsg = err.error.error
      // this.invalidPass = true
      this.alertToggle1 = true
      this.validationError.emit({'error':true, 'errorMsg':this.alertMsg})
      this.formArrayCreation()
      this.calcTotalDuration()
    })
  }

  // Create Formarry only on valid date
  formArrayCreation(){
    if(!this.alertToggle1 && this.formGp.value.short_leave_portion?.length == 0 && !this.checkTimeOnly){
      this.addLeaves()
    }
  }

  // Checking portion is there or not
  checkIfFormArrayHasValues(): boolean {
    return this.short_leave_portion().controls.every((control: AbstractControl) => {
      return (
        control.get('from_time')?.value !== null &&
        control.get('to_time')?.value !== null &&
        control.get('duration')?.value !== 0
      );
    });
  }

  // Alter to HH:mm:ss
  alterAPI(){
    let shortArr = this.short_leave_portion().getRawValue()
    // Below code is added because on conversion of date the original values
    // are getting changed and can be seen on the screen
    let shortArrCopy = JSON.parse(JSON.stringify(shortArr));
    shortArrCopy.forEach((item: any) => {
      item['from_time'] = moment(item['from_time']).format('HH:mm:ss');
      item['to_time'] = moment(item['to_time']).format('HH:mm:ss');
      item['duration'] = moment(item['duration'],'HH:mm').format('HH:mm:ss');
    });
    return shortArrCopy
  }

  // Portion based all validation
  checkWholeValidation(){
    const fromControl = this.short_leave_portion().at(this.activeIndex).get('from_time');
    const toControl = this.short_leave_portion().at(this.activeIndex).get('to_time');
    const { first_half_end, second_half_start, shift_end_time, shift_start_time } = this.dateAPIData?.shift_details
    const shiftStart = moment(shift_start_time, 'HH:mm:ss');
    const shiftEnd = moment(shift_end_time, 'HH:mm:ss');
    const isShiftOvernight = shiftEnd.isBefore(shiftStart);
    function isTimeInShiftWindow(time:any) {
      if (isShiftOvernight) {
        return time.isBetween(shiftStart, moment('23:59:59', 'HH:mm:ss'), null, '[]') ||
               time.isBetween(moment('00:00:00', 'HH:mm:ss'), shiftEnd, null, '[]');
      } else {
        return time.isBetween(shiftStart, shiftEnd, null, '[]');
      }
    }
    const from_valid = fromControl?.value != null ? isTimeInShiftWindow(moment(fromControl?.value, 'HH:mm:ss')) : true;
    const to_valid = toControl?.value != null ? isTimeInShiftWindow(moment(toControl?.value, 'HH:mm:ss')) : true;
    if(!from_valid || !to_valid){
      fromControl?.setErrors({
        'outWindow' : true
      })
      toControl?.setErrors({
        'outWindow' : true
      })
    }
    else if(moment(fromControl?.value,'HH:mm:ss').isSame(moment(toControl?.value,'HH:mm:ss'))){
      this.short_leave_portion().at(this.activeIndex).get(this.activeControl)?.setErrors({
        'sameTime' :  true
      })
    }
    fromControl?.valueChanges.subscribe((res:any)=>{
      this.short_leave_portion().at(this.activeIndex).patchValue({
        to_time : null,
        duration : 0
      })
    })
    if(fromControl?.value != null && toControl?.value != null && !this.short_leave_portion().invalid){
      // Individual Duration
      this.individualDuration(fromControl?.value,toControl?.value,this.activeIndex)
      // Shift Window
      this.hoursMinMaxValidation()
      if(!this.alertToggle1 && !this.short_leave_portion().invalid){
        this.payloadAPI()
      }
    }
  }

  // Min-Max Validation
  addBtn : boolean = false
  hoursMinMaxValidation(){
    this.addBtn =  false
    let remaining_total_hours = this.toHoursAndMinutes(this.dateAPIData?.remaining_total_hours)
    let max_hours = this.toHoursAndMinutes(this.dateAPIData?.max_short_leave_hours_in_request)
    let min_hours = this.toHoursAndMinutes(this.dateAPIData?.min_short_leave_hours_in_request)
    if(moment(this.totalDuration,'HH:mm').isAfter(moment(remaining_total_hours,'HH:mm'))){
      this.alertToggle1 = true
      this.addBtn = true
      this.alertMsg = `Total hours allowed for short leave in a ${this.dateAPIData?.short_leave_limit_type.toLowerCase()} has exceeded the limit`
      this.validationError.emit({'error':true, 'errorMsg':this.alertMsg})
    }else if(moment(this.totalDuration,'HH:mm').isBefore(moment(min_hours,'HH:mm'))){
      this.alertToggle1 = true
      this.alertMsg = 'Short leave request does not meet the minimum hour criteria as per the policy.'
      this.validationError.emit({'error':true, 'errorMsg':this.alertMsg})
    }else if(moment(this.totalDuration,'HH:mm').isAfter(moment(max_hours,'HH:mm'))){
      this.alertToggle1 = true
      this.alertMsg = 'Short leave request duration exceeds the maximum short leave hours as per the policy.'
      this.validationError.emit({'error':true, 'errorMsg':this.alertMsg})
    }else{
      this.alertToggle1 = false
      this.alertMsg = ''
      this.validationError.emit({'error':false, 'errorMsg':this.alertMsg})
    }
  }

  // Millisec to Hours
  toHoursAndMinutes(sec:any) {
    const totalMinutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    const hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    const formattedHours: string = (hours.toString().length <2) ? "0" + hours.toString() : hours.toString();
    const formattedMinutes: string = (minutes.toString().length <2) ? "0" + minutes.toString() : minutes.toString();
    return formattedHours+':'+formattedMinutes
  }

  // Calc Duration
  calcTotalDuration(){
    this.totalDuration = this.short_leave_portion().at(0)?.get('duration')?.value
  }

  // Individual Duration
  individualDuration(from_time_form:any,to_time_form:any,index:any){
    const duration = moment.duration(moment(to_time_form).diff(moment(from_time_form)));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const formattedDuration = `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}`;
    this.short_leave_portion().controls[index].patchValue({
      duration : formattedDuration
    })
    // Total Duration Calc
    this.calcTotalDuration()
  }

  // Remove Row
  removeRow(index:any){
    this.short_leave_portion().removeAt(index);
    this.calcTotalDuration();
  }

  removeValidation() {
    // this.invalidPass = false;
    this.alertMsg = '';
    while (this.short_leave_portion().length !== 0) {
      this.short_leave_portion().removeAt(0)
    }
    this.calcTotalDuration();
  }

  // Disable Date
  disableDate() {
    return false;
  }

  // Format for Datepicker
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appServ.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }

  // Month change
  fromMonthSelection(e:any){
    let a = moment(e.toDate()).format('YYYY-MM-DD');
    this.getWeekOffHolidayList(moment(a).month() + 1, moment(a).year());
  }

  // Setup for Calendar
  openCalendar(data: any) {
    setTimeout(() => {
      const buttons = document.querySelectorAll
        ('.mat-calendar-previous-button,.mat-calendar-next-button')
      if (buttons) {
        Array.from(buttons).forEach(button => {
          this.renderer.listen(button, "click", (event) => {
            const date = new Date();
            let elements = document.querySelectorAll(".mat-calendar");
            const cells = elements[0].querySelectorAll(".mat-calendar-body-cell");
            if (data == 'fromdate')
              this.dateTxt = [];
            cells.forEach(x => {

              const date = new Date(String(x.getAttribute("aria-label")));
              const dateTxt =
                date.getFullYear() +
                "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("00" + date.getDate()).slice(-2);
              if (data == 'fromdate')
                this.dateTxt.push(dateTxt);
            });
            if (data == 'fromdate')
              this.weekOffHolidayList(this.dateTxt);
          });
        })
      }
    })
  }

  weekOffHolidayList(date: any) {
    let year = 0;
    let month = 0;
    if (this.dateTxt.length == 0) {
      this.dateTxt = this.appServ.dateFormatConvert(new Date());
    } else {
      this.dateTxt = this.dateTxt[0];
    }
    year = moment(this.dateTxt, "YYYY-MM-DD").year();
    month = moment(this.dateTxt, "YYYY-MM-DD").month() + 1;
    this.getWeekOffHolidayList(month, year);
  }

  getWeekOffHolidayList(month: any, year: any) {
    this.weekoffArray = [];
    this.holidayArray = [];
    this.weekoff = [];
    this.holiday = [];

    this.investmentService.getWeekOffHolidayList({ 'employee': this.employee, 'month': month, 'year': year }).subscribe((res: any) => {
      this.weekoff = res.weekoff;
      this.holiday = res.holiday;
      this.fromDateFilter = (date: Date | null): boolean => {
        this.weekoffArray = [];
        this.holidayArray = [];
        for (let i = 0; i < this.weekoff.length; i++) {
          const weekoff = {
            date: moment(this.weekoff[i]?.date, "YYYY-MM-DD").date(),
            portion: this.weekoff[i]?.portion
          };
          this.weekoffArray.push(weekoff);
        }
        for (let i = 0; i < this.holiday.length; i++) {
          const holiday = moment(this.holiday[i], "YYYY-MM-DD").date();
          this.holidayArray.push(holiday);
        }
        const a = this.appServ.dateFormatConvert(date);
        const d = moment(date, "YYYY-MM-DD").date();
        const weekIndex = this.weekoffArray.findIndex((weekoff:any) => weekoff.date == d && weekoff.portion === 'Full Day')
        return weekIndex == -1 && this.holidayArray.indexOf(d) == -1;
      };
    })
  }

  appendTypeIndicator() {
    const htmlContent = `
    <div class="d-flex flex-wrap w-100 px-16 pb-16 gap-16 fs-12 fw-600">
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-weekoff"></span>WEEKEND
      </div>
      <div class="d-flex align-items-center gap-8">
        <span class="type-indicator sq-12 type-holiday"></span>HOLIDAY
      </div>
    </div>
  `;
    const newDiv = this.renderer.createElement('div');
    newDiv.innerHTML = htmlContent;
    setTimeout(() => {
      if (document.getElementsByTagName('mat-datepicker-content')) {
        var element = document.getElementsByTagName('mat-datepicker-content');
        element[0]?.appendChild(newDiv);
      }
    })
  }

  // File Setup
  getFilename(event: any) {
    this.docName = event.name;
    this.fileSize = this.appServ.formatSizeUnits(event.size);
    window.sessionStorage.setItem('this.fileSize', this.fileSize);
    window.sessionStorage.setItem('this.docName', event.name);
  }

  getloader(event: any) {
    this.docLoader = event;
  }

  getfileformat(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docLoader = false;
    if (event == false) {
      this.fileError = true;
      this.fileErrorMsg = this.msgServ.validateFileFormat('pdf,doc');
    }
  }

  getvalidsize(event: any) {
    this.formGp.get('document')?.setValue('');
    this.docLoader = false;
    this.fileError = true;
    this.fileErrorMsg = event;
  }

  getURL(event: any) {
    this.docURL = event;
    this.fileError = false;
    this.formGp.get('document')?.setValue(event);
    this.docLoader = false;
  }

  deleteFile(ev:any){
    this.formGp.get('document')?.setValue('');
    this.docURL = '';
  }

  closed(bool: any) {
    this.alertToggle1 = bool;
  }

  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName               = filename;
    this.currentDocName         = this.appServ.getDocName(filename);
    this.currentExtension       = this.fileName.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle1         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  // Step 1 Submit
  validateLeaveDetails(){
    this.submitted = true;
    if(this.alertMsg != ''){
      this.alertToggle1 = true
    }else{
      this.submitMethod.emit({'total':this.totalDuration,'textMsg' : this.dateAPIData});
    }
  }

  // Download File
  downLoad(val:any){
    let url = this.appServ.getDocName(val).split('.')[0]
    this.appServ.downloadFile(val,url)
  }
}
