import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { FormBuilder,Validators,FormGroup, FormArray } from '@angular/forms';
import { LeavePolicyService } from 'src/app/@core/services/leave-policy.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
​import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { AppService } from 'src/app/app.global';
import { leavetypeService } from 'src/app/@core/services/leave-type.service';
import { digitdecimalValidator, singleDigitDecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { maxTenDigitDecimal } from 'src/app/@shared/validators/maxTenDigitDecimal.validators';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';

@Component({
  selector: 'app-leave-policy-setup',
  templateUrl: './leave-policy-setup.component.html',
  styleUrls: ['./leave-policy-setup.component.scss']
})
export class LeavePolicySetupComponent implements OnInit {

  activeTab             = 1;
  formatList:any        = [];
  headerText            = 'Add';
  id: number            = 0;
  loader                = false;
  formatLeave:any       = [];
  mapLeavedata:any      = [];
  clubLeavedata:any     = [];
  list:any              = [];
  editflag : boolean = false
  checkVisible = true;
  leaveId : any
  leaveTypeName : any;
  leaveConfigureForm : any
  mapLeaveType : any = [];
  disabled = false
  leaveType:any = [];
  editData:any  = [];
  selectData : any = [];
  activeTabConfig:any= [];
  Lid:any
  activeLeaveType:any = [];
  selecedLeaveCount = 0;

  policyForm:FormGroup  = this.formBuilder.group({
                                policyDetails   : this.formBuilder.group({
                                                  name                      :['',[Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
                                                  leave_cycle               :[false],
                                                  description               :[null, [Validators.maxLength(500)]],
                                                  policy_valid_from         :[null,[Validators.required]],
                                                  leaveCheck                :[false],
                                                  mapleave                  :this.formBuilder.array([])
                                }),
                                configureform: this.formBuilder.group({
                                  leaveform           : this.formBuilder.array([]),
                                  creditdetailsform   : this.formBuilder.array([]),
                                  leaveEligibilty     : this.formBuilder.array([]),
                                  leaveRequestLimit   : this.formBuilder.array([]),
                                  leaveRequestcriteria: this.formBuilder.array([]),
                                }),
                                leave_policy_leaveendprocess:this.formBuilder.group({
                                  manual_auto: [true],
                                  process_to_carron_date: [null, [Validators.required]],
                                  process_to_carriedon_month: [null, [Validators.required]],
                                  negative_balance_leaveyearend: ['Deduct from salary'],
                                }),
                                assign: this.formBuilder.group({
                                  applicableto: [null, [Validators.required]],
                                  assignees: [null, [Validators.required]]
                                })
                        })
  constructor(private _location: Location,private formBuilder:FormBuilder,private leaveservice:LeavePolicyService,private router:Router,public route:ActivatedRoute,private ams:ApprovalMatrixService,public appservice:AppService,public leavetypeService:leavetypeService,public changeDetectorRef: ChangeDetectorRef,private notification : NotificationService) { }

  backClicked() {
    this.router.navigate(['/leave-rule/leave-policy']);
  }
  ngOnInit(): void {
    this.LeavemapFunction();
    this.route.params.subscribe((params: Params) => {

      if( !isNaN(params['id'])){
        this.id         = +params['id'];
        this.headerText = "Edit";
        this.editflag = true
        this.editForms();
      }
      else{
        this.headerText = "Add";
      }
    })
  }

  get policyDetailsForm() {
    return this.policyForm.get('policyDetails') as FormGroup;
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
      if (this.policyForm['controls'].policyDetails.invalid || this.selecedLeaveCount==0) {
        changeEvent.preventDefault();
      }
    }
    if (changeEvent.nextId === 3) {
      if (this.policyForm['controls'].policyDetails.invalid || this.step2Validity()==false || this.checkDuplicateRule()) {
        changeEvent.preventDefault();
      }
    }
    if (changeEvent.nextId === 4) {
      if (this.policyForm['controls'].policyDetails.invalid || this.step2Validity()==false || this.policyForm['controls'].leave_policy_leaveendprocess.invalid || this.checkDuplicateRule()) {
        changeEvent.preventDefault();
      }
    }
  }
  submitNext(){
    this.activeTab = 2;
  }
  // Next Step
  submitMethod(value:any){
    this.selectData = [];
    this.selectData = value?.selectData;
    let val = value?.selectData;
    this.leaveType = value?.leaveType;

    if(this.selectData.length>0){

      this.mapLeaveType = [];
      for(let i=0;i<val.length;i++){
        this.checkVisible = val[i].checkVisible
        if(this.checkVisible==true){
          this.mapLeaveType.push({leaveId:val[i].leaveTypeId,name:val[i].leaveTypeName,leaveCode:val[i].leaveCode,id:val[i].leaveTypeId,color_code:val[i].color_code})
          if(val[i].status=='new'){

            this.newLeaveConfiguration(val[i]);
            this.selectData[i]['status'] = 'old'

          }

        }
        else{

          if(val[i].status=='old')
            this.removeLeaveConfigure(i)
        }
      }
    }


  }
  checkboxEvent(index:any,from:any){
    let id      = this.mapleave().controls[index].get('id')?.value
    let action  = this.mapleave().controls[index].get('checked')?.value
    if(action==true){

      this.activeLeaveType.push(id);
      var leave_object = this.leaveType.find((x:any) => x.id == id);
      this.mapLeaveType.push({leaveId:leave_object.id,name:leave_object.leave_name,leaveCode:leave_object.leave_code,id:leave_object.id,color_code:leave_object.color_code})
      this.activeTabConfig.push({
        'id'    : leave_object.id,
        'tab'   : 1,
        'step1' : false,
        'step2' : false,
        'step3' : false,
        'step4' : false,
        'step5' : false,
        'from'  : from
      })
      this.newLeaveConfiguration(leave_object);

    }
    else{
      let controlIndex    = this.activeLeaveType?.indexOf(id)
      this.mapLeaveType.splice(controlIndex,1);
      this.activeLeaveType.splice(controlIndex,1);
      this.activeTabConfig.splice(controlIndex,1);
      this.removeLeaveConfigure(controlIndex)
    }

  }


submitTwo(){
  this.activeTab = 3;
}

submitThree(){
  this.activeTab = 4;
}

is_valid : boolean = false
checkDuplicateRule(){
  this.is_valid = false
  for (let k = 0; k < this.creditdetailsForm.value.length; k++) {
    const leavemappingCreditRuleArray = this.creditdetailsForm.value[k]['leavemapping_creditrule'] as Array<any>
    for (let i = 0; i < leavemappingCreditRuleArray.length; i++) {
      for (let j = i + 1; j < leavemappingCreditRuleArray.length; j++) {
        const formGroupI = leavemappingCreditRuleArray[i];
        const formGroupJ = leavemappingCreditRuleArray[j]
        let clubbedValueI = `${formGroupI?.effective_type} ${formGroupI?.from_doj_doc}`;
        let clubbedValueJ = `${formGroupJ?.effective_type} ${formGroupJ?.from_doj_doc}`;
        if (
          (
            (clubbedValueI.includes('Days false') || clubbedValueI.includes('Months false') || clubbedValueI.includes('Years false')
        ) &&
            (clubbedValueJ.includes('Days false') || clubbedValueJ.includes('Months false') || clubbedValueJ.includes('Years false')
        ) && formGroupI?.effective_from === formGroupJ?.effective_from) ||
        (
            (clubbedValueI.includes('Days true') || clubbedValueI.includes('Months true') || clubbedValueI.includes('Years true')
        ) &&
            (clubbedValueJ.includes('Days true') || clubbedValueJ.includes('Months true') || clubbedValueJ.includes('Years true')
        ) && formGroupI?.effective_from === formGroupJ?.effective_from)
        ) {
          this.is_valid = true;
          break;
        }
      }
    }
  }
  if(this.is_valid == true){
    this.notification.handleErrorNotification('Credit rule with the same configuration already exists.','Error')
  }
  return this.is_valid
}

newLeaveConfiguration(leave_object:any) {

  this.leaveForm.push(this.formBuilder.group({
      id:'',
      leave_code :leave_object.leave_code,
      leave_name :leave_object.leave_name,
      // leave_unpaid_paid :[true,[Validators.required]],
      negative_balance_allowed:[false],
      negative_balance_days:[''],
      halfday_leave_allow:[true],
      leave_cancellation_allow:[true],
      leave_encashable:[false],
      max_encash_limit_status:[false],
      max_encash_limit:[''],
      do_you_want_weekoffleave:[false],
      weekoffleave_choice:[],
      do_you_want_holidayleave:[false],
      holidayleave_choice:[],
      leaveId:leave_object.id,
      leave_encash_allow_leaveendyear:[false],
      how_encash_work_leaveendyear:[false],
      adjoin_halfday_leave : [false],
      adjoin_halfday_weekoff : [false]
    }));
  this.creditdetailsForm.push(this.formBuilder.group({
    grant_leave :[false],
    leave_code :leave_object.leave_code,
    leavemapping_creditrule :this.formBuilder.array([this.newcreditRule()]),
    carry_forward_feature_year : [false],
    max_carry_forward_count_status: [false],
    carry_forward_count : []



  }));
  this.leaveEligibiltyForm.push(this.formBuilder.group({
    leave_code :leave_object.leave_code,
    leave_applicable_emp_type:[false],
    employment_type:[null],
    leave_applicable_gender:[false],
    gender:[null],
    leave_waiting_status:[false],
    waiting_period_start:[false],
    waiting_period_effective:['Calender days'],
    waiting_period_days:[null],
    leave_default :leave_object.default,
 }));
  this.leaveRequestLimitForm.push(this.formBuilder.group({
    leave_code :leave_object.leave_code,
    max_req_apply_tenure_status:[false],
    max_req_apply_tenure:[null],
    max_req_apply_year_status:[false],
    max_req_apply_year:[null],
    max_req_apply_month_status:[false],
    max_req_apply_month:[null],
    max_leave_apply_month_status:[false],
    max_leave_apply_month:[null],
    min_leave_per_req_status:[false],
    min_leave_per_req:[null],
    max_leave_per_req_status:[false],
    max_leave_per_req:[null]

  }));
  this.leaveRequestCriteriaForm.push(this.formBuilder.group({
      leave_code :leave_object.leave_code,
      prior_days_required_req_leave:[false],
      prior_days:[null],
      backdated_leave_allow:[false],
      backdated_days:[null],
      leave_during_probation:[true,[Validators.required]],
      leave_during_noticeperiod:[true,[Validators.required]],
      doc_required_leave:[false],
      leave_exceed:[null],
      leave_clubbing_not_allowed_status:[false],
      leave_clubbing_leave_type:[null],
      leave_taken_birthday:[false],
      leave_taken_wedding_anniversary:[false]

    }));
  if(leave_object.default==true){
    let index = this.leaveForm.length-1
    this.leaveForm.controls[index].get('negative_balance_allowed')?.disable();
    // this.leaveForm.controls[index].get('leave_encash_allow_leaveendyear')?.disable();
    // this.leaveForm.controls[index].get('leave_encashable')?.setValue(false)
    this.leaveForm.controls[index].get('leave_encashable')?.disable()
    this.creditdetailsForm.controls[index].get('grant_leave')?.setValue(true);
    this.creditdetailsForm.controls[index].get('grant_leave')?.disable();
    this.creditdetailsForm.controls[index].get('carry_forward_feature_year')?.setValue(true);
    this.creditdetailsForm.controls[index].get('carry_forward_feature_year')?.disable();
    this.creditdetailsForm.controls[index].get('max_carry_forward_count_status')?.setValue(false);
    this.creditdetailsForm.controls[index].get('max_carry_forward_count_status')?.disable();
    this.creditdetailsForm.controls[index].get('carry_forward_count')?.setValue(0);
    this.creditdetailsForm.controls[index].get('carry_forward_count')?.disable();
    this.leavemapping_creditrule(index).clear();

  }
  if(leave_object?.leave_unpaid_paid==false){
     let index = this.leaveForm.length-1
     this.leaveForm.controls[index].get('leave_encashable')?.disable();
  }
  }
  newcreditRule(){
    return this.formBuilder.group({
      "effective_from"                : [0,[Validators.required,Validators.min(0),Validators.maxLength(10),Validators.pattern('^[0-9]+$')]],
      "effective_type"                : ['Days',[Validators.required]],
      "from_doj_doc"                  : [true,[Validators.required]],
      "do_want_to_credit_leave"       : ['Yearly',[Validators.required]],
      "no_of_leaves_credited"         : ['',[Validators.required,Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      "which_dayof_month"             : [null,[Validators.required]],
      'leavecreditrule_firstmonth'    : this.formBuilder.array([]),
      leavecreditrule_lastmonth       : this.formBuilder.array([]),
      first_month_rule                : [false],
      leave_credit_timeofjoining      : ['Prorate based on credit rule start date',[Validators.required]],
      leave_credit_timeofexiting      : ['Prorate based on exit date of employee',[Validators.required]],
      last_month_rule                 : [false],
      grant_leave_prev_month          : [false],
      req_attend_calculate            : [true],
      prev_month_greater_than         : [''],
      criteria_not_met                : [true],
      roundoff_leave_granting         : [false],
      credit_lapse_enable             : [false],
      credit_lapse_cond               : [true],
      credit_lapse                    : [''],
      roundoff_option                 : [null],
      leave_opening_balance_enable    : [false],
      leave_opening_balance           : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      carry_over_prev_creditrule      : [false],
      carry_over_limit                : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      carry_forward_next_year         : [false],
      carry_forward_limit             : ['',[Validators.min(0.1),maxTenDigitDecimal(),digitdecimalValidator()]],
      max_carry_forward_status        : [false],
      max_carry_over_status           : [false]
    })
  }
  step2Validity(){
    let ret = true
    if(this.configureForm.invalid){
      ret = false
    }
    else{
      for(let i=0;i<this.creditdetailsForm.controls.length;i++){
        if(this.creditdetailsForm.controls[i].get('grant_leave')?.value==false &&  this.creditdetailsForm.controls[i].get('leavemapping_creditrule')?.value.length==0){
          ret = false
        }
      }
      for(let j=0;j<this.activeTabConfig.length;j++){
        if(this.activeTabConfig[j]['step1']==false || this.activeTabConfig[j]['step2']==false || this.activeTabConfig[j]['step3']==false || this.activeTabConfig[j]['step4']==false || this.activeTabConfig[j]?.step5==false){
          ret = false
        }
      }

    }

    return ret

  }

removeLeaveConfigure(index:any){
  // this.configureForm.removeAt(index);
  this.leaveForm.removeAt(index);
  this.leaveEligibiltyForm.removeAt(index);
  this.leaveRequestCriteriaForm.removeAt(index);
  this.leaveRequestLimitForm.removeAt(index);
  this.creditdetailsForm.removeAt(index);
}


get configureForm():FormArray {
  return this.policyForm.get('configureform') as FormArray;
}


get leaveForm():FormArray {
  return this.configureForm.get('leaveform') as FormArray;
}

get creditdetailsForm():FormArray {
  return this.configureForm.get('creditdetailsform') as FormArray;
}

get leaveEligibiltyForm():FormArray {
  return this.configureForm.get('leaveEligibilty') as FormArray;
}

get leaveRequestLimitForm():FormArray {
  return this.configureForm.get('leaveRequestLimit') as FormArray;
}

get leaveRequestCriteriaForm():FormArray {
  return this.configureForm.get('leaveRequestcriteria') as FormArray;
}

get assignForm() {
  return this.policyForm.get('assign') as FormGroup;
}

get leaveYearEndForm() {
  return this.policyForm.get('leave_policy_leaveendprocess') as FormGroup;
}

submitForm(){

  var applicable = this.assignForm.controls.applicableto.value;
    var applicableStr = applicable;
    if(applicable == "BU")
      applicableStr = "Businessunit";
    var arr = [];
    for(let key of this.assignForm.controls.assignees.value){
      arr.push({
        "applicable":applicable,
        [applicableStr.toLowerCase()]:key,
     });
    }

    var leaveConfifarr = []
    var configArr:any = [];
    let key =0
    for(let keyVal of this.leaveForm.getRawValue()){
      leaveConfifarr = [];
      leaveConfifarr.push({
        "leavemapping_creditrule":this.processedData(this.creditdetailsForm.controls[key]?.value.leavemapping_creditrule),
        // "leave_unpaid_paid": keyVal.leave_unpaid_paid,
        "negative_balance_allowed":keyVal.negative_balance_allowed,
        "negative_balance_days":(keyVal.negative_balance_allowed == true)?keyVal.negative_balance_days:0,
        "halfday_leave_allow":keyVal.halfday_leave_allow,
        "leave_cancellation_allow":keyVal.leave_cancellation_allow,
        "leave_encashable":keyVal.leave_encashable,
        "max_encash_limit_status":keyVal.max_encash_limit_status,
        "max_encash_limit":(keyVal.max_encash_limit_status == true)?keyVal.max_encash_limit:0,
        "leave_encash_allow_leaveendyear": (keyVal.leave_encash_allow_leaveendyear== null)?false:(keyVal.leave_encash_allow_leaveendyear == true ? true : false),
        "how_encash_work_leaveendyear": (keyVal.how_encash_work_leaveendyear== null)?false:(keyVal.how_encash_work_leaveendyear == true ? true : false),
        "do_you_want_weekoffleave":keyVal.do_you_want_weekoffleave,
        "weekoffleave_choice":(keyVal.do_you_want_weekoffleave == true)?keyVal.weekoffleave_choice:'',
        "do_you_want_holidayleave":keyVal.do_you_want_holidayleave,
        "holidayleave_choice":(keyVal.do_you_want_holidayleave == true)?keyVal.holidayleave_choice:'',
        "adjoin_halfday_leave" : keyVal.adjoin_halfday_leave,
        "adjoin_halfday_weekoff" : keyVal.adjoin_halfday_weekoff,

        "grant_leave":this.creditdetailsForm.controls[key]?.get('grant_leave')?.value,
        "carry_forward_feature_year" :this.creditdetailsForm.controls[key]?.get('carry_forward_feature_year')?.value,
        "max_carry_forward_count_status" :this.creditdetailsForm.controls[key]?.get('max_carry_forward_count_status')?.value,
        "carry_forward_count" :this.creditdetailsForm.controls[key]?.get('carry_forward_count')?.value == "" ? 0 : this.creditdetailsForm.controls[key]?.get('carry_forward_count')?.value,
        "leave_applicable_emp_type":this.leaveEligibiltyForm.controls[key]?.get('leave_applicable_emp_type')?.value,
        "employment_type":(this.leaveEligibiltyForm.controls[key]?.get('leave_applicable_emp_type')?.value == true)?this.leaveEligibiltyForm.controls[key]?.get('employment_type')?.value:[],
        "leave_applicable_gender":this.leaveEligibiltyForm.controls[key]?.get('leave_applicable_gender')?.value,
        "gender":(this.leaveEligibiltyForm.controls[key]?.get('leave_applicable_gender')?.value == true)?this.leaveEligibiltyForm.controls[key]?.get('gender')?.value:[],
        "leave_waiting_status":this.leaveEligibiltyForm.controls[key]?.get('leave_waiting_status')?.value,
        "waiting_period_start":this.leaveEligibiltyForm.controls[key]?.get('waiting_period_start')?.value,
        "waiting_period_effective":this.leaveEligibiltyForm.controls[key]?.get('waiting_period_effective')?.value,
        "waiting_period_days":(this.leaveEligibiltyForm.controls[key]?.get('waiting_period_days')?.value != null?this.leaveEligibiltyForm.controls[key]?.get('waiting_period_days')?.value:0),

        "prior_days_required_req_leave":this.leaveRequestCriteriaForm.controls[key]?.get('prior_days_required_req_leave')?.value,
        "prior_days":(this.leaveRequestCriteriaForm.controls[key]?.get('prior_days_required_req_leave')?.value == true)?this.leaveRequestCriteriaForm.controls[key]?.get('prior_days')?.value:0,
        "backdated_leave_allow":this.leaveRequestCriteriaForm.controls[key]?.get('backdated_leave_allow')?.value,
        "backdated_days":(this.leaveRequestCriteriaForm.controls[key]?.get('backdated_leave_allow')?.value == true)?this.leaveRequestCriteriaForm.controls[key]?.get('backdated_days')?.value:0,
        "leave_during_probation":this.leaveRequestCriteriaForm.controls[key]?.get('leave_during_probation')?.value,
        "leave_during_noticeperiod":this.leaveRequestCriteriaForm.controls[key]?.get('leave_during_noticeperiod')?.value,
        "doc_required_leave":this.leaveRequestCriteriaForm.controls[key]?.get('doc_required_leave')?.value,
        "leave_exceed":(this.leaveRequestCriteriaForm.controls[key]?.get('doc_required_leave')?.value == true)?this.leaveRequestCriteriaForm.controls[key]?.get('leave_exceed')?.value:0,
        "leave_clubbing_not_allowed_status":this.leaveRequestCriteriaForm.controls[key]?.get('leave_clubbing_not_allowed_status')?.value,
        "leave_taken_birthday":this.leaveRequestCriteriaForm.controls[key]?.get('leave_taken_birthday')?.value,
        "leave_taken_wedding_anniversary":this.leaveRequestCriteriaForm.controls[key]?.get('leave_taken_wedding_anniversary')?.value,
        "leave_clubbing_leave_type":(this.leaveRequestCriteriaForm.controls[key]?.get('leave_clubbing_not_allowed_status')?.value )?this.leaveRequestCriteriaForm.controls[key]?.get('leave_clubbing_leave_type')?.value:[],

        "max_req_apply_tenure_status":this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_tenure_status')?.value,
        "max_req_apply_tenure":(this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_tenure_status')?.value == true)?this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_tenure')?.value:0,

        "max_req_apply_year_status":this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_year_status')?.value,
        "max_req_apply_year":(this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_year_status')?.value == true)?this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_year')?.value:0,

        "max_req_apply_month_status":this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_month_status')?.value,
        "max_req_apply_month":(this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_month_status')?.value == true)?this.leaveRequestLimitForm.controls[key]?.get('max_req_apply_month')?.value:0,

        "max_leave_apply_month_status":this.leaveRequestLimitForm.controls[key]?.get('max_leave_apply_month_status')?.value,
        "max_leave_apply_month":(this.leaveRequestLimitForm.controls[key]?.get('max_leave_apply_month_status')?.value == true)?this.leaveRequestLimitForm.controls[key]?.get('max_leave_apply_month')?.value:0,

        "min_leave_per_req_status":this.leaveRequestLimitForm.controls[key]?.get('min_leave_per_req_status')?.value,
        "min_leave_per_req":(this.leaveRequestLimitForm.controls[key]?.get('min_leave_per_req_status')?.value == true)?this.leaveRequestLimitForm.controls[key]?.get('min_leave_per_req')?.value:0,

        "max_leave_per_req_status":this.leaveRequestLimitForm.controls[key]?.get('max_leave_per_req_status')?.value,
        "max_leave_per_req":(this.leaveRequestLimitForm.controls[key]?.get('max_leave_per_req_status')?.value == true)?this.leaveRequestLimitForm.controls[key]?.get('max_leave_per_req')?.value:0,

      })
        configArr.push({
          "leave_type": keyVal?.leaveId,
          "leavemapping_configuration": leaveConfifarr
      })
      key++;
    }

    var leaveYearEndarr = [];
    leaveYearEndarr.push({
        "manual_auto":this.leaveYearEndForm.controls.manual_auto.value,
        "process_to_carron_date":this.leaveYearEndForm.controls.process_to_carron_date.value,
        "process_to_carriedon_month":this.leaveYearEndForm.controls.process_to_carriedon_month.value,
        "negative_balance_leaveyearend":this.leaveYearEndForm.controls.negative_balance_leaveyearend.value,
      });
    var dataArray:any;
    dataArray = {
      "name": this.policyDetailsForm.controls.name.value,
      "description": this.policyDetailsForm.controls.description.value,
      "leave_cycle": this.policyDetailsForm.controls.leave_cycle.value,
      "policy_valid_from": this.appservice.dateFormatConvert(this.policyDetailsForm.controls.policy_valid_from.value),
      "leave_mapping": configArr,
      "leave_policy_assignright": arr,
      "leave_policy_leaveendprocess":leaveYearEndarr

    }

    if(this.id!=0){

      dataArray['id'] = this.id;
      this.leaveservice.updateLeavePolicy(this.id,dataArray).subscribe((res:any)=>{
        this.router.navigate(['/leave-rule/leave-policy']);

      })

    }
    else{

      this.leaveservice.createLeavePolicy(dataArray).subscribe((res:any)=>{
        this.router.navigate(['/leave-rule/leave-policy']);
      })
    }

}

processedData(form:any){
  let alteredForm = form.map((item:any)=>{
    if(item?.carry_forward_limit == ""){
      item.carry_forward_limit = 0
    }
    if(item?.carry_over_limit == ""){
      item.carry_over_limit = 0
    }
    if(item?.leave_opening_balance == ""){
      item.leave_opening_balance = 0
    }
    if(item?.prev_month_greater_than == ""){
      item.prev_month_greater_than = 0
    }
    if(item?.credit_lapse == ""){
      item.credit_lapse = 0
    }
    return item
  })
  return alteredForm
}

editForms(){
  this.loader = true;
  this.editflag = true
  this.leaveservice.getSingleLeavePolicyData(this.id).subscribe((res:any)=>{

    this.setEditForm(res);


},(error: any)=>{
  this.router.navigate(['/leave-rule/leave-policy']);
});

}

setEditForm(res:any){

  this.id=res.id;
  let applicableto = "";
  let assignees = [];
  let appkey ="";
  if(res.is_active == false){
    this.configureForm.disable();
    this.disabled = true;
  }
  for(let key of res.leave_policy_assignright){
    appkey = key.applicable.toLowerCase();
    if(appkey == "bu")
      appkey = "businessunit";
    assignees.push(key[appkey]);
    applicableto = key.applicable
  }
  let manual_auto = ""
  let process_to_carron_date = ""
  let process_to_carriedon_month = ""
  let negative_balance_leaveyearend = ""
  for(let key of res.leave_policy_leaveendprocess){
    manual_auto = key.manual_auto;
    process_to_carron_date = key.process_to_carron_date;
    process_to_carriedon_month = key.process_to_carriedon_month;
    negative_balance_leaveyearend = key.negative_balance_leaveyearend;
  }
  this.policyForm.reset(
    {
      id: res.id,
      policyDetails:{
        name:res.name,
        leave_cycle:res.leave_cycle,
        description:res.description,
        policy_valid_from:res.policy_valid_from,

      },
      leave_policy_leaveendprocess:{
        manual_auto :manual_auto,
        process_to_carron_date : process_to_carron_date,
        process_to_carriedon_month : process_to_carriedon_month,
        negative_balance_leaveyearend : negative_balance_leaveyearend
      },
      assign: {
        applicableto: applicableto,
        assignees: assignees
      },

  })
  if(!manual_auto){
    this.leaveYearEndForm.get('process_to_carriedon_month')?.clearValidators()
    this.leaveYearEndForm.get('process_to_carriedon_month')?.updateValueAndValidity()
    this.leaveYearEndForm.get('process_to_carron_date')?.clearValidators()
    this.leaveYearEndForm.get('process_to_carron_date')?.updateValueAndValidity()
  }
  this.policyForm['controls'].policyDetails.get('leave_cycle')?.disable();
  this.mapLeavetypeEdit(res.leave_mapping);
}
leavemapping_creditrule(currentIndex:any): FormArray {
  return this.creditdetailsForm.controls[currentIndex].get("leavemapping_creditrule") as FormArray
}
newfstMonthrule(): FormGroup {
  return this.formBuilder.group({"from_date"   : [null,[Validators.required]], "to_date"     : [null,[Validators.required]],  "count_number": ['',[Validators.required,maxTenDigitDecimal(),digitdecimalValidator()]]})
}
newLstMonthrule(): FormGroup {
  return this.formBuilder.group({ "from_date"   : [null,[Validators.required]], "to_date"     : [null,[Validators.required]], "count_number": ['',[Validators.required,maxTenDigitDecimal(),digitdecimalValidator()]]})
}

firstmonth(cId: number,iId:number): FormArray {

  return this.leavemapping_creditrule(iId)
    .at(cId)
    .get('leavecreditrule_firstmonth') as FormArray;
}
lastmonth(cId: number,iId:number): FormArray {
  return this.leavemapping_creditrule(iId)
    .at(cId)
    .get('leavecreditrule_lastmonth') as FormArray;
}
setFormData(){

  for(let i=0;i<this.mapLeaveType.length;i++){
    // if(i>0)
    //   this.newLeaveConfiguration({})

    //corect the order of the data after save

    for(let j=0;j<this.editData.length;j++){

      if(this.mapLeaveType[i]['leaveId']==this.editData[j]['leave_type']){
        if(this.activeTabConfig[i]== undefined || this.activeTabConfig[i]['step1'] == false){


          let leave_club:any = [];

          Object.entries( this.editData[j]['leavemapping_configuration'][0] ).forEach(([key, value]) => {

            if(key!='leavemapping_creditrule' && key!='leave_clubbing_leave_type'){
              this.leaveForm.controls[i].get(key)?.setValue(value);
              this.leaveEligibiltyForm.controls[i].get(key)?.setValue(value);
              this.leaveRequestCriteriaForm.controls[i].get(key)?.setValue(value);
              this.leaveRequestLimitForm.controls[i].get(key)?.setValue(value);
              this.creditdetailsForm.controls[i].get(key)?.setValue(value);


            }
            else if(key=='leave_clubbing_leave_type'){
              let club_value:any = value;

              for(let i=0;i<club_value.length;i++){
                leave_club.push(club_value[i].id)
              }
              this.leaveRequestCriteriaForm.controls[i].get(key)?.setValue(leave_club);
            }
          else if(key=='leavemapping_creditrule' && this.editData[j]['leavemapping_configuration'][0]['grant_leave']==false){
            let credit_data:any = value;

            for(let map=0;map<credit_data.length;map++){

              if(map>0)
              this.leavemapping_creditrule(i).push(this.newcreditRule());

              Object.entries( credit_data[map] ).forEach(([key, value]) => {

                if(key!='leavecreditrule_firstmonth' && key!='leavecreditrule_lastmonth'){
                  this.leavemapping_creditrule(i).controls[map]?.get(key)?.setValue(value);
                  if(key == 'no_of_leaves_credited' || key == 'leave_opening_balance' || key == 'carry_over_limit' || key == 'carry_forward_limit' || key == 'prev_month_greater_than' || key == 'credit_lapse'){
                    value == 0 && this.leavemapping_creditrule(i).controls[map]?.get(key)?.setValue('');
                  }
                  if(key == 'which_dayof_month'){
                    this.leavemapping_creditrule(i).controls[map]?.get(key)?.setValue(value!='Last day of month'?Number(value):value);
                  }
                }
                else if(key=='leavecreditrule_firstmonth'){
                  let firstMonthdata:any = value;
                  for(let first=0;first<firstMonthdata.length;first++){
                    this.firstmonth(map,i).push(this.newfstMonthrule());
                    Object.entries( firstMonthdata[first] ).forEach(([key, value]) => {
                      if(key!='from_date' && key!='to_date')
                      this.firstmonth(map,i).controls[first].get(key)?.setValue(value);
                      else
                      this.firstmonth(map,i).controls[first].get(key)?.setValue(value!='Last day of month'?Number(value):value);
                    })

                  }
                }
                else{
                  let lastMonthdata:any = value;

                  for(let last=0;last<lastMonthdata.length;last++){
                    this.lastmonth(map,i).push(this.newLstMonthrule());
                    Object.entries( lastMonthdata[last] ).forEach(([key, value]) => {
                      if(key!='from_date' && key!='to_date')
                      this.lastmonth(map,i).controls[last].get(key)?.setValue(value);
                      else
                      this.lastmonth(map,i).controls[last].get(key)?.setValue(value!='Last day of month'?Number(value):value);


                    })

                  }

                }

              })
            }
          } else if(this.editData[j]['leavemapping_configuration'][0]['grant_leave']){
            this.leavemapping_creditrule(i).clear();
          }

          })
          this.activeTabConfig[i]['step1'] = true;
          this.activeTabConfig[i]['step2'] = true;
          this.activeTabConfig[i]['step3'] = true;
          this.activeTabConfig[i]['step4'] = true;
          this.activeTabConfig[i]['step5'] = true;
          this.activeTabConfig[i]['from']  = 'edit';
        }
      }
    }
  }

}

mapLeavetypeEdit(data:any){
  // this.loader = true;
  this.editData = data;

  this.selecedLeaveCount = 0;

  for(let i=0;i<this.mapleave().length;i++){
    this.mapleave().controls[i].get('id')?.setValue(this.leaveType[i]['id']);

    var leave_object = data.find((x:any) => x.leave_type == this.leaveType[i]['id'] && x.is_active==true);

    if(leave_object!=undefined){

      this.mapleave().controls[i].get('checked')?.setValue(true);

      if(this.leaveType[i].default==true)
      this.mapleave().controls[i].get('checked')?.disable();
      this.selecedLeaveCount++;
      this.checkboxEvent(i,'edit')
    }
    if(i == this.mapleave().length-1){
      this.setFormData()

    }
  }


  this.loader =  false;
}
mapleave() {
  return this.policyDetailsForm.get('mapleave') as FormArray;
}
createLeave():FormGroup{

  return this.formBuilder.group({
                                  id                  : [this.Lid],
                                  checked             : []

                                })

}
addLeave() {

  this.mapleave().push(this.createLeave());


}
LeavemapFunction(){
  this.loader = true;
  this.leavetypeService.getLeaveDropdown().subscribe((res: any) => {

  this.leaveType = res;

    for(let i=0;i<this.leaveType.length;i++){
      this.Lid = this.leaveType[i]['id']
      this.addLeave();
      if(this.leaveType[i]['default']==true && this.editflag==false){
        this.mapleave().controls[i].get('checked')?.setValue(true);
        this.mapleave().controls[i].get('checked')?.disable();
        this.checkboxEvent(i,'add')
      }
    }
    if(this.headerText=='Add'){

      this.loader = false;
    }




  });
}
checkboxCount(count:any){
  this.selecedLeaveCount = count;
}

ngAfterViewChecked(){
  //your code to update the model
  this.changeDetectorRef.detectChanges();
}
}



