<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Holiday</h3>
      <p class="text-helper mb-md-0">Create holiday types like national and floating holidays and have flexible holiday
        policies mapped to them.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='holidaysearch'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchholiday($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div class="" style="width:22.6875rem;">
          <ng-select class="form-ngselect " (change)="yearChange($event)" [(ngModel)]="selectedYear"
            placeholder="{{this.messageService.selectplaceholddisplay('year')}}" id="year">
            <ng-option class="fs-12" *ngFor="let years of yearList" [value]="years"> {{years}} </ng-option>

          </ng-select>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';listFilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';listFilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';listFilter(false)">Deleted</button>
          </div>
        </div>

        <button class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a"
          (click)="addholidayForm()"><i class="icon-plus"></i>Add Holiday </button>
      </div>
    </div>
  </div>
  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card7'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="!holiday_list_flag && !loader;else all_data " class="row " style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg8.png'" [noDataText]="'Holiday Fun!'"
      [noDataPara]="'Define and manage different holiday types and ensure everyone knows their time off options. Click \'Add Holiday type\' to get started.'"
      [hasPermission]="permissions?.a" (addData)="addholidayForm()"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <ng-container *ngFor="let holidaytype of holidayTypeList, let i = index">
          <div class="  col-md-3 col-sm-6  ">
            <div class="card card-custom1 card-hover">
              <div class="card-body p-0 d-flex align-items-start">
                <div class="d-flex view-section" (click)="viewholidaytyp(holidaytype.id)">
                  <div class="logo-img {{holidaytype.color_code}}">
                    {{this.appService.getFirstChar(holidaytype.holiday_name,2)}}</div>

                  <div class="overflow-hidden">
                    <h6 class="mb-0 fw-500 text-trim tips">{{holidaytype.holiday_name}} (
                      <ng-container *ngFor="let items of holidaytype.list_date, let i = index">
                        {{this.appService.dateFormatDisplay(items)}}
                        <ng-container *ngIf="i!=holidaytype.list_date.length-1">,</ng-container>
                      </ng-container>)
                    </h6>
                    <p class="mb-0  tips-helper">{{holidaytype.holiday_name}} ( <ng-container
                        *ngFor="let items of holidaytype.list_date, let i = index">
                        {{this.appService.dateFormatDisplay(items)}}
                        <ng-container *ngIf="i!=holidaytype.list_date.length-1">,</ng-container>
                      </ng-container>
                      )</p>
                    <span
                      class="fs-10 text-uppercase fw-500 {{(holidaytype.is_active == true) ? 'text-success' : 'text-danger'}}">{{(holidaytype.is_active
                      == true) ? 'Active' : 'Deleted'}}</span>
                  </div>
                </div>
                <div ngbDropdown class="dropdown-section"
                  *ngIf="holidaytype.is_active && (permissions?.e || permissions?.d)">
                  <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                    <i class="icon-three-dots-vertical fs-16"></i>
                  </button>
                  <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                    <button *ngIf="permissions?.e" ngbDropdownItem (click)="editholidayForm(holidaytype.id)">
                      <i class="icon-pencil-square me-1"></i> Edit
                    </button>
                    <button *ngIf="permissions?.d" class="text-danger" ngbDropdownItem
                      (click)="deleteholidaytype(holidaytype.id)">
                      <i class="icon-trash  me-1"></i> Delete
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-16" (click)="viewDetail = true">
                <div class="row  row-16 fs-12 w-100">
                  <div class="col-lg-6 ">
                    <div class="text-light-400 fs-10 text-uppercase">FLOATING HOLIDAY</div>
                    <div class="fw-500 ">{{holidaytype.floating?'Yes':'No'}}</div>
                  </div>
                  <div class="col-lg-6 overflow-hidden">
                    <div class="text-light-400 fs-10 text-uppercase">NATIONAL HOLIDAY</div>
                    <div class="fw-500 ">{{holidaytype.national_holiday?'Yes':'No'}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-md-3 col-sm-6" [cardType]="'card7'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
          <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
      </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader> -->


</div>

<div class="side-panel" style="--sidepanel-width:43.75rem;" [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="d-flex mb-16">
          <p class="text-uppercase fw-500 mb-0">Holiday </p>
          <a class="link-primary fs-14 d-flex align-items-center ms-auto"
            (click)="editholidayForm(viewholidaytypedata.id)"
            *ngIf="viewholidaytypedata.is_active==true && (permissions?.e)">
            <i class="icon-pencil-square me-1"></i> Edit
          </a>
        </div>
        <!-- View loader start -->
        <app-view-loader *ngIf="loader1"></app-view-loader>
        <!-- View loader end -->
        <div class="row row-24 mb-24" *ngIf="!loader1">
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">Holiday DETAILS</span></div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Holiday name</div>
                  <div class="detail-desc">{{viewholidaytypedata.holiday_name}}</div>
                </div>
                <div class=" col-sm-4 col-12">
                  <div class="detail-title">From date</div>
                  <div class="detail-desc">{{this.appService.dateFormatDisplay(viewholidaytypedata.from_date)}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Status</div>
                  <div class="detail-desc">
                    <span class="fs-12 fw-500 text-success text-uppercase"
                      *ngIf="viewholidaytypedata.is_active">ACTIVE</span>
                    <span class="fs-12 fw-500 text-danger text-uppercase"
                      *ngIf="viewholidaytypedata.is_active==false">DELETED</span>
                  </div>
                </div>

                <div class="col-sm-4 col-12">
                  <div class="detail-title">To date</div>
                  <div class="detail-desc">{{this.appService.dateFormatDisplay(viewholidaytypedata.to_date)}}</div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="detail-title">Floating holiday</div>
                  <div class="detail-desc" *ngIf="viewholidaytypedata.floating">Yes</div>
                  <div class="detail-desc" *ngIf="viewholidaytypedata.floating==false">No</div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="detail-title">National holiday</div>
                  <div class="detail-desc" *ngIf="viewholidaytypedata.national_holiday">Yes</div>
                  <div class="detail-desc" *ngIf="viewholidaytypedata.national_holiday==false">No</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='editDetail === true'>
  <form [formGroup]="holidaytypeForm" (ngSubmit)="holidaytypFormSubmit()" class="side-panel-container"
    autocomplete="off">
    <header class="side-panel-head">
      <h5><span [innerHTML]="header"></span> Holiday</h5>
      <a class="toggle-panel" (click)="editDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>

    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <!-- Edit loader start -->
        <app-edit-loader *ngIf="loader1"></app-edit-loader>
        <!-- Edit loader end -->
        <ng-container *ngIf="!loader1">
          <p class="text-uppercase fw-500"><span [innerHTML]="header"></span> Holiday DETAILS</p>
          <div class="row">
            <div class="col-12 form-row mb-24">
              <label for="name" class="form-label required">Holiday name</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enter the name of the holiday (or festival) eg: Onam, Diwali, etc."
                  popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
              <input type="text" class="form-control" formControlName="holiday_name" id="name"
                placeholder="{{this.messageService.placeholderdisp('Holiday Name')}}"
                [ngClass]="{ 'is-invalid': (submitted || f.holiday_name.dirty || f.holiday_name.touched) && f.holiday_name.errors || f.holiday_name.errors?.exist}"
                (keyup)="keyUp()">
              <div
                *ngIf="(submitted || f.holiday_name.dirty || f.holiday_name.touched) && f.holiday_name.errors || f.holiday_name.errors?.exist"
                class="invalid-feedback">
                <div *ngIf="f.holiday_name.hasError('exist')">
                  {{this.messageService.validationDisplay(f.holiday_name.value)}}</div>
                <div *ngIf="f.holiday_name.errors?.required">{{this.messageService.validationDisplay('required')}}</div>
                <div *ngIf="f.holiday_name.errors?.pattern">{{this.messageService.validationDisplay('pattern')}}</div>
                <div *ngIf="!f.holiday_name.errors?.pattern && f.holiday_name.errors?.maxlength">
                  {{this.messageService.fieldlengthvalidation('word','100')}}</div>
              </div>
            </div>
            <div class="col-md-6 form-row mb-24">
              <label for="name" class="form-label required">From date </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="select the holiday start date." popoverClass="popover-input popover-info"
                  placement="bottom" triggers="hover"></i></span>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.from_date.dirty || f.from_date.touched)  && f.from_date.errors }">
                <input (click)="datePicker1.open()" class="form-control" [max]="maxFromValue" [min]="minFromValue"
                  (dateInput)="fromDate($event);keyUp()" placeholder="{{this.messageService.selectplaceholddisplay('Date')}}"
                  matInput formControlName="from_date" [matDatepicker]="datePicker1" (keydown)="disableDate()"
                  [ngClass]="{ 'is-invalid': (submitted || f.from_date.touched)  && (f.from_date.errors || holidaytypeForm.errors!=null) }"><i
                  class="icon-calendar pointer" (click)="datePicker1.open()"></i>
                <mat-datepicker touchUi="false" #datePicker1></mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.from_date.dirty || f.from_date?.touched)  && f.from_date.errors"
                class="invalid-feedback">
                <div *ngIf="f.from_date.errors.required">{{this.messageService.validationDisplay('required')}}

                </div>
              </div>
            </div>

            <div class="col-md-6 form-row mb-24">
              <label for="name" class="form-label required">To date </label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="select the holiday end date" popoverClass="popover-input popover-info" placement="bottom"
                  triggers="hover"></i></span>
              <div class="input-group form-icon icon-right"
                [ngClass]="{ 'is-invalid': (submitted || f.to_date.touched)  && (f.to_date.errors || holidaytypeForm.errors!=null) }">
                <input (click)="datePicker2.open()" class="form-control" [max]="maxFromValue" [min]="f.from_date.value"
                  placeholder="{{this.messageService.selectplaceholddisplay('Date')}}" matInput
                  formControlName="to_date" [matDatepicker]="datePicker2" (keydown)="disableDate()"
                  [ngClass]="{ 'is-invalid': (submitted || f.to_date.touched)  && (f.to_date.errors || holidaytypeForm.errors!=null) }"><i
                  class="icon-calendar pointer" (click)="datePicker2.open()"></i>
                <mat-datepicker touchUi="false" #datePicker2></mat-datepicker>
              </div>
              <div *ngIf="(submitted || f.to_date?.touched) && (f.to_date.errors || holidaytypeForm.errors!=null)"
                class="invalid-feedback">
                <div *ngIf="(submitted || f.to_date?.touched) && f.to_date?.errors?.required">
                  {{this.messageService.validationDisplay('required')}}

                </div>
                <div *ngIf="holidaytypeForm.errors!=null ">To date should be greater than from date.</div>

              </div>
            </div>
            <div class="col-12 form-row">
              <label for="" class="form-label required mb-8">Floating holiday</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option if the holiday type is a Floating  holiday"
                  popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mb-24">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [value]="true" id="floating_yes" name="floating"
                  formControlName="floating" (click)="toggleType('floating')">
                <label class="form-check-label" for="floating_yes">
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [value]="false" id="floating_no" name="floating"
                  formControlName="floating" (click)="toggleType('floating')">
                <label class="form-check-label" for="floating_no">
                  No
                </label>
              </div>
            </div>
            <div class="col-12 form-row">
              <label for="" class="form-label required mb-8">National holiday</label>
              <span class=" ms-8"><i class="bi bi-info-circle text-accent4 fs-14 tooltip-icon"
                  ngbPopover="Enable this option if the holiday type is a national holiday"
                  popoverClass="popover-input popover-info" placement="bottom" triggers="hover"></i></span>
            </div>
            <div class="col-lg-12 form-row mb-24">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [value]="true" id="national_holiday_yes"
                  name="national_holiday" formControlName="national_holiday" (click)="toggleType('national')">
                <label class="form-check-label" for="national_holiday_yes">
                  Yes
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [value]="false" id="national_holiday_no"
                  name="national_holiday" formControlName="national_holiday" (click)="toggleType('national')">
                <label class="form-check-label" for="national_holiday_no">
                  No
                </label>
              </div>
            </div>
          </div>

        </ng-container>
      </div>
    </div>
    <footer class="side-panel-footer gap-32" *ngIf="!loader1">
      <button class="btn btn-outline-danger text-uppercase btn-sm" type="button" (click)="deleteToggle = true"
        *ngIf="deleteClass==true && permissions?.d" [disabled]='disabled'>Delete</button>
      <button *ngIf="permissions?.a ||  permissions?.e" class="ms-auto btn btn-primary text-uppercase btn-sm"
        [disabled]='disabled || !holidaytypeForm.valid'>SAVE</button>
    </footer>
  </form>
</div>

<!-- delete model  -->
<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Holiday Type?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Holiday Type')}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase fw-500"
          (click)="deleteToggle = false">CANCEL</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase fw-500" (click)="confirmDelete()"
          [disabled]="deleteClicked">DELETE</button>
      </div>
    </div>
  </div>
</div>

<!-- test start -->
<div class="modal  modal-alert {{futureAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" class="btn-close ms-auto my-0" (click)="futureAlert = false"></button>
      </div>
      <div class="modal-body">
        <p class="mb-0"> Any changes in the dates, the request will become Invalid if any employee has already applied
          for this holiday. Do you want to continue? </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="futureAlert = false;disabled=false;">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm text-uppercase"
          (click)="updateHolidayType();futureAlert=false;">SAVE</button>
      </div>
    </div>
  </div>
</div>
<!-- test end -->
