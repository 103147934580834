import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LeaveBalanceService {
    API_PATH: string = environment.apiPath;
    httpOption = {
      headers : new HttpHeaders({
        'Content-Type' : 'application/json',
      })
    }


    constructor(
      private http: HttpClient,
      public router : Router
    ) { }


    // leaveBalance(body: any){
    //   return this.http.post(`${this.API_PATH}request/api/v1/leave-balance/`, body, this.httpOption)
    // }

    leaveTrend(body: any){
      return this.http.post(`${this.API_PATH}request/api/v1/profile-leave-balance-trend/`, body,this.httpOption)
    }
    upcomingLeave(body: any){
      return this.http.post(`${this.API_PATH}request/api/v1/profile-leave-balance-upcoming/`, body,this.httpOption)
    }
    leaveTrendDropdown(id: any){
      return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-employee-dropdown/?id=${id}`,this.httpOption)
    }
    leaveData(body: any){
      return this.http.post(`${this.API_PATH}request/api/v1/profile-leave-balance-data/`,body,this.httpOption)
    }
    leaveDatatable(id: any,search:any,transaction_type:any,year: any){
      return this.http.get(`${this.API_PATH}request/api/v1/profile-leave-transaction/?employee_id=${id}&search=${search}&transaction_type=${transaction_type}&year=${year}`,this.httpOption)
    }
    leavesingleview(id: any){
      return this.http.get(`${this.API_PATH}request/api/v1/my-leave-single-leave-request-data/${id}/`, this.httpOption)
    }
    leaveTypeDropdown(id: any,search: any,year:any){
      return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-employee-dropdown/?search=${search}&id=${id}&year=${year}`,this.httpOption)
    }

  }
