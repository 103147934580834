import { Component, OnInit, Inject } from '@angular/core';
import {Location} from '@angular/common';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,Validators ,FormGroup} from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { CustomDateValidators } from 'src/app/@shared/validators/customDate.validators';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import * as moment from 'moment';

@Component({
  selector: 'app-leave-request-setup',
  templateUrl: './leave-request-setup.component.html',
  styleUrls: ['./leave-request-setup.component.scss']
})
export class LeaveRequestSetupComponent implements OnInit {
  activeTab               = 1 ;
  validationData: any     = [];
  totalLeaveDays: number  = 0;
  error: any              = false;

  validationPortionData:any = [];
  errorPass                 = "";
  invalidPass               = false;
  alertToggle               = false;

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    private _location: Location,private formBuilder:FormBuilder,
    private investmentService:InvestmentDeclerationService,
    public router: Router,
    public route:ActivatedRoute,
    private appservice:AppService,
    private authService:AuthService,
    private previousRouteService: PreviousRouteService

  ) { }

  backClicked() {
   this.investmentService.backClicked('/leave-request-setup')
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.matDateFormat.display.dateInput = this.appservice.getdatepickerformat();
    }, 1000);
  }

  leaverequestForm: FormGroup = this.formBuilder.group({
    leaverequest        : this.formBuilder.group({
      leave_type        : [null,[Validators.required]],
      from_date         : ['',[Validators.required]],
      to_date           : ['',[Validators.required]],
      reason            : ['',[Validators.required,Validators.maxLength(100)]],
      document          : [''],
      // portion           : [''],
      leave_policy      : [''],
      portion           : this.formBuilder.array([]),
      leave_num_days    : [0],
      leave_dates       : [],
      compoff_date      : [],
      compoff_number    : [],
      leave_name        : [],
      compoff_date_num  : [],
      penalty_dates     : [],
      penality_count    : [],
      validateDuration  : []
    },
    { validators: CustomDateValidators.fromToDate('from_date', 'to_date') }
    ),

  });

  get leaveDetialsForm(){
    return this.leaverequestForm.get('leaverequest') as FormGroup;
  }
  submitOne(data: any){
    this.activeTab = 2;
    this.totalLeaveDays = data.total_days;
  }

  submitForm(data:any){

    let employee   = this.authService.isEmployeeId;
    let portion1   = data?.portions;

    // If leave type is compensatory
    let portion:any = [];
    if(this.leaverequestForm.getRawValue().leaverequest['compoff_date']?.length > 0){
      for(let i=0;i<portion1?.length;i++){

        portion.push({
          "date"          : portion1?.[i]?.date,
          "portion"       : portion1?.[i]?.portion,
          "compoff_date"  : this.leaverequestForm.getRawValue().leaverequest['compoff_date']?.[i]})
      } // If leave has penality
    }else if(this.leaverequestForm.getRawValue().leaverequest['penalty_dates']?.length > 0){

      let leavePortion    = [];
      let penalityPortion = [];

      if(portion1?.length > 0){

      for(let i=0;i<portion1?.length;i++){
        leavePortion.push({
            "date"          : portion1?.[i]?.date,
            "portion"       : portion1?.[i]?.portion,
            "leave_penalty" : false})
          }
        }
        if(this.leaverequestForm.getRawValue().leaverequest['penalty_dates']?.length > 0){
          for(let j=0;j<this.leaverequestForm.getRawValue().leaverequest['penalty_dates']?.length;j++){
            const rawDate = this.leaverequestForm.getRawValue().leaverequest['penalty_dates']?.[j].date;
            let portion = this.leaverequestForm.getRawValue().leaverequest['penalty_dates']?.[j].portion;
            if(portion == 'Second Half'){
              portion = 'Second Half Day'
            }else if(portion == 'First Half'){
              portion = 'First Half Day'
            }else{
              portion = 'Full Day'
            }
            const formattedDate = moment(rawDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
            penalityPortion.push({
            "date"          : formattedDate,
            "portion"       : portion,
            "leave_penalty" : true})
        }
      }

      if(leavePortion?.length > 0 && penalityPortion?.length > 0){
        portion = ([...leavePortion, ...penalityPortion]);
      }

    }else{
      portion = portion1;
    }

    this.investmentService.leaveRequestCreate({'leave_type':this.leaverequestForm.getRawValue().leaverequest['leave_type'], 'from_date':this.appservice.dateFormatConvert(this.leaverequestForm.getRawValue().leaverequest['from_date']), 'to_date': this.appservice.dateFormatConvert(this.leaverequestForm.getRawValue().leaverequest['to_date']), 'employee': employee,'reason': this.leaverequestForm.getRawValue().leaverequest['reason'],'document': this.leaverequestForm.getRawValue().leaverequest['document'], 'portion':portion, 'leave_policy': this.leaverequestForm.getRawValue().leaverequest['leave_policy'], 'leave_num_days': this.leaverequestForm.getRawValue().leaverequest['leave_num_days']}).subscribe((res:any)=>{
      this.router.navigate(['request']);
    });

  }

  validationResult(data:any){
    this.validationData = data.ValidationResult;
    this.totalLeaveDays = data.total_days;
  }

  validationError(data:any){
    this.error      = data?.error;
    this.errorPass  = data?.errorMsg;
  }

  overtimeCompOffError = false;
  overtimeCompoffError(data:any){
    this.overtimeCompOffError = data?.error;
  }

  onNavChange(changeEvent: NgbNavChangeEvent){
    if (changeEvent.nextId === 2) {
     if (this.leaverequestForm['controls'].leaverequest.invalid || this.overtimeCompOffError) {
        changeEvent.preventDefault();
     }
     else if(this.error){
        if(this.errorPass != ''){
          this.invalidPass = true;
          this.alertToggle = true;
        }else{
          this.invalidPass = false;
          this.alertToggle = false;
        }
        changeEvent.preventDefault();
     }
   }
 }

validationPortionResult(data:any){
  if(data?.error){
    this.validationPortionData = [];
    this.errorPass             = data?.validationPortionResult;
  }else{
    this.validationPortionData = data?.validationPortionResult;
    this.errorPass             = '';
  }
}

}
