<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row row-16">
    <div class="col-12 d-flex align-items-start">
      <div class="">
        <h3>Reimbursement</h3>
        <p class="text-helper mb-md-0">Details of all the reimbursement components are listed down below.</p>
      </div>
      <div class=" d-inline-flex ms-auto">
        <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
      </div>
    </div>
    <app-table-loader *ngIf="loader"></app-table-loader>
    <ng-container *ngIf="!loader">
      <div class="col-12">
        <div class="card card-c2  pt-24 pb-8 px-24">
          <div class="row row-12">
            <div class="col-12 d-flex ">
              <div class="">
                <p class="mb-0 fw-500">Reimbursement categories</p>
              </div>
              <div class="w-100  ms-auto" style="max-width:316px">
                <ng-select class="form-ngselect fs-14 h-36s" placeholder="All categories" [(ngModel)]="selectedCategory"
                  (change)="categoryChange(selectedCategory)" name="selectedCategory">
                  <ng-option *ngFor="let category of dropDownData" [value]="category.pay_component_id">
                    {{category.name}} </ng-option>
                </ng-select>
              </div>
            </div>

            <!-- No data start -->
            <div class="col-12 " *ngIf="requestedCategoryData?.length == 0; else requestedCategoryDatas"
              style="height : 140px">
              <div class="flex-center d-flex flex-column gap-20">
                <img  src="assets\images\no-data\bg20.png" style="width: 5rem" class="img-fluid">
                <p class="fs-14 fw-500">No data found</p>
              </div>
            </div>
            <!-- No data end -->
            <ng-template #requestedCategoryDatas>
              <ngu-carousel class="custom-carousel mt-0" #myCarousel [inputs]="reimbursementTiles"
                [dataSource]="requestedCategoryData">
                <ngu-tile *nguCarouselDef="let item ; let i=index">
                  <div class="card card-c2 indicater1 card-hover" (click)="vieCategoryDetails(item);detailsView = true">
                    <div class="py-12 px-20 ">
                      <div class="fw-500 d-flex align-items-center"><span *ngIf="item.fbp"
                          class="fs-10 badge4 badge-primary radius-4 py-1 me-8">FBP</span> {{item.name}}</div>
                      <div class="fs-10 text-light-400 pt-8 text-uppecase">AS OF {{currentMonth}} {{reimburseYear}}
                      </div>
                      <div class="custom-progress mt-16 mb-1 radius-4">
                        <div class="progress-bar bg-accent1" style="--percentage:{{item.percentage}}%;"></div>
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="fs-12 text-light-400 status1 status-primary">Claimed : </span>
                        <span class="fs-14 fw-500 me-auto ms-1">{{(item.claimed)|currency:currency}} </span>
                        <span class="fs-12 text-light-400 status1 status-secondary">Unclaimed : </span>
                        <span class="fs-14 fw-500 ms-1">{{(item.unclaimed)|currency:currency}} </span>
                      </div>
                    </div>
                  </div>
                </ngu-tile>
                <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel?.isFirst ? 0:1">
                  <i class="icon-chevron-left-lg"></i>
                </button>
                <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel?.isLast ? 0:1">
                  <i class="icon-chevron-right-lg"></i>
                </button>
              </ngu-carousel>
              <!-- <div class="col-12" *ngFor="let item of requestedCategoryData; let i = index">
                <div class="card card-c2 indicater1">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="py-12 px-20 ">
                        <div class="fw-500 d-flex align-items-center"><span *ngIf="item.fbp"
                            class="fs-10 badge4 badge-primary radius-4 py-1 me-8">FBP</span> {{item.name}}</div>
                        <div class="fs-10 text-light-400 pt-8 text-uppecase">AS OF {{currentMonth}} {{reimburseYear}}
                        </div>
                        <div class="custom-progress mt-16 mb-1 radius-4">
                          <div class="progress-bar bg-accent1" style="--percentage:{{item.percentage}}%;"></div>
                        </div>
                        <div class="d-flex align-items-center">
                          <span class="fs-12 text-light-400 status1 status-primary">Claimed : </span>
                          <span class="fs-14 fw-500 me-auto ms-1">{{(item.claimed)|currency:currency}} </span>
                          <span class="fs-12 text-light-400 status1 status-secondary">Unclaimed : </span>
                          <span class="fs-14 fw-500 ms-1">{{(item.unclaimed)|currency:currency}} </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="py-12 h-100 px-36 bg-dark-400">
                        <div class="row  row-8">
                          <div class="col">
                            <div class="fs-12 text-light-400">Eligible</div>
                            <div class="fs-14">{{(item.eligible)|currency:currency}} </div>
                          </div>
                          <div class="col">
                            <div class="fs-12 text-light-400">Bills submitted</div>
                            <div class="fs-14">{{(item.bill_submit)|currency:currency}} </div>
                          </div>
                          <div class="col">
                            <div class="fs-12 text-light-400">Balance</div>
                            <div class="fs-14">{{(item.unclaimed)| currency: currency}} </div>
                          </div>
                          <div class="col-12">
                            <hr class="hr-1">
                          </div>
                          <div class="col">
                            <div class="fs-12 text-light-400">Pending</div>
                            <div class="fs-14 status1 status-warning">{{(item.pending)|currency:currency}} </div>
                          </div>
                          <div class="col">
                            <div class="fs-12 text-light-400">Approved</div>
                            <div class="fs-14 status1 status-success">{{(item.approved)|currency:currency}} </div>
                          </div>
                          <div class="col">
                            <div class="fs-12 text-light-400">Rejected</div>
                            <div class="fs-14 status1 status-danger">{{(item.rejected)|currency:currency}} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-c2  py-16 px-24 h-100
              ">
          <div class="fw-500">Total reimbursement</div>
          <div class="fs-14 fw-500 mt-8 text-light-400 text-uppercase">
            TILL DATE
          </div>
          <div class=" vstack justify-content-center  h-100">
            <!-- No data start -->
            <div class="col-12 " *ngIf="chartData?.length == 0; else chartDatas">
              <div class="flex-center d-flex flex-column gap-20">
                <img  src="assets\images\no-data\bg20.png" style="width: 5rem" class="img-fluid">
                <p class="fs-14 fw-500">No data found</p>
              </div>
            </div>
            <!-- No data end -->
            <!-- <div style="min-height:5rem;"> -->
            <ng-template #chartDatas>
              <!-- <ngx-charts-advanced-pie-chart class="h-100 d-block" [scheme]="colorScheme" [results]="chartData" [gradient]="gradient"
                          (select)="onSelect($event)" [valueFormatting]="format"  [animations]="false" id="pieChart">
                        </ngx-charts-advanced-pie-chart> -->
              <app-doughnut-chart [totalCenter]="false" [labelFontSize]="'1rem'" [height]="'14rem'" [radius]="80"
                [color]="colorScheme" [data]="chartData" [labels]="taxLabels" [id]="0"
                [showRupee]="true" [currency]="currency">
              </app-doughnut-chart>
            </ng-template>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="card card-c2  py-16 px-24  h-100">
          <div class="fs-24">Reimbursements claimed </div>
          <div class="fs-14 fw-500 mt-8 text-uppercase">
            TILL DATE
          </div>
          <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels" [yText]="barChartYAxisLabel"></app-bar-chart>
        </div>
      </div> -->
      <!-- Bar Chart -->
      <div class="col-md-6">
        <div class="card card-c2  py-16 px-24  h-100">
          <div class="fw-500">Reimbursements claimed </div>
          <div class="fs-14 fw-500 mt-8 text-light-400 text-uppercase">
            TILL DATE
            <!-- FISCAL YEAR {{this.appService.getFinancialYearRange(selectedYear)}} -->
          </div>
          <!-- <div class="col-12 mt-16" style="max-height:12.6875rem ;margin-bottom: 2.5rem;"> -->
          <!-- No data start -->
          <div class="col-12 " *ngIf="barChartData?.length == 0; else barChartDatas">
            <div class="flex-center d-flex flex-column gap-20" style="height: 14rem;">
              <img  src="assets\images\no-data\bg20.png" style="width: 5rem" class="img-fluid">
              <p class="fs-14 fw-500">No data found</p>
            </div>
          </div>
          <!-- No data end -->
          <ng-template #barChartDatas>
            <app-bar-chart [data]="barchart" [labels]="barChartXAxisLabels"
              [yText]="barChartYAxisLabel"></app-bar-chart>
          </ng-template>
        </div>
      </div>
      <!-- </div> -->
      <!-- Table -->
      <div class="col-12">
        <div class="card card-c2 px-24 py-16">
          <div class="row mb-16">
            <div class="col-12 d-flex">
              <div class="fw-500">My claims</div>
              <div class="ms-auto" *ngIf="tableData?.length > 0">
                <div class="form-icon2 w-100" style="max-width: 363px">
                  <i class="icon-search icon-left fs-14"></i>
                  <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12"
                    (click)="searchString=''"></div>
                  <input class="form-control  fs-14" placeholder="{{this.messageService.searchdisplay('claims')}} "
                    [(ngModel)]="searchString" />
                </div>
              </div>
            </div>
          </div>
          <div class="row row-16 ">
            <div class="col-12">
              <div class="card card-c2 p-0">
                <div class="table-responsive   radius-4" style="max-height: calc(100vh - 7rem);">
                  <table class="table sticky-header table-striped table-sm  table-minheight">
                    <thead>
                      <tr>
                        <th class="fw-600 text-uppercase" scope="col">CATEGORY</th>
                        <th class="fw-600 text-uppercase" scope="col">CLAIM DATE</th>
                        <th class="fw-600 text-uppercase" scope="col">BILL COUNT</th>
                        <th class="fw-600 text-uppercase" scope="col">CLAIM AMOUNT</th>
                        <th class="fw-600 text-uppercase" scope="col">APPROVED AMOUNT</th>
                        <th class="fw-600 text-uppercase" scope="col">DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="tableData?.length==0">
                        <tr>
                          <td colspan="7">
                            <div class="card card-c2 flex-center p-16 bg-dark-500">
                              <span class="fw-500 fs-14">No data found</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                      <tr
                        *ngFor='let item of $any(tableData|filter:searchString| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize)'>
                        <td class="">{{item.category}}</td>
                        <td>{{item.claim_date | date : "d MMM y"}}</td>
                        <td>{{item.bill_count}}</td>
                        <td>{{(item.claim_amount)| currency: currency}}</td>
                        <td><span class="text-success">{{(item.approved_amount)| currency:currency}}</span></td>
                        <td>
                          <span (click)="viewrReimbursement(item.request_id)" class="link-primary"><i
                              class="icon-eye me-1"></i> View</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="this.appService.filteredcount(tableData,searchString)>pageSize">
              <ngb-pagination class="d-flex justify-content-end"
                [collectionSize]="this.appService.filteredcount(tableData,searchString)" [(page)]="page" [maxSize]="3"
                [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- </ng-template> -->
  </div>
</div>
<div class="side-panel" *ngIf="permissions" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='empReimburseView === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>View</h5>
      <a class="toggle-panel" (click)="empReimburseView = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <app-view-reimbursement [reimbursementData]="reimbursementData" [loader1]="loader1" [requestId]="reimbursementID"
      [from]="'my-reimbursement'">
    </app-view-reimbursement>
  </div>
</div>

<div class="side-panel " style="--sidepanel-width:45rem;" [class.side-pane-active]='detailsView === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>CLAIM INFORMATION</h5>
      <a class="toggle-panel" (click)="detailsView = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-16 ">
          <div class="col-12">
            <p class="text-uppercase fw-500 mb-0">{{categoryData?.name}}</p>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-16 ">
                <div class="col-12 "><span class="title">REIMBURSEMENT DETAILS</span></div>
                <div class="col-sm-6 col-md-4 col-12">
                  <div class="detail-title">Category </div>
                  <div class="detail-desc">{{categoryData?.name}} </div>
                </div>
                <div class="col-sm-6 col-md-4 col-12">
                  <div class="detail-title">Date</div>
                  <div class="detail-desc">{{currentMonth}} {{reimburseYear}}</div>
                </div>
                <div class="col-sm-6 col-md-4 col-12">
                  <div class="detail-title">Claimed</div>
                  <div class="detail-desc">{{(categoryData?.claimed)|currency:currency}}</div>
                </div>
                <div class="col-sm-6 col-md-4 col-12">
                  <div class="detail-title">Unclaimed</div>
                  <div class="detail-desc">{{(categoryData?.unclaimed)|currency:currency}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-16 ">
                <div class="col-12 "><span class="title">STATUS DETAILS </span></div>
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Eligible</div>
                  <div class="detail-desc">{{(categoryData?.eligible)|currency:currency}}</div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Bills submitted</div>
                  <div class="detail-desc">{{(categoryData?.bill_submit)|currency:currency}}</div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Balance</div>
                  <div class="detail-desc">{{(categoryData?.unclaimed)| currency: currency}}</div>
                </div>
                <div class="col-12">
                  <hr class="hr-1">
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Pending </div>
                  <div class="detail-desc"> <span
                      class="fs-12 text-light-400 status1 status-warning"></span>{{(categoryData?.pending)|currency:currency}}
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Approved </div>
                  <div class="detail-desc"><span
                      class="fs-12 text-light-400 status1 status-success"></span>{{(categoryData?.approved)|currency:currency}}
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Rejected </div>
                  <div class="detail-desc"><span
                      class="fs-12 text-light-400 status1 status-danger"></span>{{(categoryData?.rejected)|currency:currency}}
                  </div>
                </div>

                <div class="col-md-4 col-sm-6 col-12">
                  <div class="detail-title">Cancelled </div>
                  <div class="detail-desc"><span
                      class="fs-12 text-light-400 status1 status-cancel"></span>{{(categoryData?.cancelled)|currency:currency}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
